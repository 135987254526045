import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [status, setStatus] = useState('verifying'); // verifying, success, error

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${config.API_URL}/api/auth/verify-email/${token}`);
        setStatus('success');
        // Redirect to login after 3 seconds
        setTimeout(() => {
          navigate('/login', { 
            state: { message: response.data.message }
          });
        }, 3000);
      } catch (error) {
        console.error('Email verification error:', error);
        setStatus('error');
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-8 m-4">
        <h1 className="text-2xl font-bold text-center mb-4">
          {status === 'verifying' && t('Verifying your email...')}
          {status === 'success' && t('Email Verified!')}
          {status === 'error' && t('Verification Failed')}
        </h1>
        <p className="text-center text-gray-600 mb-4">
          {status === 'verifying' && t('Please wait while we verify your email address.')}
          {status === 'success' && t('You will be redirected to login shortly.')}
          {status === 'error' && t('The verification link is invalid or has expired.')}
        </p>
        {status === 'error' && (
          <div className="text-center">
            <button
              onClick={() => navigate('/login')}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              {t('Go to Login')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
