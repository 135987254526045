import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const PlanCard = ({ plan, price, period, savings, isPopular, onSelect, selected, paypalButtonRef }) => {
  const { t } = useTranslation();
  const isSelected = selected === plan;
  const cardRef = useRef(null);
  
  useEffect(() => {
    if (isSelected && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isSelected]);
  
  return (
    <div 
      ref={cardRef}
      className={`relative rounded-lg p-8 transform transition-all duration-300 hover:scale-105 hover:-translate-y-2 
      ${isPopular ? 'bg-blue-600 shadow-2xl shadow-blue-500/50' : 'bg-gray-800 shadow-xl shadow-gray-900/50'} 
      ${isSelected ? 'ring-4 ring-blue-400 shadow-2xl shadow-blue-400/50' : ''}`}
    >
      {isPopular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
          <span className="bg-yellow-400 text-black px-4 py-1 rounded-full text-sm font-bold shadow-lg">
            {t('upgrade.bestValue')}
          </span>
        </div>
      )}
      <div className="text-center relative">
        <div className="absolute inset-0 bg-gradient-to-b from-white/5 to-transparent rounded-lg -m-8 pointer-events-none"></div>
        <h3 className="text-2xl font-bold text-white mb-4">{t(`premium.plans.${plan}.title`)}</h3>
        <div className="text-4xl font-bold text-white mb-2">
          {price}
          <span className="text-lg text-gray-300">/{t('upgrade.perMonth')}</span>
        </div>
        {savings && (
          <div className="text-green-400 text-sm mb-4">{t(savings)}</div>
        )}
        <div className="text-gray-300 mb-6">{period}</div>
        {!isSelected ? (
          <button
            onClick={() => onSelect(plan)}
            className="w-full py-3 px-6 rounded-lg font-semibold transition-all duration-300 transform hover:scale-105 
            bg-blue-500 hover:bg-blue-400 text-white shadow-lg hover:shadow-xl shadow-blue-500/50"
          >
            Select Plan
          </button>
        ) : (
          <div className="space-y-4">
            <div className="py-2 px-4 rounded-lg bg-blue-400 text-white shadow-lg shadow-blue-400/50">
              ✓ Selected
            </div>
            <div ref={paypalButtonRef} className="w-full mt-4"></div>
          </div>
        )}
      </div>
    </div>
  );
};

const FeatureList = () => {
  const { t } = useTranslation();
  const features = [
    {
      key: 'messaging',
      title: t('premium.features.messaging.title'),
      description: t('premium.features.messaging.description')
    },
    {
      key: 'history',
      title: t('premium.features.history.title'),
      description: t('premium.features.history.description')
    },
    {
      key: 'clear',
      title: t('premium.features.clear.title'),
      description: t('premium.features.clear.description')
    },
    {
      key: 'commitment',
      title: t('premium.features.commitment.title'),
      description: t('premium.features.commitment.description')
    }
  ];

  return (
    <div className="mt-8 border-t border-gray-700 pt-8">
      <h3 className="text-xl font-bold text-white mb-6 text-center">
        {t('premium.title')}
      </h3>
      <ul className="space-y-4 max-w-md mx-auto">
        {features.map((feature) => (
          <li key={feature.key} className="flex items-center text-gray-300">
            <svg
              className="w-5 h-5 text-green-400 mr-3 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <div>
              <div className="font-semibold">{feature.title}</div>
              <div className="text-sm text-gray-400">{feature.description}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function Upgrade() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null); // Start with no plan selected
  const [loading, setLoading] = useState(false);
  const [adBlockerDetected, setAdBlockerDetected] = useState(false);
  const paypalButtonRef = useRef(null);
  const plansRef = useRef(null);

  // Define plans with clear comments
  const plans = [
    {
      plan: 'monthly',
      price: '$20',
      period: t('premium.plans.monthly.title'),
      savings: t('premium.plans.monthly.savings'),
      planId: 'P-2P474484X0011900XM6OONQQ'  // Production Monthly plan
    },
    {
      plan: '6months',
      price: '$90',
      period: t('premium.plans.6months.title'),
      savings: t('premium.plans.6months.savings'),
      planId: 'P-18446884561656018M6OOOZY'  // Production 6-month plan
    },
    {
      plan: 'yearly',
      price: '$144',
      period: t('premium.plans.yearly.title'),
      savings: t('premium.plans.yearly.savings'),
      isPopular: true,
      planId: 'P-87J49653GL5208216M6OOMZA'  // Production Annual plan
    }
  ];

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    // Re-initialize PayPal buttons when plan changes
    if (window.paypal && paypalButtonRef.current) {
      // Clear any existing errors
      setLoading(false);
      initializePayPalButtons();
    }
  };

  const selectedPlanData = plans.find(p => p.plan === selectedPlan);

  const initializePayPalButtons = () => {
    if (!window.paypal || !paypalButtonRef.current || !selectedPlanData) {
      console.error('PayPal not initialized or missing plan data');
      return;
    }

    // Clear existing buttons
    paypalButtonRef.current.innerHTML = '';

    try {
      window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: async (data, actions) => {
          try {
            setLoading(true);
            // Create subscription through our server first
            const response = await axios.post('/api/payments/create-subscription', {
              planId: selectedPlanData.planId
            });

            if (!response.data?.success) {
              console.error('Server error:', response.data);
              throw new Error('Failed to create subscription');
            }

            // Return the subscription ID to PayPal
            return response.data.subscriptionId;
          } catch (error) {
            console.error('Error creating subscription:', error.response?.data || error.message);
            toast.error(t('upgrade.errors.orderCreation'));
            throw error;
          } finally {
            setLoading(false);
          }
        },
        onApprove: async (data, actions) => {
          console.log('Subscription approved:', data);
          try {
            if (!data.subscriptionID) {
              console.error('Missing subscription ID:', data);
              toast.error(t('upgrade.errors.verificationFailed'));
              return;
            }

            // Navigate to success page with parameters
            navigate(`/upgrade/success?subscription_id=${data.subscriptionID}&token=${data.orderID}`);
          } catch (error) {
            console.error('Error handling subscription approval:', error);
            toast.error(t('upgrade.errors.verificationFailed'));
          }
        },
        onError: (err) => {
          console.error('PayPal error:', err);
          toast.error(t('upgrade.errors.paypalError'));
        }
      }).render(paypalButtonRef.current);
    } catch (error) {
      console.error('Error initializing PayPal buttons:', error);
      toast.error(t('upgrade.errors.paypalError'));
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      loadPayPalScript();
    }
  }, [selectedPlan]);

  const loadPayPalScript = async () => {
    try {
      // Remove any existing PayPal script to avoid duplicates
      const existingScript = document.querySelector('script[src*="www.paypal.com/sdk/js"]');
      if (existingScript) {
        existingScript.remove();
      }

      const { data } = await axios.get('/api/payments/config');
      
      if (!data?.clientId) {
        console.error('PayPal Client ID not received from server');
        toast.error(t('upgrade.errors.paypalError'));
        return;
      }

      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${encodeURIComponent(data.clientId)}&vault=true&intent=subscription&currency=USD`;
        script.async = true;
        
        script.onload = () => {
          setAdBlockerDetected(false);
          if (selectedPlan) {
            initializePayPalButtons();
          }
          resolve();
        };

        script.onerror = (err) => {
          console.error('PayPal script loading error:', err);
          setAdBlockerDetected(true);
          reject(err);
        };

        document.body.appendChild(script);
      });
    } catch (error) {
      console.error('Error loading PayPal script:', error);
      toast.error(t('upgrade.errors.paypalError'));
    }
  };

  if (adBlockerDetected) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Please Disable Your Ad Blocker
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              We've detected that your ad blocker is preventing PayPal from loading. To complete your subscription, please:
            </p>
            <ul className="mt-4 list-disc pl-5 text-sm text-gray-600">
              <li>Disable your ad blocker for this site</li>
              <li>Refresh the page</li>
              <li>Try subscribing again</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-blue-900 to-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <ToastContainer />
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <div className="flex items-center justify-center space-x-4 mb-4">
            <svg
              className="w-8 h-8 text-green-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
              />
            </svg>
            <h2 className="text-4xl font-bold text-white">
              {t('upgrade.title')}
            </h2>
            <svg
              className="w-8 h-8 text-pink-500"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              />
            </svg>
          </div>
          <p className="text-xl text-gray-300">
            {t('upgrade.subtitle')}
          </p>
        </div>

        {/* Premium Features */}
        <FeatureList />

        {/* Plan Selection */}
        <div className="mt-16" ref={plansRef}>
          <h2 className="text-3xl font-bold text-center mb-8">{t('upgrade.choosePlan')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto px-4">
            {plans.map((plan) => (
              <PlanCard
                key={plan.plan}
                {...plan}
                selected={selectedPlan}
                onSelect={() => handleSelectPlan(plan.plan)}
                paypalButtonRef={selectedPlan === plan.plan ? paypalButtonRef : null}
              />
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}