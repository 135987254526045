import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axios';

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus({ type: '', message: '' });

    try {
      const response = await axios.post('/api/auth/forgot-password', { email });
      setStatus({
        type: 'success',
        message: 'If an account exists with this email, you will receive password reset instructions.'
      });
      setEmail('');
    } catch (error) {
      setStatus({
        type: 'error',
        message: error.response?.data?.message || 'An error occurred. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 py-4 px-4">
      <div className="max-w-md mx-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-6">
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-2 text-white">Reset Password / รีเซ็ตรหัสผ่าน 🔑</h2>
            <p className="text-white/80 mb-1">Enter your email to receive reset instructions</p>
            <p className="text-white/80 mb-6">กรอกอีเมลเพื่อรับคำแนะนำในการรีเซ็ตรหัสผ่าน</p>
          </div>

          {status.message && (
            <div className={`mb-4 p-3 rounded ${
              status.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
            }`}>
              {status.message}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-white mb-2">Email / อีเมล</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded bg-white/20 border-white/30 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/50"
                required
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-white/20 hover:bg-white/30 text-white p-2 rounded transition duration-200 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 disabled:opacity-50"
            >
              {loading ? 'Sending... / กำลังส่ง...' : 'Send Reset Link / ส่งลิงก์รีเซ็ต'}
            </button>
          </form>

          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={() => navigate('/login')}
              className="text-white/80 hover:text-white transition-colors"
            >
              ← Back to Login / กลับไปหน้าเข้าสู่ระบบ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

