import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axios';

function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus({ type: '', message: '' });

    if (formData.newPassword !== formData.confirmPassword) {
      setStatus({
        type: 'error',
        message: 'Passwords do not match / รหัสผ่านไม่ตรงกัน'
      });
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`/api/auth/reset-password/${token}`, {
        newPassword: formData.newPassword
      });

      setStatus({
        type: 'success',
        message: 'Password reset successful! Redirecting to login...'
      });

      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setStatus({
        type: 'error',
        message: error.response?.data?.message || 'An error occurred. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 py-4 px-4">
      <div className="max-w-md mx-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-6">
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-2 text-white">New Password / รหัสผ่านใหม่ 🔒</h2>
            <p className="text-white/80 mb-1">Enter your new password</p>
            <p className="text-white/80 mb-6">กรอกรหัสผ่านใหม่ของคุณ</p>
          </div>

          {status.message && (
            <div className={`mb-4 p-3 rounded ${
              status.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
            }`}>
              {status.message}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-white mb-2">New Password / รหัสผ่านใหม่</label>
              <input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-white/20 border-white/30 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/50"
                required
                minLength="6"
              />
            </div>

            <div>
              <label className="block text-white mb-2">Confirm Password / ยืนยันรหัสผ่าน</label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-white/20 border-white/30 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/50"
                required
                minLength="6"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-white/20 hover:bg-white/30 text-white p-2 rounded transition duration-200 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 disabled:opacity-50"
            >
              {loading ? 'Resetting... / กำลังรีเซ็ต...' : 'Reset Password / รีเซ็ตรหัสผ่าน'}
            </button>
          </form>

          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={() => navigate('/login')}
              className="text-white/80 hover:text-white transition-colors"
            >
              ← Back to Login / กลับไปหน้าเข้าสู่ระบบ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
