import React, { useState, useEffect, useRef, useCallback, lazy, Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-easy-crop';
import {
  UserIcon,
  ArrowUpTrayIcon,
  CameraIcon,
  XMarkIcon,
  PhotoIcon,
  FaceSmileIcon
} from '@heroicons/react/24/outline';

const EmojiPicker = lazy(() => import('emoji-picker-react'));

function EditProfile() {
  const { currentUser, updateUser, logout } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: currentUser?.profile?.name || '',
    age: currentUser?.profile?.age || '',
    location: currentUser?.profile?.location || '',
    bio: currentUser?.profile?.bio || '',
    gender: currentUser?.profile?.gender || '',
    lookingFor: currentUser?.profile?.lookingFor || ''
  });
  
  const [profilePicture, setProfilePicture] = useState(null);
  const [additionalPictures, setAdditionalPictures] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const MAX_IMAGES = 12;
  const [profileImageSrc, setProfileImageSrc] = useState(
    currentUser?.profile?.profilePicture ? 
    currentUser.profile.profilePicture : 
    '/images/placeholder.jpg'
  );
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [uploadingProfile, setUploadingProfile] = useState(false);
  const [uploadingAdditional, setUploadingAdditional] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('none');
  const [showAdditionalCamera, setShowAdditionalCamera] = useState(false);
  const shutterSound = useMemo(() => new Audio('/sounds/shutter.mp3'), []);
  const filters = {
    none: 'none',
    grayscale: 'grayscale(1)',
    sepia: 'sepia(1)',
    warm: 'sepia(0.5) saturate(1.5)',
    cool: 'hue-rotate(180deg)',
    bright: 'brightness(1.3) contrast(1.1)',
    vintage: 'sepia(0.3) contrast(1.1) brightness(1.1)',
    fade: 'opacity(0.8) brightness(1.2)',
    dramatic: 'contrast(1.3) brightness(0.8)',
    vivid: 'saturate(1.5) contrast(1.2)'
  };
  const videoRef = useRef(null);
  const additionalVideoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [additionalStream, setAdditionalStream] = useState(null);
  const imageRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const imageInputRef = useRef(null);
  const additionalPicturesRef = useRef(null);
  const { t } = useTranslation();

  const [stickers, setStickers] = useState([]);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [activeStickers, setActiveStickers] = useState([]);
  const canvasRef = useRef(null);
  const [showStickers, setShowStickers] = useState(false);

  // Load stickers
  useEffect(() => {
    fetch('/stickers/stickers.json')
      .then(res => res.json())
      .then(data => setStickers(data.stickers))
      .catch(err => console.error('Error loading stickers:', err));
  }, []);

  const addSticker = (stickerPath) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const baseSize = 96; 
      const width = baseSize;
      const height = baseSize / aspectRatio;
      
      setActiveStickers(prev => [...prev, {
        src: stickerPath,
        x: 50,
        y: 50,
        width,
        height,
        rotation: 0,
        scale: 1
      }]);
    };
    img.src = stickerPath;
  };

  const handleStickerDrag = (index, newX, newY) => {
    setActiveStickers(prev => {
      const newStickers = [...prev];
      const videoContainer = additionalVideoRef.current?.parentElement;
      if (!videoContainer) return prev;

      const rect = videoContainer.getBoundingClientRect();
      const sticker = newStickers[index];
      
      // Keep sticker within bounds
      const maxX = rect.width - sticker.width;
      const maxY = rect.height - sticker.height;
      
      newStickers[index] = {
        ...sticker,
        x: Math.max(0, Math.min(newX, maxX)),
        y: Math.max(0, Math.min(newY, maxY))
      };
      
      return newStickers;
    });
  };

  const updateStickerPosition = (index, newX, newY) => {
    setActiveStickers(prev => prev.map((s, i) => 
      i === index ? { ...s, x: newX, y: newY } : s
    ));
  };

  const handleStickerDragStart = (index) => {
    setActiveStickers(prev => prev.map((s, i) => 
      i === index ? { ...s, isDragging: true } : s
    ));
  };

  const handleStickerDragEnd = (index) => {
    setActiveStickers(prev => prev.map((s, i) => 
      i === index ? { ...s, isDragging: false } : s
    ));
  };

  const handleStickerMouseMove = (e, index) => {
    if (!activeStickers[index]?.isDragging) return;
    
    const videoContainer = additionalVideoRef.current?.parentElement;
    if (!videoContainer) return;

    e.preventDefault();
    handleStickerDrag(index, e.clientX, e.clientY);
  };

  const handleStickerResize = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const target = e.currentTarget;
    const stickerElement = target.parentElement;
    const rect = stickerElement.getBoundingClientRect();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = rect.width;
    const startHeight = rect.height;
    const sticker = activeStickers[index];
    
    const handlePointerMove = (moveEvent) => {
      moveEvent.preventDefault();
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;
      
      // Use diagonal distance for smooth resizing
      const delta = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const sign = deltaX + deltaY > 0 ? 1 : -1;
      
      // Calculate new size maintaining aspect ratio
      const scale = 1 + (sign * delta / rect.width);
      const newWidth = Math.max(24, startWidth * scale);
      const newHeight = Math.max(24, startHeight * scale);
      
      setActiveStickers(prev => {
        const newStickers = [...prev];
        newStickers[index] = {
          ...sticker,
          width: newWidth,
          height: newHeight,
          scale: newWidth / 96
        };
        return newStickers;
      });
    };
    
    const handlePointerUp = () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
      setActiveStickers(prev => prev.map((s, i) => 
        i === index ? { ...s, isResizing: false } : s
      ));
    };
    
    setActiveStickers(prev => prev.map((s, i) => 
      i === index ? { ...s, isResizing: true } : s
    ));
    
    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handlePointerUp);
  };

  const handleStickerRotate = (index, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const target = e.currentTarget;
    const stickerElement = target.parentElement;
    const rect = stickerElement.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const startAngle = Math.atan2(
      e.clientY - centerY,
      e.clientX - centerX
    ) * 180 / Math.PI;
    const startRotation = activeStickers[index].rotation || 0;
    
    const handlePointerMove = (moveEvent) => {
      moveEvent.preventDefault();
      const currentAngle = Math.atan2(
        moveEvent.clientY - centerY,
        moveEvent.clientX - centerX
      ) * 180 / Math.PI;
      
      let deltaAngle = currentAngle - startAngle;
      
      // Snap to 45-degree increments when holding Shift
      if (moveEvent.shiftKey) {
        deltaAngle = Math.round(deltaAngle / 45) * 45;
      }
      
      setActiveStickers(prev => {
        const newStickers = [...prev];
        newStickers[index] = {
          ...newStickers[index],
          rotation: (startRotation + deltaAngle) % 360
        };
        return newStickers;
      });
    };
    
    const handlePointerUp = () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
    };
    
    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handlePointerUp);
  };

  const removeSticker = (index) => {
    setActiveStickers(prev => prev.filter((_, i) => i !== index));
  };

  // Load initial additional pictures
  useEffect(() => {
    if (currentUser?.profile?.additionalPictures) {
      setPreviewUrls(currentUser.profile.additionalPictures);
    }
  }, [currentUser]);

  useEffect(() => {
    // Initialize form data with user data
    if (currentUser?.profile) {
      setFormData({
        name: currentUser.profile.name || '',
        age: currentUser.profile.age || '',
        gender: currentUser.profile.gender || '',
        location: currentUser.profile.location || '',
        bio: currentUser.profile.bio || '',
        lookingFor: currentUser.profile.lookingFor || ''
      });
      
      // Clear any existing preview URLs
      setPreviewUrls([]);
    }
  }, [currentUser]);

  const handleAdditionalPictureSelect = async (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    
    if (!files.length) return;
    
    if (previewUrls.length + files.length > MAX_IMAGES) {
      setError(`Maximum ${MAX_IMAGES} photos allowed`);
      return;
    }

    try {
      setUploadingAdditional(true);
      setError('');
      
      // Process files one by one to avoid memory issues
      for (const file of files) {
        try {
          console.log('Processing file:', file.name, 'size:', file.size, 'type:', file.type);
          
          // Basic validation before compression
          if (!file.type.startsWith('image/')) {
            console.error('Invalid file type:', file.type);
            continue; // Skip non-image files
          }
          
          if (file.size > 10 * 1024 * 1024) { // 10MB limit
            console.error('File too large:', file.size);
            continue; // Skip files that are too large
          }
          
          // Compress image
          const compressedFile = await compressImage(file);
          
          // Create and add preview URL
          const url = URL.createObjectURL(compressedFile);
          setPreviewUrls(prev => [...prev, url]);
          
          console.log('Successfully processed image:', file.name);
        } catch (fileError) {
          console.error('Error processing individual file:', fileError);
          // Continue with other files
        }
      }
      
      setUploadingAdditional(false);
    } catch (error) {
      console.error('Error processing images:', error);
      setError('Error processing images. Please try again.');
      setUploadingAdditional(false);
    }
  };

  const removeAdditionalPicture = (index) => {
    setPreviewUrls(prev => {
      const newUrls = [...prev];
      URL.revokeObjectURL(newUrls[index]); // Clean up the URL
      newUrls.splice(index, 1);
      return newUrls;
    });
  };

  const captureAdditionalPhoto = async () => {
    if (!additionalVideoRef.current) return;

    try {
      // Play shutter sound
      shutterSound.play().catch(err => console.log('Could not play shutter sound:', err));
      
      const video = additionalVideoRef.current;
      const canvas = document.createElement('canvas');
      
      // Force portrait mode with 3:4 aspect ratio
      const targetWidth = Math.min(video.videoWidth, video.videoHeight * (3/4));
      const targetHeight = targetWidth * (4/3);
      
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');
      
      // Center crop the video
      const sourceX = (video.videoWidth - targetWidth) / 2;
      const sourceY = (video.videoHeight - targetHeight) / 2;
      
      // Draw video frame
      ctx.drawImage(
        video,
        sourceX, sourceY, targetWidth, targetHeight,
        0, 0, targetWidth, targetHeight
      );

      // Draw stickers
      const videoContainer = video.parentElement;
      if (videoContainer && activeStickers.length > 0) {
        const containerRect = videoContainer.getBoundingClientRect();
        const videoRect = video.getBoundingClientRect();
        
        // Calculate scale based on the actual video display size vs canvas size
        const scaleX = targetWidth / videoRect.width;
        const scaleY = targetHeight / videoRect.height;
        
        // Calculate video offset within container
        const videoOffsetX = (containerRect.width - videoRect.width) / 2;
        const videoOffsetY = (containerRect.height - videoRect.height) / 2;
        
        // Load all sticker images first
        const stickerImages = await Promise.all(
          activeStickers.map(sticker => 
            new Promise((resolve) => {
              const img = new Image();
              img.onload = () => resolve(img);
              img.src = sticker.src;
            })
          )
        );

        // Draw each sticker
        activeStickers.forEach((sticker, index) => {
          const img = stickerImages[index];
          
          // Adjust sticker position relative to video
          const relativeX = sticker.x - videoOffsetX;
          const relativeY = sticker.y - videoOffsetY;
          
          // Scale sticker position and size
          const scaledX = relativeX * scaleX;
          const scaledY = relativeY * scaleY;
          
          // Use the smaller scale factor for size to prevent stretching
          const sizeScale = Math.min(scaleX, scaleY); 
          const scaledWidth = sticker.width * sizeScale;
          const scaledHeight = sticker.height * sizeScale;

          // Save context state
          ctx.save();

          // Move to sticker center for rotation
          ctx.translate(scaledX + scaledWidth / 2, scaledY + scaledHeight / 2);
          ctx.rotate(sticker.rotation * Math.PI / 180);

          // Draw sticker
          ctx.drawImage(
            img,
            -scaledWidth / 2,
            -scaledHeight / 2,
            scaledWidth,
            scaledHeight
          );

          // Restore context state
          ctx.restore();
        });
      }

      // Create blob and add to preview
      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.8));
      const url = URL.createObjectURL(blob);
      
      // Clear previous preview URLs and add new one
      setPreviewUrls([url]);
      setActiveStickers([]);
      
      // Stop camera
      stopAdditionalCamera();
      
      console.log('Photo captured successfully with stickers');
    } catch (error) {
      console.error('Error capturing photo:', error);
      setError('Failed to capture photo. Please try again.');
    }
  };

  const handleProfilePictureSelect = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    try {
      setUploadingProfile(true);
      setError('');

      // Validate file type
      if (!file.type.startsWith('image/')) {
        throw new Error('Please select a valid image file');
      }

      // Validate file size (5MB)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Image must be less than 5MB');
      }

      const reader = new FileReader();
      reader.onload = () => {
        setProfileImageSrc(reader.result);
        setShowCropModal(true);
        setProfilePicture(file);
        setUploadingProfile(false);
      };

      reader.onerror = () => {
        setError('Error reading file');
        setUploadingProfile(false);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      setError(error.message);
      setUploadingProfile(false);
    }
  };

  useEffect(() => {
    // Cleanup camera when component unmounts
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      if (additionalStream) {
        additionalStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream, additionalStream]);

  const startCamera = async () => {
    try {
      console.log('Starting camera...');
      setShowCamera(true);
      setCameraError(null);

      const constraints = {
        video: {
          facingMode: 'user',
          width: { ideal: 1280 },
          height: { ideal: 1280 }
        },
        audio: false
      };

      console.log('Requesting media with constraints:', constraints);
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log('Got media stream:', mediaStream);

      if (videoRef.current) {
        console.log('Setting video source...');
        videoRef.current.srcObject = mediaStream;
        await videoRef.current.play().catch(e => {
          console.error('Error playing video:', e);
          setCameraError('Error playing video stream');
        });
        setStream(mediaStream);
      } else {
        console.error('Video ref is null');
        setCameraError('Video element not found');
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
      setCameraError(error.message || t('profile.editProfile.cameraError'));
      setShowCamera(false);
    }
  };

  const stopCamera = () => {
    console.log('Stopping camera...');
    if (stream) {
      stream.getTracks().forEach(track => {
        console.log('Stopping track:', track);
        track.stop();
      });
      setStream(null);
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setShowCamera(false);
    setCameraError(null);
  };

  const capturePhoto = async () => {
    try {
      // Play shutter sound
      shutterSound.play().catch(err => console.log('Could not play shutter sound:', err));

      const canvas = document.createElement('canvas');
      const video = videoRef.current;
      
      // Force 1:1 aspect ratio
      const size = Math.min(video.videoWidth, video.videoHeight);
      canvas.width = size;
      canvas.height = size;
      
      const ctx = canvas.getContext('2d');
      
      // Calculate centering
      const offsetX = (video.videoWidth - size) / 2;
      const offsetY = (video.videoHeight - size) / 2;

      // Apply selected filter
      if (selectedFilter !== 'none') {
        ctx.filter = filters[selectedFilter];
      }

      // Draw the video frame centered and cropped to square
      ctx.drawImage(
        video, 
        offsetX, offsetY, size, size,  // Source rectangle
        0, 0, size, size               // Destination rectangle
      );

      // Convert to blob with proper MIME type
      const blob = await new Promise(resolve => 
        canvas.toBlob(resolve, 'image/jpeg', 0.9)
      );
      
      if (!blob) {
        throw new Error('Failed to create image blob');
      }

      const file = new File([blob], `profile-photo-${Date.now()}.jpg`, { 
        type: 'image/jpeg',
        lastModified: Date.now()
      });
      
      // Stop camera and show cropping
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      setShowCamera(false);
      
      // Set up cropping
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImageSrc(reader.result);
        setShowCropModal(true);
        setProfilePicture(file);
      };
      reader.onerror = () => {
        throw new Error('Failed to read captured photo');
      };
      reader.readAsDataURL(file);

    } catch (error) {
      console.error('Error capturing photo:', error);
      setError(t('profile.editProfile.uploadError'));
      stopCamera();
    }
  };

  const startAdditionalCamera = async () => {
    try {
      console.log('Starting additional camera...');
      setShowAdditionalCamera(true);
      setCameraError(null);

      const constraints = {
        video: {
          facingMode: 'user',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        },
        audio: false
      };

      console.log('Requesting media with constraints:', constraints);
      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      console.log('Got media stream:', mediaStream);

      if (additionalVideoRef.current) {
        console.log('Setting video source...');
        additionalVideoRef.current.srcObject = mediaStream;
        additionalVideoRef.current.onloadedmetadata = () => {
          console.log('Video metadata loaded, playing...');
          additionalVideoRef.current.play().catch(e => {
            console.error('Error playing video:', e);
            setCameraError('Error playing video stream');
          });
        };
      } else {
        console.error('Video ref is null');
        setCameraError('Video element not found');
      }

      setAdditionalStream(mediaStream);
    } catch (error) {
      console.error('Error accessing camera:', error);
      setCameraError(error.message || t('profile.editProfile.cameraError'));
      setShowAdditionalCamera(false);
    }
  };

  const stopAdditionalCamera = () => {
    console.log('Stopping additional camera...');
    if (additionalStream) {
      additionalStream.getTracks().forEach(track => {
        console.log('Stopping track:', track);
        track.stop();
      });
      setAdditionalStream(null);
    }
    if (additionalVideoRef.current) {
      additionalVideoRef.current.srcObject = null;
    }
    setShowAdditionalCamera(false);
    setCameraError(null);
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropFinish = async () => {
    try {
      const croppedImage = await getCroppedImg(profileImageSrc, croppedAreaPixels);
      const croppedImageUrl = URL.createObjectURL(croppedImage);
      setProfileImageSrc(croppedImageUrl);
      setProfilePicture(croppedImage);
      setShowCropModal(false);
    } catch (e) {
      console.error(e);
      setError('Failed to crop image');
    }
  };

  const compressImage = async (file) => {
    console.log('Starting image compression for file:', file.name, 'size:', file.size, 'type:', file.type);
    
    // First, validate the image
    if (!file.type.startsWith('image/')) {
      throw new Error('Invalid image format');
    }
    
    // Skip compression for small files (less than 200KB)
    if (file.size < 200 * 1024) {
      console.log('Image is already small, skipping compression');
      return file;
    }
    
    const options = {
      maxSizeMB: 1, // Max file size in MB
      maxWidthOrHeight: 1024, // Max width/height in pixels
      useWebWorker: true,
      maxIteration: 10, // Increase max iterations for better results
      exifOrientation: -1, // Preserve EXIF orientation
      onProgress: (progress) => console.log(`Compression progress: ${progress}%`)
    };

    try {
      // Add timeout to prevent hanging
      const compressionPromise = imageCompression(file, options);
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Image compression timed out')), 30000)
      );
      
      const compressedFile = await Promise.race([compressionPromise, timeoutPromise]);
      console.log('Compression complete:', 
        'Original:', file.size, 'bytes,',
        'Compressed:', compressedFile.size, 'bytes,',
        'Reduction:', Math.round((1 - compressedFile.size / file.size) * 100) + '%'
      );
      return compressedFile;
    } catch (error) {
      console.error('Error compressing image:', error);
      // If compression fails, try a fallback approach
      try {
        console.log('Trying fallback compression method...');
        return await fallbackCompression(file);
      } catch (fallbackError) {
        console.error('Fallback compression also failed:', fallbackError);
        // Return original file if all compression attempts fail
        console.log('Using original uncompressed file');
        return file;
      }
    }
  };
  
  // Fallback compression using Canvas API
  const fallbackCompression = async (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        try {
          // Create canvas
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          
          // Calculate new dimensions (max 1024px)
          if (width > height && width > 1024) {
            height = Math.round(height * (1024 / width));
            width = 1024;
          } else if (height > 1024) {
            width = Math.round(width * (1024 / height));
            height = 1024;
          }
          
          // Set canvas dimensions
          canvas.width = width;
          canvas.height = height;
          
          // Draw image on canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          
          // Convert to blob
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error('Canvas toBlob failed'));
                return;
              }
              
              // Create new file from blob
              const newFile = new File([blob], file.name, {
                type: 'image/jpeg',
                lastModified: Date.now()
              });
              
              console.log('Fallback compression complete:', 
                'Original:', file.size, 'bytes,',
                'Compressed:', newFile.size, 'bytes'
              );
              resolve(newFile);
            },
            'image/jpeg',
            0.7 // Quality (0.7 = 70%)
          );
        } catch (error) {
          reject(error);
        }
      };
      
      img.onerror = (error) => {
        reject(new Error('Failed to load image for fallback compression'));
      };
      
      // Load image from file
      img.src = URL.createObjectURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    setError(null);

    try {
      const formDataObj = new FormData();
      
      // Add profile fields with validation
      const profileData = {
        name: formData.name?.trim(),
        age: parseInt(formData.age, 10),
        gender: formData.gender,
        location: formData.location?.trim(),
        bio: formData.bio?.trim(),
        lookingFor: formData.lookingFor
      };

      // Validate age
      if (isNaN(profileData.age) || profileData.age < 18 || profileData.age > 100) {
        throw new Error('Please enter a valid age between 18 and 100');
      }

      // Add validated profile data
      Object.entries(profileData).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
          formDataObj.append(key, value);
          console.log(`Adding form field: ${key} = ${value}`);
        }
      });

      // Handle profile picture
      if (profilePicture) {
        try {
          let profileImageBlob;
          
          // If it's already a File object
          if (profilePicture instanceof File) {
            profileImageBlob = profilePicture;
          } 
          // If it's a Blob from cropping
          else if (profilePicture instanceof Blob) {
            profileImageBlob = new File([profilePicture], 'profile.jpg', {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
          } 
          // If it's a data URL or other format
          else {
            const response = await fetch(profilePicture);
            if (!response.ok) throw new Error('Failed to fetch profile picture');
            const blob = await response.blob();
            profileImageBlob = new File([blob], 'profile.jpg', {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
          }

          // Validate image size (max 5MB)
          if (profileImageBlob.size > 5 * 1024 * 1024) {
            throw new Error('Profile picture must be less than 5MB');
          }

          // Ensure we're sending a proper image file
          if (!profileImageBlob.type.startsWith('image/')) {
            throw new Error('Invalid image format');
          }

          // Compress the image before uploading
          const compressedImage = await compressImage(profileImageBlob);
          formDataObj.append('profilePicture', compressedImage, 'profile.jpg');
          console.log('Added profile picture:', compressedImage.size, 'bytes');
        } catch (error) {
          console.error('Error processing profile picture:', error);
          throw new Error('Failed to process profile picture: ' + error.message);
        }
      }

      // Handle additional pictures
      if (previewUrls.length > 0) {
        console.log(`Processing ${previewUrls.length} additional pictures`);
        const processedImages = [];

        for (let i = 0; i <previewUrls.length; i++) {
          try {
            const url = previewUrls[i];
            console.log(`Processing image ${i}:`, url.substring(0, 50) + '...');

            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch image ${i}`);
            
            const blob = await response.blob();
            
            // Validate image
            if (blob.size === 0) throw new Error('Empty image file');
            if (blob.size > 5 * 1024 * 1024) throw new Error('Image must be less than 5MB');
            if (!blob.type.startsWith('image/')) throw new Error('Invalid image type');

            processedImages.push({ blob, index: i });
            console.log(`Image ${i} processed:`, blob.size, 'bytes,', blob.type);
          } catch (error) {
            console.error(`Error processing image ${i}:`, error);
            // Continue with other images
          }
        }

        console.log(`Successfully processed ${processedImages.length} images`);
        
        // Add processed images to form data
        processedImages.forEach(({ blob, index }) => {
          formDataObj.append('additionalPictures', blob, `image-${index}.jpg`);
        });
      }

      // Log final form data
      console.log('Final Form Data:');
      for (const [key, value] of formDataObj.entries()) {
        if (value instanceof Blob) {
          console.log(`${key}: Blob (${value.size} bytes, ${value.type})`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }

      // Send the request
      console.log('Sending profile update request...');
      const token = localStorage.getItem('token');
      const response = await axios.put(
        `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}/api/profile`,
        formDataObj,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-auth-token': token,
            'authorization': `Bearer ${token}`
          },
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log('Upload Progress:', percentCompleted);
          }
        }
      );

      if (response.data) {
        console.log('Profile updated successfully:', response.data);
        await updateUser(response.data);
        navigate('/profile');
      }
    } catch (err) {
      console.error('Profile update error:', err);
      if (err.response?.data) {
        console.error('Server error response:', err.response.data);
      }
      
      let errorMessage = err.message;
      if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      } else if (!errorMessage.includes('Please')) {
        errorMessage = 'Failed to update profile: ' + errorMessage;
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const onEmojiClick = (emojiData) => {
    const { emoji } = emojiData;
    setFormData(prev => ({
      ...prev,
      bio: prev.bio + emoji
    }));
  };

  const stickerPreviewStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    zIndex: 10
  };

  const videoFilterStyle = selectedFilter !== 'none' ? {
    filter: filters[selectedFilter],
    WebkitFilter: filters[selectedFilter]
  } : {};

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      <motion.div 
        className="max-w-2xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <motion.h2 
              className="text-2xl font-bold text-white"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              {t('profile.editProfile.title', { count: previewUrls.length, max: MAX_IMAGES })}
            </motion.h2>
          </div>

          {error && (
            <motion.div 
              className="bg-red-400/80 backdrop-blur-sm text-white px-4 py-3 rounded-lg mb-4"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
            >
              {error}
            </motion.div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Profile Picture */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <h3 className="text-xl font-semibold text-white">
                {t('profile.editProfile.profilePicture')}
              </h3>

              <div className="relative w-32 h-32 mx-auto mb-6">
                <img
                  src={profileImageSrc}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full"
                />
                <div className="absolute -bottom-2 -right-2 flex space-x-2">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      imageInputRef.current?.click();
                    }}
                    className="p-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition-colors"
                    title={t('profile.editProfile.uploadPhoto')}
                  >
                    <ArrowUpTrayIcon className="w-5 h-5" />
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      startCamera();
                    }}
                    className="p-2 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition-colors"
                    title={t('profile.editProfile.takePhoto')}
                    type="button"
                  >
                    <CameraIcon className="w-5 h-5" />
                  </button>
                </div>
                <input
                  type="file"
                  ref={imageInputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => {
                    e.preventDefault();
                    handleProfilePictureSelect(e);
                  }}
                />
              </div>
            </motion.div>

            {/* Additional Pictures */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <label className="block text-lg font-medium text-white">
                {t('profile.editProfile.additionalPictures')}
              </label>

              {/* Encouragement message */}
              <div className="bg-gradient-to-r from-purple-500/20 to-blue-500/20 p-3 rounded-lg mb-4 border border-white/10">
                <p className="text-white text-sm">
                  <span className="font-bold">💡 Pro Tip:</span> Adding multiple high-quality photos significantly increases your chances of matching! Users with 4+ photos get up to 5x more matches.
                </p>
                <p className="text-white text-sm mt-1">
                  <span className="font-bold">💡 เคล็ดลับ:</span> การเพิ่มรูปภาพคุณภาพสูงหลายรูปจะเพิ่มโอกาสในการจับคู่ของคุณอย่างมาก! ผู้ใช้ที่มีรูปภาพ 4+ รูปจะได้รับการจับคู่มากขึ้นถึง 5 เท่า
                </p>
              </div>

              {/* Camera and Upload Controls */}
              <div className="flex gap-4 mb-4">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    additionalPicturesRef.current?.click();
                  }}
                  className="flex items-center gap-2 px-4 py-2 bg-white/10 hover:bg-white/20 rounded-lg transition-colors"
                  disabled={previewUrls.length >= MAX_IMAGES}
                >
                  <ArrowUpTrayIcon className="w-5 h-5" />
                  {t('profile.editProfile.uploadPhoto')}
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    startAdditionalCamera();
                  }}
                  className="flex items-center gap-2 px-4 py-2 bg-white/10 hover:bg-white/20 rounded-lg transition-colors"
                  disabled={previewUrls.length >= MAX_IMAGES}
                >
                  <CameraIcon className="w-5 h-5" />
                  {t('profile.editProfile.takePhoto')}
                </button>
                <input
                  type="file"
                  ref={additionalPicturesRef}
                  accept="image/*"
                  multiple
                  onChange={handleAdditionalPictureSelect}
                  className="hidden"
                />
              </div>
              
              {/* Save and Cancel Buttons */}
              <div className="flex gap-4 mb-6">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  onClick={() => navigate('/profile')}
                  className="flex-1 px-6 py-2 bg-white/10 backdrop-blur-sm text-white rounded-lg hover:bg-white/20 transition-colors"
                >
                  {t('profile.editProfile.buttons.cancel')}
                </motion.button>
                
                <motion.button
                  type="submit"
                  disabled={loading}
                  className={`flex-1 px-6 py-2 rounded-lg text-white font-semibold transition-all duration-200
                    ${loading 
                      ? 'bg-purple-400 cursor-not-allowed'
                      : 'bg-purple-600 hover:bg-purple-700 active:scale-95'
                    }`}
                  whileHover={{ scale: loading ? 1 : 1.02 }}
                  whileTap={{ scale: loading ? 1 : 0.98 }}
                >
                  <span className="flex items-center justify-center">
                    {loading ? (
                      <>
                        <motion.div 
                          className="w-5 h-5 border-2 border-[#FF1493] border-t-transparent rounded-full mr-2"
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                        />
                        {t('profile.editProfile.buttons.saving')}
                      </>
                    ) : (
                      t('profile.editProfile.buttons.save')
                    )}
                  </span>
                </motion.button>
              </div>

              {/* Image Grid */}
              <div className="grid grid-cols-4 gap-4">
                {previewUrls.map((url, index) => (
                  <motion.div 
                    key={index} 
                    className="relative aspect-square rounded-lg overflow-hidden group"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                  >
                    {uploadingAdditional ? (
                      <div className="w-full h-full bg-gray-100 rounded-lg flex items-center justify-center">
                        <div className="w-8 h-8 border-4 border-[#FF1493] border-t-transparent rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      <>
                        <img
                          src={url}
                          alt={`Additional ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => removeAdditionalPicture(index)}
                          className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-all duration-200"
                        >
                          <XMarkIcon className="w-4 h-4" />
                        </motion.button>
                      </>
                    )}
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Basic Information */}
            <motion.div 
              className="space-y-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                <label className="block text-white font-semibold mb-2">
                  {t('profile.editProfile.name')}
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={(e) => {
                    console.log('Updating name to:', e.target.value);
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-blue-300"
                  placeholder="Your name"
                />
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <label className="block text-white font-semibold mb-2">
                  {t('profile.editProfile.age')}
                </label>
                <input
                  type="number"
                  value={formData.age}
                  onChange={(e) => setFormData({ ...formData, age: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-blue-300"
                  required
                />
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <label className="block text-white font-semibold mb-2">
                  {t('profile.editProfile.gender')}
                </label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white focus:outline-none focus:border-blue-300 [&>option]:bg-purple-900 [&>option]:text-white"
                >
                  <option value="">{t('profile.editProfile.selectGender')}</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Ladyboy">Ladyboy</option>
                </select>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
              >
                <label className="block text-white font-semibold mb-2">
                  {t('profile.editProfile.lookingFor')}
                </label>
                <select
                  value={formData.lookingFor || ''}
                  onChange={(e) => setFormData({ ...formData, lookingFor: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white focus:outline-none focus:border-blue-300 [&>option]:bg-purple-900 [&>option]:text-white"
                >
                  <option value="">{t('profile.editProfile.selectPreference')}</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Ladyboy">Ladyboy</option>
                </select>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8 }}
              >
                <label className="block text-white font-semibold mb-2">
                  {t('profile.editProfile.location')}
                </label>
                <input
                  type="text"
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-blue-300"
                  required
                />
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.9 }}
              >
                <label className="block text-white font-semibold mb-2">
                  {t('profile.editProfile.bio')}
                </label>
                <div className="relative">
                  <textarea
                    value={formData.bio}
                    onChange={(e) => setFormData({ ...formData, bio: e.target.value })}
                    className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/50 focus:outline-none focus:border-blue-300 h-32"
                  />
                  <button
                    type="button"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    className="absolute right-2 bottom-2 text-white hover:text-blue-300 transition-colors"
                  >
                    <FaceSmileIcon className="w-6 h-6" />
                  </button>
                  {showEmojiPicker && (
                    <Suspense fallback={<div>Loading emoji picker...</div>}>
                      <div className="absolute bottom-full right-0 mb-2">
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          theme="dark"
                          width={300}
                          height={400}
                        />
                      </div>
                    </Suspense>
                  )}
                </div>
              </motion.div>
            </motion.div>

            <div className="flex justify-end mt-6 space-x-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="button"
                onClick={() => {
                  logout();
                  window.location.href = '/login';
                }}
                className="w-full px-6 py-2 bg-red-500/80 backdrop-blur-sm text-white rounded-lg hover:bg-red-600/80 transition-colors"
              >
                {t('profile.editProfile.buttons.logout')}
              </motion.button>
            </div>
          </form>
        </div>
      </motion.div>

      {/* Profile Camera Modal */}
      {showCamera && (
        <div className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4">
          <div className="bg-gray-900 rounded-xl shadow-xl w-full max-w-md overflow-hidden">
            {/* Top Bar */}
            <div className="p-4 flex justify-between items-center border-b border-gray-800">
              <h3 className="text-xl font-semibold text-white">
                {t('profile.editProfile.captureProfilePhoto')}
              </h3>
              <button
                onClick={() => {
                  if (stream) {
                    stream.getTracks().forEach(track => track.stop());
                  }
                  setShowCamera(false);
                  setCameraError(null);
                }}
                className="text-white hover:text-gray-300"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>

            {/* Camera View */}
            <div className="relative w-full max-w-md mx-auto aspect-square">
              <div className="relative w-full h-full">
                {cameraError ? (
                  <div className="absolute inset-0 flex items-center justify-center text-white bg-gray-900">
                    <p className="text-center px-4">{cameraError}</p>
                  </div>
                ) : (
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    className="w-full h-full object-cover"
                    style={{ filter: filters[selectedFilter] }}
                  />
                )}
              </div>

              {/* Camera Controls */}
              <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-4">
                {/* Filter Controls */}
                <div className="absolute bottom-16 left-0 right-0 overflow-x-auto px-4 pb-2 -mx-4 scrollbar-hide">
                  <div className="flex gap-2 mx-auto min-w-max px-4">
                    {Object.entries(filters).map(([name, filter]) => (
                      <button
                        key={name}
                        onClick={() => setSelectedFilter(name)}
                        className={`
                          px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap transition-colors
                          ${selectedFilter === name 
                            ? 'bg-purple-600 text-white' 
                            : 'bg-white/10 text-white hover:bg-white/20'
                          }
                        `}
                      >
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Capture Controls */}
                <div className="flex items-center justify-center space-x-4">
                  {/* Capture Button */}
                  <button
                    onClick={capturePhoto}
                    className="p-4 bg-white rounded-full hover:bg-white/90 transition-colors"
                  >
                    <div className="w-12 h-12 rounded-full border-4 border-purple-600" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Additional Camera Modal */}
      {showAdditionalCamera && (
        <div className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4">
          <div className="bg-gray-900 rounded-xl shadow-xl w-full max-w-md overflow-hidden">
            {/* Top Bar */}
            <div className="p-4 flex justify-between items-center border-b border-gray-800">
              <h3 className="text-xl font-semibold text-white">
                {t('profile.editProfile.capturePhoto')}
              </h3>
              <button
                onClick={stopAdditionalCamera}
                className="text-white hover:text-gray-300"
              >
                <XMarkIcon className="w-6 h-6" />
              </button>
            </div>

            {/* Camera View */}
            <div className="relative w-full max-w-md mx-auto aspect-[3/4]">
              <div className="relative w-full h-full">
                <video
                  ref={additionalVideoRef}
                  autoPlay
                  playsInline
                  className="w-full h-full object-cover"
                  style={{ filter: filters[selectedFilter] }}
                />
                
                {/* Stickers overlay */}
                <div 
                  className="absolute inset-0 pointer-events-none"
                  style={stickerPreviewStyle}
                >
                  {activeStickers.map((sticker, index) => (
                    <div
                      key={index}
                      className="absolute cursor-move group"
                      style={{
                        left: `${sticker.x}px`,
                        top: `${sticker.y}px`,
                        width: `${sticker.width}px`,
                        height: `${sticker.height}px`,
                        transform: `rotate(${sticker.rotation}deg)`,
                        pointerEvents: 'auto',
                        zIndex: sticker.isDragging || sticker.isResizing ? 20 : 10,
                        touchAction: 'none'
                      }}
                      onPointerDown={(e) => {
                        if (e.target === e.currentTarget || e.target.tagName === 'IMG') {
                          e.preventDefault();
                          e.stopPropagation();
                          
                          const target = e.currentTarget;
                          const rect = target.getBoundingClientRect();
                          const startX = e.clientX - rect.left;
                          const startY = e.clientY - rect.top;
                          
                          setActiveStickers(prev => prev.map((s, i) => 
                            i === index ? { ...s, isDragging: true } : s
                          ));
                          
                          const handlePointerMove = (moveEvent) => {
                            moveEvent.preventDefault();
                            const containerRect = target.parentElement.getBoundingClientRect();
                            const newX = moveEvent.clientX - containerRect.left - startX;
                            const newY = moveEvent.clientY - containerRect.top - startY;
                            handleStickerDrag(index, newX, newY);
                          };
                          
                          const handlePointerUp = () => {
                            document.removeEventListener('pointermove', handlePointerMove);
                            document.removeEventListener('pointerup', handlePointerUp);
                            setActiveStickers(prev => prev.map((s, i) => 
                              i === index ? { ...s, isDragging: false } : s
                            ));
                          };
                          
                          document.addEventListener('pointermove', handlePointerMove);
                          document.addEventListener('pointerup', handlePointerUp);
                        }
                      }}
                    >
                      <img
                        src={sticker.src}
                        alt={sticker.name}
                        className="w-full h-full select-none pointer-events-none"
                        draggable="false"
                      />
                      {/* Resize handle */}
                      <div 
                        className="absolute -bottom-1 -right-1 w-4 h-4 bg-white border-2 border-blue-500 rounded-full cursor-se-resize hover:scale-125 transition-transform"
                        onPointerDown={(e) => handleStickerResize(index, e)} 
                      />
                      
                      {/* Rotate handle */}
                      <div 
                        className="absolute -top-6 left-1/2 w-4 h-4 bg-white border-2 border-green-500 rounded-full cursor-pointer hover:scale-125 transition-transform -translate-x-1/2"
                        onPointerDown={(e) => handleStickerRotate(index, e)}
                      >
                        <div className="absolute left-1/2 top-full w-0.5 h-4 bg-green-500 -translate-x-1/2" />
                      </div>
                      
                      {/* Delete button */}
                      <button
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center cursor-pointer hover:bg-red-600 transition-colors"
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveStickers(prev => prev.filter((_, i) => i !== index));
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Camera Controls */}
              <div className="absolute bottom-4 left-0 right-0 flex flex-col items-center space-y-4 px-4">
                {/* Filter Controls */}
                <div className="absolute bottom-16 left-0 right-0 overflow-x-auto px-4 pb-2 -mx-4 scrollbar-hide">
                  <div className="flex gap-2 mx-auto min-w-max px-4">
                    {Object.entries(filters).map(([name, filter]) => (
                      <button
                        key={name}
                        onClick={() => setSelectedFilter(name)}
                        className={`
                          px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap transition-colors
                          ${selectedFilter === name 
                            ? 'bg-purple-600 text-white' 
                            : 'bg-white/10 text-white hover:bg-white/20'
                          }
                        `}
                      >
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Sticker and Capture Controls */}
                <div className="flex items-center justify-center space-x-4 w-full">
                  {/* Sticker Button */}
                  <button
                    onClick={() => setShowStickers(!showStickers)}
                    className={`p-3 rounded-full ${
                      showStickers ? 'bg-purple-600' : 'bg-white/10'
                    } text-white hover:bg-purple-700 transition-colors`}
                  >
                    <FaceSmileIcon className="w-6 h-6" />
                  </button>

                  {/* Capture Button */}
                  <button
                    onClick={captureAdditionalPhoto}
                    className="p-4 bg-white rounded-full hover:bg-white/90 transition-colors"
                  >
                    <div className="w-12 h-12 rounded-full border-4 border-purple-600" />
                  </button>

                  {/* Close Camera Button */}
                  <button
                    onClick={stopAdditionalCamera}
                    className="p-3 rounded-full bg-white/10 text-white hover:bg-white/20 transition-colors"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>

                {/* Sticker Panel */}
                {showStickers && (
                  <div className="absolute bottom-full left-0 right-0 mb-4 bg-black/80 backdrop-blur-sm p-4 rounded-lg">
                    <div className="grid grid-cols-4 gap-4 max-h-40 overflow-y-auto">
                      {stickers.map((sticker, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            addSticker(sticker.src);
                            setShowStickers(false);
                          }}
                          className="p-2 hover:bg-white/10 rounded-lg transition-colors"
                        >
                          <img
                            src={sticker.src}
                            alt={sticker.name}
                            className="w-8 h-8 object-contain"
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Image Cropping Modal */}
      {showCropModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4">
          <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-xl p-6 max-w-2xl w-full">
            <h3 className="text-xl font-semibold mb-4 text-white">
              {t('profile.editProfile.cropModal.title')}
            </h3>
            <div className="relative h-96 bg-black rounded-lg overflow-hidden">
              <Cropper
                image={profileImageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                objectFit="contain"
              />
            </div>

            <div className="mt-4 flex justify-between items-center">
              <div className="flex-1 mr-4">
                <label className="block text-sm font-medium text-white mb-1">
                  {t('profile.editProfile.cropModal.zoom')} {zoom.toFixed(1)}x
                </label>
                <input
                  type="range"
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e) => setZoom(Number(e.target.value))}
                  className="w-full"
                />
              </div>
              <div className="flex space-x-2">
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowCropModal(false);
                    setProfileImageSrc(currentUser?.profile?.profilePicture || '/images/placeholder.jpg');
                  }}
                  className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
                >
                  {t('profile.editProfile.cropModal.cancel')}
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleCropFinish();
                  }}
                  className="px-4 py-2 bg-white text-purple-600 rounded-lg hover:bg-white/90 transition-colors"
                >
                  {t('profile.editProfile.cropModal.save')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .animate-flash {
          animation: flash 500ms ease-out forwards;
        }
        @keyframes flash {
          0% { opacity: 0; }
          50% { opacity: 0.8; }
          100% { opacity: 0; }
        }
      `}</style>
    </div>
  );
}

export default EditProfile;


