import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function Terms() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      <Helmet>
        <title>Terms of Service - ThaiMeetUp.com</title>
        <meta name="description" content="Terms of Service for ThaiMeetUp.com - Read our user agreement" />
      </Helmet>

      <div className="max-w-4xl mx-auto bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-8 text-white">
        {/* Back Button */}
        <motion.button
          onClick={() => navigate('/')}
          className="mb-6 text-blue-200 hover:text-white transition-colors flex items-center gap-2"
          whileHover={{ x: -2 }}
        >
          ← Back to Home
        </motion.button>

        <motion.h1 
          className="text-4xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          Terms of Service
          <span className="block text-2xl mt-2">ข้อกำหนดการใช้งาน</span>
        </motion.h1>

        <motion.div
          className="space-y-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p className="text-white/90">
              By accessing and using ThaiMeetUp.com, you agree to be bound by these Terms of Service and all applicable laws and regulations.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Eligibility</h2>
            <p className="text-white/90 mb-4">You must meet the following criteria to use our services:</p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>Be at least 18 years old</li>
              <li>Have the legal capacity to enter into agreements</li>
              <li>Not be prohibited by law from using our services</li>
              <li>Agree to provide accurate and truthful information</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. User Conduct</h2>
            <p className="text-white/90 mb-4">You agree not to:</p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>Harass, abuse, or harm other users</li>
              <li>Post false or misleading information</li>
              <li>Share explicit or inappropriate content</li>
              <li>Attempt to circumvent our security measures</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Account Security</h2>
            <p className="text-white/90 mb-4">You are responsible for:</p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>Maintaining the confidentiality of your account</li>
              <li>All activities that occur under your account</li>
              <li>Notifying us of any unauthorized access</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Termination</h2>
            <p className="text-white/90">
              We reserve the right to terminate or suspend your account at our sole discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, us, or third parties, or for any other reason.
            </p>
          </section>

          {/* Thai Version Summary */}
          <section className="mt-12 pt-8 border-t border-white/20">
            <h2 className="text-2xl font-semibold mb-4">สรุปข้อกำหนดการใช้งาน</h2>
            <p className="text-white/90 mb-4">
              ในการใช้บริการของเรา คุณต้อง:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>มีอายุ 18 ปีขึ้นไป</li>
              <li>ให้ข้อมูลที่เป็นความจริง</li>
              <li>ไม่ละเมิดสิทธิ์ของผู้อื่น</li>
              <li>รักษาความปลอดภัยของบัญชีผู้ใช้</li>
            </ul>
            <p className="mt-4 text-white/90">
              เราขอสงวนสิทธิ์ในการระงับหรือยกเลิกบัญชีที่ละเมิดข้อกำหนด
            </p>
          </section>
        </motion.div>
      </div>
    </div>
  );
}

export default Terms;
