import { 
    List, 
    Datagrid, 
    TextField, 
    EmailField, 
    BooleanField,
    ImageField,
    DateField,
    FilterButton,
    ExportButton,
    TopToolbar,
    SearchInput,
    Button,
    useRecordContext,
    useNotify,
    useRefresh,
    BooleanInput,
    Filter,
    TextInput,
    useDataProvider
} from 'react-admin';
import { useState } from 'react';

// Define filters properly
const UserFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <TextInput source="email" label="Email" />,
    <TextInput source="profile.location" label="Location" />,
    <BooleanInput source="suspended" label="Suspended" />
];

const UserActions = () => (
    <TopToolbar>
        <FilterButton filters={UserFilters} />
        <ExportButton />
    </TopToolbar>
);

const SuspendButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();

    const handleClick = async () => {
        if (!window.confirm(`Are you sure you want to ${record.suspended ? 'unsuspend' : 'suspend'} this user?`)) {
            return;
        }
        setLoading(true);
        try {
            await dataProvider.update('users', { 
                id: record.id, 
                data: { suspended: !record.suspended } 
            });
            notify(record.suspended ? 'User unsuspended' : 'User suspended', { type: 'success' });
            refresh();
        } catch (error) {
            notify('Error updating user', { type: 'error' });
        }
        setLoading(false);
    };

    return (
        <Button
            label={record.suspended ? "Unsuspend" : "Suspend"}
            onClick={handleClick}
            disabled={loading}
            color={record.suspended ? "primary" : "warning"}
        />
    );
};

const DeleteButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();

    const handleClick = async () => {
        if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
            return;
        }
        setLoading(true);
        try {
            await dataProvider.delete('users', { id: record.id });
            notify('User deleted successfully', { type: 'success' });
            refresh();
        } catch (error) {
            notify('Error deleting user', { type: 'error' });
        }
        setLoading(false);
    };

    return (
        <Button
            label="Delete"
            onClick={handleClick}
            disabled={loading}
            color="error"
        />
    );
};

const VerifyButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();

    const handleClick = async () => {
        setLoading(true);
        try {
            await dataProvider.update('users', { 
                id: record.id, 
                data: { verified: !record.verified } 
            });
            notify(record.verified ? 'User verification removed' : 'User verified', { type: 'success' });
            refresh();
        } catch (error) {
            notify('Error updating verification status', { type: 'error' });
        }
        setLoading(false);
    };

    return (
        <Button
            label={record.verified ? "Unverify" : "Verify"}
            onClick={handleClick}
            disabled={loading}
            color={record.verified ? "secondary" : "success"}
        />
    );
};

export const UserList = () => (
    <List 
        filters={UserFilters}
        actions={<UserActions />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
    >
        <Datagrid>
            {/* Custom component to display profile images */}
            <Datagrid.Cell label="Photo" sortBy="profile.name">
            {({ record }) => {
                // Get profile picture URL
                const profilePic = record?.profile?.profilePicture;
                
                // Debug logging
                console.log('Profile picture path:', {
                    profilePic,
                    user: record?.profile?.name,
                    userId: record?.id
                });
                
                // Use placeholder if no image or broken path
                const imageSrc = profilePic 
                    ? (profilePic.startsWith('http') ? profilePic : profilePic.startsWith('/') ? profilePic : `/${profilePic}`) 
                    : '/images/placeholder.jpg';
                
                return (
                    <div style={{ 
                        width: 50, 
                        height: 50, 
                        borderRadius: '50%',
                        overflow: 'hidden',
                        flexShrink: 0
                    }}>
                        <img 
                            src={imageSrc}
                            alt={record?.profile?.name || "User"} 
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                            onError={(e) => {
                                // Handle broken image by loading placeholder
                                e.target.src = '/images/placeholder.jpg';
                            }}
                        />
                    </div>
                );
            }}
            </Datagrid.Cell>
            <TextField source="profile.name" label="Name" />
            <EmailField source="email" />
            <TextField source="profile.age" label="Age" />
            <TextField source="profile.location" label="Location" />
            <TextField source="membershipType" label="Plan" />
            <DateField 
                source="createdAt" 
                label="Joined" 
                showTime
                locales="en-GB"
                options={{ 
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
            />
            <DateField 
                source="lastActive" 
                label="Last Active" 
                showTime
                locales="en-GB"
                options={{ 
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
            />
            <BooleanField source="suspended" />
            <BooleanField source="verified" />
            <VerifyButton />
            <SuspendButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default UserList; 


