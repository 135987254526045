import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const LanguageSwitcher = ({ isScrolled }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <div className="flex items-center space-x-2">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => changeLanguage('en')}
        className={`px-3 py-1 rounded-full transition-all duration-200 ${
          i18n.language === 'en'
            ? isScrolled
              ? 'bg-purple-600 text-white'
              : 'bg-white/20 text-white'
            : isScrolled
              ? 'bg-white/10 text-gray-700 hover:bg-white/20'
              : 'bg-white/10 text-white hover:bg-white/20'
        }`}
      >
        EN
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => changeLanguage('th')}
        className={`px-3 py-1 rounded-full transition-all duration-200 ${
          i18n.language === 'th'
            ? isScrolled
              ? 'bg-purple-600 text-white'
              : 'bg-white/20 text-white'
            : isScrolled
              ? 'bg-white/10 text-gray-700 hover:bg-white/20'
              : 'bg-white/10 text-white hover:bg-white/20'
        }`}
      >
        ไทย
      </motion.button>
    </div>
  );
};

export default LanguageSwitcher;
