import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Index() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    // If user is logged in, redirect to browse
    if (currentUser) {
      navigate('/browse');
    } else {
      // If not logged in, redirect to landing page
      navigate('/landing');
    }
  }, [currentUser, navigate]);

  // Return null since this is just a router
  return null;
}

export default Index; 