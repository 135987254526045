import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [unreadCount, setUnreadCount] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close mobile menu when route changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    let isMounted = true;
    let intervalId;

    const checkUnreadMessages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/messages/unread', {
          headers: { 'x-auth-token': token }
        });
        if (isMounted) {
          setUnreadCount(response.data.count || 0);
        }
      } catch (error) {
        console.error('Error checking unread messages:', error);
      }
    };

    if (currentUser) {
      checkUnreadMessages();
      intervalId = setInterval(checkUnreadMessages, 30000);
    }

    return () => {
      isMounted = false;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentUser]);

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'th' : 'en';
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  if (!currentUser) return null;

  const navItems = [
    { path: '/browse', label: t('nav.browse') },
    { path: '/swipe', label: t('nav.swipe') },
    { path: '/messages', label: t('nav.messages'), hasNotification: unreadCount > 0, count: unreadCount },
    { path: '/profile', label: t('nav.profile'), 
      icon: currentUser?.profile?.profilePicture 
        ? `https://thaimeetup.com${currentUser.profile.profilePicture}`
        : '/images/default-avatar.png'
    },
    ...(currentUser?.isAdmin ? [{ path: '/admin', label: 'Admin' }] : []),
  ];

  const NotificationBadge = ({ count }) => (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center"
    >
      {count}
    </motion.div>
  );

  return (
    <nav 
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-200 ${
        isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="flex justify-between items-center">
          {/* Logo/Brand */}
          <Link to="/browse" className="flex items-center">
            <motion.img
              src="/images/thaimeetup-logo1.png"
              alt="ThaiMeetUp"
              className="h-12 w-auto object-contain"
              whileHover={{ scale: 1.05 }}
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            {navItems.map((item) => (
              <motion.div
                key={item.path}
                whileHover={{ scale: 1.05 }}
                className="relative"
              >
                <Link 
                  to={item.path}
                  className={`px-4 py-2 rounded-full transition-all duration-200 text-lg font-medium relative group flex items-center ${
                    location.pathname === item.path 
                      ? isScrolled
                        ? 'bg-pink-100 text-pink-600'
                        : 'bg-white/20 text-white'
                      : isScrolled
                        ? 'text-gray-700 hover:bg-pink-50 hover:text-pink-600'
                        : 'text-white hover:bg-white/20'
                  }`}
                >
                  {item.icon ? (
                    <>
                      <img 
                        src={item.icon} 
                        alt="Profile" 
                        className="w-8 h-8 rounded-full object-cover border-2 border-current"
                      />
                      <span className="ml-2">{item.label}</span>
                    </>
                  ) : (
                    item.label
                  )}
                  {item.hasNotification && <NotificationBadge count={item.count} />}
                  <span className={`absolute bottom-0 left-1/2 w-1/3 h-0.5 transform -translate-x-1/2 scale-x-0 transition-transform group-hover:scale-x-100 ${
                    location.pathname === item.path 
                      ? 'bg-pink-600' 
                      : isScrolled ? 'bg-pink-400' : 'bg-white'
                  }`} />
                </Link>
              </motion.div>
            ))}

            {/* Language Toggle */}
            <motion.button
              whileHover={{ scale: 1.1 }}
              onClick={toggleLanguage}
              className={`flex items-center space-x-2 px-3 py-1.5 rounded-full transition-all duration-200 ${
                isScrolled 
                  ? 'text-gray-700 hover:text-pink-600 hover:bg-pink-50 bg-gray-50' 
                  : 'text-white hover:text-pink-200 hover:bg-white/20 bg-white/10'
              }`}
            >
              <span className={`text-xl ${i18n.language === 'en' ? 'opacity-50' : 'opacity-100'}`}>
                🇬🇧
              </span>
              <span className="text-sm font-medium">/</span>
              <span className={`text-xl ${i18n.language === 'th' ? 'opacity-50' : 'opacity-100'}`}>
                🇹🇭
              </span>
            </motion.button>
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-pink-50"
          >
            <svg
              className={`w-6 h-6 ${isScrolled ? 'text-gray-700' : 'text-white'}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-gradient-to-r from-pink-400 to-pink-500 shadow-lg"
          >
            <div className="px-4 pt-2 pb-4 space-y-2">
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`block px-4 py-3 rounded-lg text-base font-medium relative ${
                    location.pathname === item.path
                      ? 'bg-white/20 text-white'
                      : 'text-white/90 hover:bg-white/10'
                  }`}
                >
                  <span className="flex items-center">
                    {item.icon && (
                      <img 
                        src={item.icon} 
                        alt="Profile" 
                        className="w-8 h-8 rounded-full object-cover border-2 border-white mr-3"
                      />
                    )}
                    {item.label}
                    {item.hasNotification && (
                      <span className="ml-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                        {item.count}
                      </span>
                    )}
                  </span>
                </Link>
              ))}
              <button
                onClick={toggleLanguage}
                className="w-full text-left px-4 py-3 rounded-lg text-base font-medium text-white/90 hover:bg-white/10 flex items-center"
              >
                <span className="text-xl mr-2">
                  {i18n.language === 'en' ? '🇹🇭' : '🇬🇧'}
                </span>
                {i18n.language === 'en' ? 'ภาษาไทย' : 'English'}
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}

export default Navbar;
