import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import config from '../../config';

function PhotoNotification({ notification, onMarkAsRead }) {
  const API_URL = config.API_URL;
  const formatTimeAgo = (date) => {
    // implement your formatTimeAgo function here
  };

  const renderNotificationContent = () => {
    const senderName = notification.fromUser?.profile?.name || 'Someone';
    const profilePicUrl = notification.fromUser?.profile?.profilePicture || '/default-avatar.png';

    return (
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          <img
            src={profilePicUrl.startsWith('http') ? profilePicUrl : `${API_URL}${profilePicUrl}`}
            alt={`${senderName}'s profile`}
            className="h-10 w-10 rounded-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/default-avatar.png';
            }}
          />
        </div>
        <div className="flex-grow">
          <p className="text-sm">
            <span className="font-medium">{senderName}</span>
            {notification.type === 'private_photo' ? (
              ' has shared a private photo with you'
            ) : notification.type === 'gallery_share' ? (
              ' has shared their private gallery with you'
            ) : (
              notification.message || 'sent you a notification'
            )}
          </p>
          <p className="text-xs text-gray-500 mt-1">
            {formatTimeAgo(new Date(notification.createdAt))}
          </p>
          {!notification.read && (
            <button
              onClick={() => onMarkAsRead(notification._id)}
              className="text-xs text-purple-500 hover:text-purple-600 mt-2"
            >
              Mark as read
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow"
    >
      {renderNotificationContent()}
      <Link 
        to={`/browse/user/${notification.fromUser?._id}`}
        className="mt-2 block text-center py-2 bg-purple-100 text-purple-600 rounded-lg hover:bg-purple-200 transition-colors"
      >
        View Profile
      </Link>
    </motion.div>
  );
}

export default PhotoNotification;