import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import { useWebSocket } from '../../contexts/WebSocketContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import config from '../../config';

// Configure axios
const axiosInstance = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Premium overlay component
const PremiumOverlay = ({ onClose }) => {
  const navigate = useNavigate();
  
  const handleUpgradeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/upgrade');
  };

  return (
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-900/95 to-gray-900 flex flex-col items-center justify-center p-6 text-center backdrop-blur z-50">
      <h3 className="text-2xl font-semibold text-white mb-4">Want to Continue Chatting?</h3>
      <p className="text-gray-300 mb-6">Upgrade to Premium to send unlimited messages and see all conversations</p>
      <div className="space-y-4">
        <button
          onClick={handleUpgradeClick}
          className="block bg-gradient-to-r from-pink-500 to-purple-500 text-white px-8 py-3 rounded-full font-semibold hover:from-pink-600 hover:to-purple-600 transition-all transform hover:scale-105"
        >
          Upgrade Now
        </button>
        {onClose && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}
            className="block w-full text-white/70 hover:text-white px-8 py-3 rounded-full font-semibold transition-all"
          >
            Maybe Later
          </button>
        )}
      </div>
    </div>
  );
};

function Messages() {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [notification, setNotification] = useState(null);
  const [messageCount, setMessageCount] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, isPremium, isFemale, isLadyboy } = useAuth();
  const { onlineUsers, requestOnlineUsers } = useWebSocket();
  const activeConversationId = location.pathname.split('/').pop();

  // Check if user has full access (Female, Ladyboy, or Premium)
  const hasFullAccess = isPremium || isFemale || isLadyboy;

  // Force refresh on first mount to ensure latest state
  useEffect(() => {
    const lastRefresh = sessionStorage.getItem('messagesLastRefresh');
    const currentTime = new Date().getTime();
    
    // Only refresh if we haven't refreshed in the last minute
    if (!lastRefresh || currentTime - parseInt(lastRefresh) > 60000) {
      sessionStorage.setItem('messagesLastRefresh', currentTime.toString());
      window.location.reload();
    }
  }, []);

  // Watch for membership changes
  useEffect(() => {
    // Hide overlay if user has full access
    if (hasFullAccess) {
      setShowOverlay(false);
      localStorage.removeItem('premiumOverlayDismissed');
    } else {
      // Show overlay if message count is high enough
      const dismissed = localStorage.getItem('premiumOverlayDismissed');
      if (messageCount > 6 && !dismissed) {
        setShowOverlay(true);
      }
    }
  }, [isPremium, isFemale, isLadyboy, hasFullAccess, messageCount]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
    
    console.log('Messages: Component mounted, requesting online users');
    requestOnlineUsers();
    fetchConversations();
    markAllMessagesAsRead();
  }, [currentUser, navigate, requestOnlineUsers]);

  useEffect(() => {
    if (!onlineUsers || !conversations.length) return;

    // Only count messages sent BY the current user
    const userMessages = conversations.reduce((count, conv) => {
      return count + (conv.messages || []).filter(msg => 
        msg.sender === currentUser._id || 
        (typeof msg.sender === 'object' && msg.sender._id === currentUser._id)
      ).length;
    }, 0);
    
    setMessageCount(userMessages);
  }, [onlineUsers, conversations, currentUser._id]);

  const stats = useMemo(() => ({
    online: onlineUsers?.size || 0,
    active: conversations.filter(c => onlineUsers?.has(c.userId)).length,
    remaining: !hasFullAccess ? Math.max(0, 7 - messageCount) : null
  }), [onlineUsers, conversations, hasFullAccess, messageCount]);

  const handleDeleteConversation = async (partnerId, e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!window.confirm('Are you sure you want to delete this entire conversation?')) {
      return;
    }

    try {
      console.log('Deleting conversation with partner:', partnerId);
      
      // Get auth token
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axiosInstance.delete(`/api/messages/conversation/${partnerId}`, {
        headers: {
          'x-auth-token': token,
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.data.success) {
        setConversations(prev => prev.filter(conv => conv.userId !== partnerId));
        
        if (location.pathname === `/messages/${partnerId}`) {
          navigate('/messages');
        }
        
        setError('');
        setSuccessMessage('Conversation deleted successfully');
        
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        setSuccessMessage('');
        setError(response.data.message || 'Failed to delete conversation');
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
      setSuccessMessage('');
      setError(error.response?.data?.message || 'Failed to delete conversation');
    }
  };

  const fetchConversations = async () => {
    try {
      setLoading(true);
      setError('');

      // Get auth token
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }
      
      const response = await axiosInstance.get('/api/messages/conversations', {
        headers: {
          'x-auth-token': token,
          'Authorization': `Bearer ${token}`
        }
      });
      
      console.log('Raw conversations data:', response.data);
      
      if (response.data && Array.isArray(response.data)) {
        setConversations(response.data);
      } else {
        console.error('Invalid conversations data:', response.data);
        setError('Failed to load conversations');
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
      const errorMessage = error.response?.data?.message || error.message || 'Failed to load conversations';
      setError(errorMessage);
      setConversations([]);
    } finally {
      setLoading(false);
    }
  };

  const markAllMessagesAsRead = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      // Use config.apiUrl instead of hardcoded URL
      await axiosInstance.put(`${config.apiUrl}/messages/read-all`, {}, {
        headers: {
          'x-auth-token': token,
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const handleConversationClick = (userId) => {
    navigate(`/messages/${userId}`);
  };

  const handleOverlayClose = () => {
    setShowOverlay(false);
    localStorage.setItem('premiumOverlayDismissed', new Date().toISOString());
  };

  const getPlaceholderText = () => {
    const placeholders = [
      "Hi! I'd love to get to know you better...",
      "Hello! I saw your profile and wanted to say...",
      "Hey there! I think we have a lot in common...",
      "Hi, I noticed we share similar interests in...",
      "Hello! I really liked what you wrote about..."
    ];
    return placeholders[Math.floor(Math.random() * placeholders.length)];
  };

  const handleMessageClick = (message) => {
    if (!hasFullAccess && messageCount >= 7) {
      setShowOverlay(true);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-purple-600 via-indigo-600 to-blue-600">
      <div className="max-w-7xl mx-auto pt-16"> 
        <div className="bg-white/10 backdrop-blur-sm rounded-3xl shadow-xl p-8 mx-4 mb-4">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          
          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
              <span className="block sm:inline">{successMessage}</span>
            </div>
          )}
          
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
            </div>
          ) : (
            <>
              <div className="mb-8">
                <div className="flex items-center gap-3 mb-3">
                  <h1 className="text-3xl font-bold text-white">{t('messages.messageList.title')}</h1>
                  <span className="text-3xl">💬</span>
                </div>
                <p className="text-white/70 mb-4 text-lg">
                  {t('messages.messageList.keepChatting', { count: conversations.length })}
                </p>
                <div className="flex items-center space-x-4 text-white/70">
                  <div className="flex items-center">
                    <span className="w-2 h-2 bg-green-400 rounded-full mr-2 animate-pulse"></span>
                    <span>{t('messages.messageList.onlineCount', { count: stats.online })}</span>
                  </div>
                  <div className="flex items-center">
                    <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                    <span>{t('messages.messageList.activeChats', { count: stats.active })}</span>
                  </div>
                  {!hasFullAccess && (
                    <div className="flex items-center">
                      <span className="w-2 h-2 bg-pink-400 rounded-full mr-2"></span>
                      <span>{t('messages.messageList.freeMessagesRemaining', { count: stats.remaining })}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="space-y-4 relative">
                <AnimatePresence>
                  {conversations.map(conversation => (
                    <motion.div
                      key={conversation.userId}
                      onClick={() => handleConversationClick(conversation.userId)}
                      className={`group relative bg-white/5 backdrop-blur-sm rounded-2xl p-4 shadow-lg cursor-pointer hover:bg-white/10 transition-all ${
                        conversation.userId === activeConversationId ? 'ring-2 ring-purple-400' : ''
                      }`}
                      whileHover={{ scale: 1.02 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2 }}
                    >
                      <button
                        onClick={(e) => handleDeleteConversation(conversation.userId, e)}
                        className="absolute right-3 top-3 p-2 text-white/40 hover:text-red-400 hover:bg-white/10 rounded-full transition-all duration-200 opacity-0 group-hover:opacity-100"
                        title="Delete conversation"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>

                      <div className="flex items-center space-x-4">
                        <div className="relative">
                          <img
                            src={conversation.partner?.profile?.profilePicture || '/default-avatar.png'}
                            alt={conversation.partner?.profile?.name || 'Unknown User'}
                            className="w-14 h-14 rounded-full border-2 border-white/20 object-cover"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/default-avatar.png';
                            }}
                          />
                          {onlineUsers.has(conversation.userId) && (
                            <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-400 border-2 border-white rounded-full animate-pulse" />
                          )}
                        </div>

                        <div>
                          <h3 className="font-semibold text-white">
                            {conversation.partner?.profile?.name || 'Unknown User'}
                          </h3>
                          <p className={`text-white/80 text-sm ${!hasFullAccess ? 'blur-sm select-none' : ''}`}>
                            {conversation.lastMessage?.content || 'No messages yet'}
                          </p>
                          {!hasFullAccess && (
                            <Link 
                              to="/upgrade"
                              className="text-sm text-black font-semibold flex items-center justify-center gap-1.5 bg-pink-100 hover:bg-pink-200 px-4 py-2 rounded-full transition-all mt-2"
                            >
                              <span>✨</span>
                              <span>Upgrade to Premium to unlock all messages</span>
                              <span>→</span>
                            </Link>
                          )}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>

                {conversations.length === 0 && !loading && !error && (
                  <motion.div 
                    className="text-center py-16 bg-white/5 backdrop-blur-sm rounded-2xl"
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p className="text-white/70 text-lg">{t('messages.noConversations')}</p>
                  </motion.div>
                )}
              </div>
            </>
          )}
          {showOverlay && (
            <PremiumOverlay onClose={handleOverlayClose} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Messages;