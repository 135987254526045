import { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { format } from 'date-fns';
import { useWebSocket } from '../../contexts/WebSocketContext';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { toast } from 'react-toastify';
import PremiumBadge from '../common/PremiumBadge';
import ProfileCompletionPrompt from './ProfileCompletionPrompt';
import FeatureGuide from '../common/FeatureGuide';

// Default placeholder image as data URL - a simple gray avatar
const DEFAULT_AVATAR = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgZmlsbD0iI2U1ZTdlYiIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjcwIiByPSI1MCIgZmlsbD0iI2E3YWFhZCIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjIzMCIgcj0iMTAwIiBmaWxsPSIjYTdhYWFkIi8+PC9zdmc+';

function Profile() {
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [recentMessages, setRecentMessages] = useState([]);
  const navigate = useNavigate();
  const { userId } = useParams();
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [receivedInterests, setReceivedInterests] = useState([]);
  const [sentInterests, setSentInterests] = useState([]);
  const webSocket = useWebSocket();
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [hasGalleryAccess, setHasGalleryAccess] = useState(false);
  const [hasShownInterest, setHasShownInterest] = useState(false);
  const { t, i18n } = useTranslation();

  // Define isOwnProfile before it's used
  const isOwnProfile = !userId || (currentUser && userId === currentUser._id);

  const isFreeUser = currentUser?.profile?.gender === 'Male' && 
                    currentUser?.membership?.type !== 'premium';

  const formatMessageContent = (message) => {
    if (isFreeUser) {
      const partner = message.sender._id === currentUser._id ? 
        message.recipient.profile?.name : 
        message.sender.profile?.name;
      return `${partner} has sent you a message`;
    }
    return message.content;
  };

  useEffect(() => {
    const loadProfile = async () => {
      if (!currentUser) {
        // If no currentUser, wait for auth to complete or redirect
        if (!loading) {
          navigate('/login');
        }
        return;
      }

      if (!userId && !currentUser._id) {
        setError('No user ID available');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError('');
        await fetchUserData();
        if (isOwnProfile) {
          await Promise.all([
            fetchRecentMessages(),
            fetchReceivedInterests(),
            fetchSentInterests()
          ]);
        } else {
          await checkGalleryAccess();
        }
      } catch (err) {
        console.error('Error loading profile:', err);
        setError(err.response?.data?.message || 'Failed to load profile');
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, [userId, currentUser, isOwnProfile]);

  useEffect(() => {
    if (webSocket?.socket) {
      const handleOnlineUsers = (users) => {
        console.log('Online users updated:', users);
        setOnlineUsers(new Set(users));
      };

      webSocket.socket.on('onlineUsers', handleOnlineUsers);
      webSocket.socket.emit('getOnlineUsers');

      // Listen for interest updates
      webSocket.socket.on('interestUpdate', ({ userId, hasInterest }) => {
        if (user && userId === user._id) {
          setHasShownInterest(hasInterest);
        }
      });

      return () => {
        webSocket.socket.off('onlineUsers', handleOnlineUsers);
        webSocket.socket.off('interestUpdate');
      };
    }
  }, [webSocket?.socket, user]);

  useEffect(() => {
    if (webSocket?.socket?.connected) {
      webSocket.socket.emit('getOnlineUsers');
    }
  }, [webSocket?.socket?.connected]);

  useEffect(() => {
    if (currentUser && user && !isOwnProfile) {
      checkInterestStatus();
    }
  }, [currentUser, user, isOwnProfile]);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const id = userId || currentUser?._id;
      
      if (!id) {
        console.error('No valid user ID available:', { userId, currentUser });
        throw new Error('Invalid user ID. Please try refreshing the page.');
      }

      console.log('Fetching profile for user:', id);
      const response = await axios.get(`${config.API_URL}/api/profile/${id}`, {
        headers: { 'x-auth-token': token }
      });

      if (!response.data) {
        throw new Error('User profile not found');
      }

      setUser(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError(error.message || 'Failed to load profile');
      throw error;
    }
  };

  const fetchRecentMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/messages/recent`, {
        headers: { 'x-auth-token': token }
      });
      setRecentMessages(response.data);
    } catch (error) {
      console.error('Error fetching recent messages:', error);
    }
  };

  const fetchReceivedInterests = async () => {
    try {
      console.log('Fetching received interests');
      const baseUrl = process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com';
      const response = await axios.get(`${baseUrl}/api/profile/interests/received`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      console.log('Received interests:', response.data);
      setReceivedInterests(response.data);
    } catch (error) {
      console.error('Error fetching received interests:', error);
    }
  };

  const fetchSentInterests = async () => {
    try {
      console.log('Fetching sent interests');
      const baseUrl = process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com';
      const response = await axios.get(`${baseUrl}/api/profile/interests/sent`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      console.log('Sent interests:', response.data);
      setSentInterests(response.data);
    } catch (error) {
      console.error('Error fetching sent interests:', error);
    }
  };

  const checkGalleryAccess = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Checking gallery access with token:', token ? 'Present' : 'Missing');
      
      const response = await axios.get(`${config.API_URL}/api/private-photos/${userId}/access`, {
        headers: { 
          'x-auth-token': token,
          'authorization': `Bearer ${token}`
        }
      });
      
      console.log('Gallery access response:', response.data);
      setHasGalleryAccess(response.data.hasAccess);
    } catch (error) {
      console.error('Error checking gallery access:', error);
      setHasGalleryAccess(false);
    }
  };

 const handleDeletePicture = async (picturePath) => {
  if (!window.confirm('Are you sure you want to delete this picture?')) return;

  try {
    const token = localStorage.getItem('token');
    // Get the filename from the path more robustly
    let imageId = picturePath.split('/').pop();
    
    // Remove any query parameters if present
    imageId = imageId.split('?')[0];
    
    console.log('Attempting to delete image with ID:', imageId);
    
    const response = await axios.delete(`${config.API_URL}/api/profile/additional-pictures/${imageId}`, {
      headers: { 'x-auth-token': token }
    });
    
    console.log('Delete response:', response.data);

    // Refresh user data
    fetchUserData();
    toast.success(t('profile.photos.deleteSuccess') || 'Picture deleted successfully');
  } catch (error) {
    console.error('Error deleting picture:', error.response || error);
    setError('Failed to delete picture');
    toast.error(t('profile.photos.deleteFailed') || 'Failed to delete picture');
  }
};

  const handleRemoveInterest = async (userId) => {
    if (!window.confirm('Are you sure you want to remove this interest?')) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${config.API_URL}/api/profile/interest/${userId}`, {
        headers: { 'x-auth-token': token }
      });

      // Refresh interests after removal
      fetchSentInterests();
    } catch (error) {
      console.error('Error removing interest:', error);
      setError('Failed to remove interest');
    }
  };

  const toggleInterest = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = {
        'x-auth-token': token,
        'authorization': `Bearer ${token}`
      };
      
      if (hasShownInterest) {
        await axios.delete(`${config.API_URL}/api/profile/interest/${user._id}`, {
          headers
        });
      } else {
        await axios.post(`${config.API_URL}/api/profile/interest/${user._id}`, {}, {
          headers
        });
      }
      
      setHasShownInterest(!hasShownInterest);
      
      // Refresh the data
      await fetchUserData();
      // Check gallery access after toggling interest
      await checkGalleryAccess();
      
      // Show success message
      toast.success(hasShownInterest ? 
        t('profile.interests.removed') : 
        t('profile.interests.added')
      );
    } catch (error) {
      console.error('Error toggling interest:', error);
      toast.error(t('profile.errors.interestToggleFailed'));
    }
  };

  const checkInterestStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/profile/interest/status/${user._id}`, {
        headers: { 
          'x-auth-token': token,
          'authorization': `Bearer ${token}`
        }
      });
      setHasShownInterest(response.data.hasInterest);
    } catch (error) {
      console.error('Error checking interest status:', error);
    }
  };

  if (loading) return <div className="text-center py-8">Loading...</div>;
  if (error) return <div className="text-red-500 text-center py-8">{error}</div>;
  if (!user) return <div className="text-center py-8">User not found</div>;

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden">
          {/* Profile Header */}
          <div className="p-6 space-y-6">
            {/* Profile Picture and Basic Info */}
            <div className="flex items-start space-x-6">
              {/* Profile Picture */}
              <div className="w-32 h-32 rounded-xl overflow-hidden flex-shrink-0 shadow-lg">
                <img
                  src={user.profile.profilePicture ? 
                    (user.profile.profilePicture.startsWith('http') ? 
                      user.profile.profilePicture : 
                      `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}${user.profile.profilePicture}`
                    ) : DEFAULT_AVATAR}
                  alt={user.profile.name}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.error('Image load error:', e.target.src);
                    e.target.onerror = null;
                    e.target.src = DEFAULT_AVATAR;
                  }}
                />
              </div>

              {/* Basic Info */}
              <div className="flex-1">
                {/* Name and Age */}
                <h1 className="text-3xl font-bold text-white flex items-center">
                  {user.profile.name}, {user.profile.age}
                  {user.membership?.type === 'premium' && <PremiumBadge />}
                </h1>
                
                {/* Location */}
                <p className="text-white/80 mt-2">
                  <span className="font-semibold">📍</span> {t('profile.location')}: {user.profile.location}
                </p>
                
                {/* Gender and Orientation */}
                <div className="flex gap-4 mt-2">
                  <p className="text-white/80">
                    <span className="font-semibold">{t('profile.gender')}:</span> {' '}
                    {user.profile.gender}
                  </p>
                  {user.profile.sexualOrientation && (
                    <p className="text-white/80">
                      <span className="font-semibold">{t('profile.orientation')}:</span> {' '}
                      {user.profile.sexualOrientation}
                    </p>
                  )}
                </div>
                
                {/* Looking For */}
                <p className="text-white/80 mt-2">
                  <span className="font-semibold">{t('profile.lookingFor.label')}:</span> {' '}
                  {user.profile.lookingFor}
                </p>
              </div>
            </div>

            {/* Bio Section - Full Width */}
            <div className="bg-white/5 rounded-lg p-4">
              <h3 className="text-white/90 font-semibold mb-2">{t('profile.about.title')}</h3>
              <p className="text-white/80 whitespace-pre-wrap">
                {user.profile.bio ? user.profile.bio : t('profile.about.noBio')}
              </p>
            </div>
          </div>

          {/* Profile Content */}
          <div className="p-6 border-t border-white/10">
            {/* Recent Messages Section - Only show on own profile */}
            {isOwnProfile && (
              <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-white">{t('profile.messages.recent')}</h2>
                {recentMessages.length > 0 ? (
                  <div className="space-y-4">
                    {recentMessages.slice(0, 3).map((message) => (
                      <div
                        key={message._id}
                        className="bg-white/10 backdrop-blur-sm rounded-lg p-4 cursor-pointer hover:bg-white/20 transition-colors"
                        onClick={() => {
                          const partnerId = message.sender._id === currentUser._id ?
                            message.recipient._id :
                            message.sender._id;
                          navigate(`/messages/${partnerId}`);
                        }}
                      >
                        <div className="flex justify-between items-start">
                          <div className="flex items-center space-x-3">
                            <div className="relative">
                              <img
                                src={message.sender._id === currentUser._id ? 
                                  (message.recipient.profile?.profilePicture ? 
                                    (message.recipient.profile.profilePicture.startsWith('http') ? 
                                      message.recipient.profile.profilePicture : 
                                      `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}${message.recipient.profile.profilePicture}`
                                    ) : DEFAULT_AVATAR
                                  ) :
                                  (message.sender.profile?.profilePicture ? 
                                    (message.sender.profile.profilePicture.startsWith('http') ? 
                                      message.sender.profile.profilePicture : 
                                      `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}${message.sender.profile.profilePicture}`
                                    ) : DEFAULT_AVATAR
                                  )
                                }
                                alt="Profile"
                                className="w-10 h-10 rounded-full object-cover"
                                onError={(e) => {
                                  console.error('Image load error:', e.target.src);
                                  e.target.onerror = null;
                                  e.target.src = DEFAULT_AVATAR;
                                }}
                              />
                              {onlineUsers.has(message.sender._id === currentUser._id ? 
                                message.recipient._id : 
                                message.sender._id) && (
                                <span className="absolute -bottom-1 -right-1 w-3.5 h-3.5 bg-green-500 rounded-full border-2 border-gray-900"></span>
                              )}
                            </div>
                            <div>
                              <p className="text-white font-medium">
                                {message.sender._id === currentUser._id ? 
                                  message.recipient.profile?.name : 
                                  message.sender.profile?.name}
                              </p>
                              <div className="text-sm text-white/70">
                                {isFreeUser ? (
                                  <div className="flex flex-col gap-1">
                                    <p>{formatMessageContent(message)}</p>
                                    <Link 
                                      to="/upgrade"
                                      onClick={(e) => e.stopPropagation()}
                                      className="text-sm text-black font-semibold flex items-center justify-center gap-1.5 bg-pink-100 hover:bg-pink-200 px-4 py-2 rounded-full transition-all w-full"
                                    >
                                      <span>✨</span>
                                      <span>Upgrade to Premium to read messages</span>
                                      <span>→</span>
                                    </Link>
                                  </div>
                                ) : (
                                  <p>{message.content}</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <span className="text-xs text-white/50">
                            {message.timestamp ? format(new Date(message.timestamp), 'MMM d, h:mm a') : 
                             message.createdAt ? format(new Date(message.createdAt), 'MMM d, h:mm a') : 
                             'Recent'}
                          </span>
                        </div>
                      </div>
                    ))}
                    <button
                      onClick={() => navigate('/messages')}
                      className="text-white/80 hover:text-white text-sm font-medium transition-colors"
                    >
                      {t('profile.messages.viewAll')} <span className="ml-1">→</span>
                    </button>
                  </div>
                ) : (
                  <p className="text-white/60">
                    {t('profile.messages.none')}
                  </p>
                )}
              </div>
            )}

            {/* Interests Sections - Only show on own profile */}
            {isOwnProfile && (
              <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4 text-white flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                  </svg>
                  {t('profile.interests.title')}
                </h2>

                <h3 className="text-xl font-semibold mb-3 text-white/90 flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                  </svg>
                  {t('profile.interests.interested')}
                </h3>

                {/* Who's Interested in Me */}
                <div className="mb-6">
                  <h2 className="text-2xl font-semibold mb-4 text-white">Who's Interested in Me</h2>
                  {receivedInterests.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {receivedInterests.map((user) => (
                        <div
                          key={user._id}
                          className="bg-white/10 backdrop-blur-sm rounded-lg p-4 transition-colors relative group"
                        >
                          <div
                            onClick={() => navigate(`/profile/${user._id}`)}
                            className="flex items-center flex-1 cursor-pointer space-x-4"
                          >
                            <div className="relative flex-shrink-0">
                              <img
                                src={user.profile?.profilePicture ? 
                                  (user.profile.profilePicture.startsWith('http') ? 
                                    user.profile.profilePicture : 
                                    `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}${user.profile.profilePicture}`
                                  ) : DEFAULT_AVATAR}
                                alt={user.profile?.name || 'User'}
                                className="w-16 h-16 sm:w-12 sm:h-12 rounded-full object-cover"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = DEFAULT_AVATAR;
                                }}
                              />
                              {onlineUsers.has(user._id) && (
                                <span className="absolute -bottom-1 -right-1 w-3.5 h-3.5 bg-green-500 rounded-full border-2 border-gray-900"></span>
                              )}
                            </div>
                            <div className="flex-1 min-w-0">
                              <h3 className="font-medium text-white truncate">{user.profile?.name || 'User'}</h3>
                              <p className="text-sm text-gray-300 truncate">
                                {[user.profile?.age, user.profile?.location].filter(Boolean).join(' • ')}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-400">No one has shown interest yet</p>
                  )}
                </div>

                {/* People I'm Interested In */}
                <div>
                  <h2 className="text-2xl font-semibold mb-4 text-white">People I'm Interested In</h2>
                  {sentInterests.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {sentInterests.map((user) => (
                        <div
                          key={user._id}
                          className="bg-white/10 backdrop-blur-sm rounded-lg p-4 transition-colors relative group"
                        >
                          <div
                            onClick={() => navigate(`/profile/${user._id}`)}
                            className="flex items-center flex-1 cursor-pointer space-x-4"
                          >
                            <div className="relative flex-shrink-0">
                              <img
                                src={user.profile?.profilePicture ? 
                                  (user.profile.profilePicture.startsWith('http') ? 
                                    user.profile.profilePicture : 
                                    `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}${user.profile.profilePicture}`
                                  ) : DEFAULT_AVATAR}
                                alt={user.profile?.name || 'User'}
                                className="w-16 h-16 sm:w-12 sm:h-12 rounded-full object-cover"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = DEFAULT_AVATAR;
                                }}
                              />
                              {onlineUsers.has(user._id) && (
                                <span className="absolute -bottom-1 -right-1 w-3.5 h-3.5 bg-green-500 rounded-full border-2 border-gray-900"></span>
                              )}
                            </div>
                            <div className="flex-1 min-w-0">
                              <h3 className="font-medium text-white truncate">{user.profile?.name || 'User'}</h3>
                              <p className="text-sm text-gray-300 truncate">
                                {[user.profile?.age, user.profile?.location].filter(Boolean).join(' • ')}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => handleRemoveInterest(user._id)}
                            className="opacity-0 group-hover:opacity-100 absolute right-2 top-2 bg-red-500/80 backdrop-blur-sm text-white p-1 rounded-full hover:bg-red-600/80 transition-all duration-200"
                            title="Remove interest"
                          >
                            ✕
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-400">You haven't shown interest in anyone yet</p>
                  )}
                </div>
              </div>
            )}

            {/* Additional Pictures Grid */}
            {user.profile.additionalPictures?.length > 0 && (
              <div>
                <h2 className="text-2xl font-semibold mb-4 text-white">
                  {t('profile.photos.title')}
                </h2>
                <div className="grid grid-cols-3 gap-4">
                  {user.profile.additionalPictures.map((picture, index) => (
                    <div
                      key={index}
                      className="aspect-square relative group"
                    >
                      <img
                        src={picture ? 
                          (picture.startsWith('http') ? 
                            picture : 
                            `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}${picture}`
                          ) : DEFAULT_AVATAR}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = DEFAULT_AVATAR;
                          console.log('Failed to load image:', picture);
                        }}
                        alt={`${user.profile.name}'s photo ${index + 1}`}
                        className="w-full h-full object-cover rounded-lg cursor-pointer transition-all duration-200 hover:opacity-90"
                        onClick={() => {
                          setSelectedImage(picture);
                          setShowImageModal(true);
                        }}
                      />
                      {isOwnProfile && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeletePicture(picture);
                          }}
                          className="absolute top-2 right-2 bg-red-500/80 backdrop-blur-sm text-white rounded-full w-6 h-6 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                        >
                          ×
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Profile action buttons with guides */}
            <div className="flex flex-col md:flex-row w-full gap-3 mb-8">
              {isOwnProfile && (
                <>
                  <FeatureGuide
                    title={t('profile.features.editProfile.title')}
                    description={t('profile.features.editProfile.description')}
                    icon="✏️"
                    position="bottom"
                    className="flex-1"
                  >
                    <Link
                      to="/profile/edit"
                      className="w-full inline-flex items-center justify-center px-6 py-3.5 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-all duration-200 shadow-sm hover:shadow-md font-medium"
                    >
                      <span className="mr-2 text-xl">✏️</span>
                      {t('profile.editProfileButton')}
                    </Link>
                  </FeatureGuide>

                  <FeatureGuide
                    title={t('profile.features.privateGallery.title')}
                    description={t('profile.features.privateGallery.description')}
                    icon="🔒"
                    position="bottom"
                    className="flex-1"
                  >
                    <Link
                      to="/private-photos"
                      className="w-full inline-flex items-center justify-center px-6 py-3.5 bg-gradient-to-r from-pink-500 to-pink-600 text-white rounded-lg hover:from-pink-600 hover:to-pink-700 transition-all duration-200 shadow-sm hover:shadow-md font-medium"
                    >
                      <span className="mr-2 text-xl">🔒</span>
                      {t('profile.privateGalleryButton')}
                    </Link>
                  </FeatureGuide>
                </>
              )}
            </div>

            {/* Gallery section with proper spacing */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4">{t('Gallery')}</h3>
              {/* Gallery content */}
            </div>

            {/* Action Buttons for non-own profile */}
            {!isOwnProfile && (
              <div className="mt-4 flex gap-4">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => navigate(`/messages/${user._id}`)}
                  className="w-1/2 py-2 px-4 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-all duration-200"
                >
                  {t('profile.actions.message')}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={toggleInterest}
                  className={`w-1/2 py-2 px-4 rounded-lg transition-all duration-200 ${
                    hasShownInterest
                      ? 'bg-green-500 hover:bg-red-500 text-white'
                      : 'bg-purple-500 hover:bg-purple-600 text-white'
                  }`}
                >
                  {hasShownInterest ? (
                    <span className="flex items-center justify-center">
                      {t('profile.actions.interestShown')}
                      <motion.span
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="ml-2"
                      >
                        ✓
                      </motion.span>
                    </span>
                  ) : t('profile.actions.showInterest')}
                </motion.button>
              </div>
            )}

            {/* Private Gallery Access Button */}
            {!isOwnProfile && hasGalleryAccess && (
              <div className="mt-4">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => navigate(`/user/${user._id}/private-gallery`)}
                  className="w-full py-2 px-4 rounded-lg bg-pink-500 hover:bg-pink-600 text-white transition-all duration-200 flex items-center justify-center gap-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                  </svg>
                  {t('profile.actions.viewPrivateGallery')}
                </motion.button>
              </div>
            )}
            
            {/* Show ProfileCompletionPrompt only on own profile and if photos are less than 3 */}
            {isOwnProfile && user?.profile && (user.profile.additionalPictures?.length + (user.profile.profilePicture ? 1 : 0) < 3) && (
              <div className="mb-8">
                <ProfileCompletionPrompt 
                  photoCount={user.profile.additionalPictures?.length + (user.profile.profilePicture ? 1 : 0)}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Image Modal */}
      {showImageModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
          onClick={() => setShowImageModal(false)}
        >
          <div className="relative max-w-6xl max-h-[90vh] w-full">
            {/* Close button */}
            <button
              className="absolute top-4 right-4 text-white text-xl bg-blue-400 hover:bg-blue-500 w-10 h-10 rounded-full flex items-center justify-center"
              onClick={() => setShowImageModal(false)}
            >
              ×
            </button>

            {/* Navigation arrows - Only show if there are multiple pictures */}
            {user.profile.additionalPictures?.length > 1 && (
              <>
                {/* Previous button */}
                <button
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white/10 hover:bg-white/20 text-white rounded-full w-12 h-12 flex items-center justify-center transition-all duration-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    const currentIndex = user.profile.additionalPictures.indexOf(selectedImage);
                    const prevIndex = currentIndex > 0 ? currentIndex - 1 : user.profile.additionalPictures.length - 1;
                    setSelectedImage(user.profile.additionalPictures[prevIndex]);
                  }}
                >
                  ←
                </button>

                {/* Next button */}
                <button
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white/10 hover:bg-white/20 text-white rounded-full w-12 h-12 flex items-center justify-center transition-all duration-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    const currentIndex = user.profile.additionalPictures.indexOf(selectedImage);
                    const nextIndex = currentIndex < user.profile.additionalPictures.length - 1 ? currentIndex + 1 : 0;
                    setSelectedImage(user.profile.additionalPictures[nextIndex]);
                  }}
                >
                  →
                </button>
              </>
            )}

            {/* Image */}
            <img
              src={selectedImage ? 
                (selectedImage.startsWith('http') ? 
                  selectedImage : 
                  `${process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com'}${selectedImage}`
                ) : DEFAULT_AVATAR}
              alt="Full size"
              className="max-w-full max-h-[85vh] object-contain mx-auto"
              onClick={(e) => e.stopPropagation()}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = DEFAULT_AVATAR;
                console.error('Failed to load full-size image:', selectedImage);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;