import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../config';
import { useAuth } from '../../contexts/AuthContext';
import { motion } from 'framer-motion';

function UserPrivateGallery() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userName, setUserName] = useState('');
  const API_URL = config.API_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!userId) {
      navigate('/browse');
      return;
    }
    fetchPhotos();
    fetchUserName();
  }, [userId]);

  const getImageUrl = (photoUrl) => {
    return `${API_URL}${photoUrl}?token=${token}`;
  };

  const fetchUserName = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/profile/${userId}`, {
        headers: { 'x-auth-token': token }
      });
      setUserName(response.data.profile.name);
    } catch (error) {
      console.error('Error fetching user name:', error);
    }
  };

  const fetchPhotos = async () => {
    try {
      setLoading(true);
      setError(null);
      
      if (!token) {
        throw new Error('No authentication token found');
      }

      // First check if we have access
      const accessResponse = await axios.get(`${API_URL}/api/private-photos/${userId}/access`, {
        headers: { 
          'x-auth-token': token,
          'authorization': `Bearer ${token}`
        }
      });

      if (!accessResponse.data.hasAccess) {
        throw new Error('You do not have access to this private gallery');
      }

      // Then fetch the photos
      const response = await axios.get(`${API_URL}/api/private-photos/${userId}/shared`, {
        headers: { 
          'x-auth-token': token,
          'authorization': `Bearer ${token}`
        }
      });

      setPhotos(response.data || []);
    } catch (error) {
      console.error('Error fetching photos:', error);
      const errorMessage = error.response?.data?.message || error.message;
      setError(errorMessage);
      toast.error(errorMessage);
      
      // If we get a 403 or 401, or if we don't have access, redirect back
      if (error.response?.status === 403 || error.response?.status === 401 || error.message.includes('access')) {
        navigate(-1);
      }
    } finally {
      setLoading(false);
    }
  };

  // Warning Message Component
  const WarningMessage = () => (
    <div className="bg-yellow-500/20 backdrop-blur-sm border border-yellow-500/50 rounded-lg p-4 mb-6">
      <h3 className="text-yellow-300 font-semibold mb-2">⚠️ Important Warning</h3>
      <p className="text-white/90">
        Please be mindful when viewing private photos:
      </p>
      <ul className="list-disc list-inside text-white/80 mt-2 space-y-1">
        <li>Respect the privacy of the person sharing these photos with you</li>
        <li>Do not save, screenshot, or share these photos with others</li>
        <li>Any misuse of private photos may result in account termination</li>
        <li>Report any concerns about content immediately</li>
      </ul>
    </div>
  );

  // Image Modal Component
  const ImageModal = () => {
    if (!selectedImage) return null;

    return (
      <div 
        className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4"
        onClick={() => setSelectedImage(null)}
      >
        <div className="relative max-w-4xl max-h-[90vh] w-full">
          <button
            className="absolute top-4 right-4 text-white text-xl bg-white/10 hover:bg-white/20 w-10 h-10 rounded-full flex items-center justify-center"
            onClick={() => setSelectedImage(null)}
          >
            ×
          </button>
          <img
            src={selectedImage ? getImageUrl(selectedImage) : ''}
            alt="Full size"
            className="max-w-full max-h-[85vh] object-contain mx-auto"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold text-white">
              {userName}'s Private Gallery
            </h2>
            <button
              onClick={() => navigate(-1)}
              className="px-4 py-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
            >
              Back to Profile
            </button>
          </div>

          <WarningMessage />
          
          {error ? (
            <div className="text-red-300 text-center py-8 bg-red-500/10 rounded-lg">
              {error}
            </div>
          ) : photos.length === 0 ? (
            <div className="text-white/80 text-center py-8">
              No private photos have been shared yet.
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {photos.map((photo) => (
                <motion.div
                  key={photo._id}
                  whileHover={{ scale: 1.02 }}
                  className="aspect-square relative group cursor-pointer"
                  onClick={() => setSelectedImage(photo.url)}
                >
                  <img
                    src={getImageUrl(photo.url)}
                    alt="Private gallery photo"
                    className="w-full h-full object-cover rounded-lg transition-transform duration-200"
                    loading="lazy"
                  />
                  <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-lg" />
                </motion.div>
              ))}
            </div>
          )}
        </div>
      </div>

      <ImageModal />
    </div>
  );
}

export default UserPrivateGallery;
