import { useState } from 'react';
import axios from 'axios';

function EmailDashboard() {
  const [emailType, setEmailType] = useState('single'); // single, bulk, template
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [recipients, setRecipients] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [dynamicData, setDynamicData] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    try {
      const token = localStorage.getItem('token');
      let endpoint = '';
      let data = {};

      switch (emailType) {
        case 'single':
          endpoint = '/api/admin/email/send';
          data = { to, subject, html: content };
          break;
        case 'bulk':
          endpoint = '/api/admin/email/send-bulk';
          data = {
            recipients: recipients.split(',').map(email => email.trim()),
            subject,
            html: content
          };
          break;
        case 'template':
          endpoint = '/api/admin/email/send-template';
          let parsedData = {};
          try {
            if (dynamicData) {
              parsedData = JSON.parse(dynamicData);
              if (!parsedData || typeof parsedData !== 'object') {
                throw new Error('Dynamic data must be a valid JSON object');
              }
            }
          } catch (parseError) {
            throw new Error('Invalid dynamic data format. Please provide valid JSON.');
          }
          data = {
            to,
            templateId,
            dynamicData: parsedData
          };
          break;
        default:
          throw new Error('Invalid email type');
      }

      const response = await axios.post(endpoint, data, {
        headers: { 'x-auth-token': token }
      });

      setMessage('Email sent successfully!');
      // Clear form
      setTo('');
      setSubject('');
      setContent('');
      setRecipients('');
      setTemplateId('');
      setDynamicData('');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to send email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Email Management</h2>
      
      <div className="flex gap-4 mb-6">
        <button 
          className={`px-4 py-2 rounded-md transition-all duration-300 ${
            emailType === 'single' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          onClick={() => setEmailType('single')}
        >
          Single Email
        </button>
        <button 
          className={`px-4 py-2 rounded-md transition-all duration-300 ${
            emailType === 'bulk' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          onClick={() => setEmailType('bulk')}
        >
          Bulk Email
        </button>
        <button 
          className={`px-4 py-2 rounded-md transition-all duration-300 ${
            emailType === 'template' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          onClick={() => setEmailType('template')}
        >
          Template Email
        </button>
      </div>

      {message && (
        <div className="bg-green-100 text-green-800 p-4 rounded-md mb-4">
          {message}
        </div>
      )}
      
      {error && (
        <div className="bg-red-100 text-red-800 p-4 rounded-md mb-4">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        {emailType === 'single' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                To:
              </label>
              <input
                type="email"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Subject:
              </label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Content:
              </label>
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[200px]"
                required
              />
            </div>
          </>
        )}

        {emailType === 'bulk' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Recipients (comma-separated):
              </label>
              <textarea
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                placeholder="email1@example.com, email2@example.com"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Subject:
              </label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Content:
              </label>
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[200px]"
                required
              />
            </div>
          </>
        )}

        {emailType === 'template' && (
          <>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                To:
              </label>
              <input
                type="email"
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Template ID:
              </label>
              <input
                type="text"
                value={templateId}
                onChange={(e) => setTemplateId(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Dynamic Data (JSON):
              </label>
              <textarea
                value={dynamicData}
                onChange={(e) => setDynamicData(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px]"
                placeholder='{"name": "John", "date": "2025-01-22"}'
                required
              />
            </div>
          </>
        )}

        <button 
          type="submit" 
          disabled={loading}
          className={`w-full py-2 px-4 rounded-md text-white font-medium transition-colors duration-300 ${
            loading 
              ? 'bg-green-400 cursor-not-allowed' 
              : 'bg-green-600 hover:bg-green-700'
          }`}
        >
          {loading ? 'Sending...' : 'Send Email'}
        </button>
      </form>
    </div>
  );
}

export default EmailDashboard;