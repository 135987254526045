import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../../config';

function Register() {
  const navigate = useNavigate();
  const { login, currentUser } = useAuth();
  const { t, i18n } = useTranslation();
  
  // Redirect if already logged in
  useEffect(() => {
    if (currentUser) {
      navigate('/browse');
    }
  }, [currentUser, navigate]);

  console.log('Translation loaded:', t('auth.register.title'));
  console.log('Current language:', i18n.language);
  console.log('Stored language:', localStorage.getItem('language'));

  // Debug logs
  console.log('Register component mounted');
  console.log('Current translations:', {
    en: t('auth.register.title', { lng: 'en' }),
    th: t('auth.register.title', { lng: 'th' })
  });

  // Consolidated language switching function
  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'th' : 'en';
    console.log('Before change:', i18n.language);
    i18n.changeLanguage(newLang);
    console.log('After change:', newLang);
    localStorage.setItem('language', newLang);
    console.log('Stored new language:', localStorage.getItem('language'));
    console.log('New translation:', t('auth.register.title'));
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    age: '',
    gender: '',
    lookingFor: '',
    location: '',
    bio: '',
  });
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      // Create preview URL
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const formDataToSend = new FormData();
      
      // Add email and password
      formDataToSend.append('email', formData.email);
      formDataToSend.append('password', formData.password);
      
      // Create profile object with proper validation
      const profile = {
        name: formData.name.trim(),
        age: parseInt(formData.age),
        gender: formData.gender,
        lookingFor: formData.lookingFor,
        location: formData.location.trim(),
        bio: formData.bio?.trim() || ''
      };

      // Validate required fields
      if (!profile.name || !profile.gender || !profile.lookingFor || !profile.location) {
        setError('All fields are required');
        setLoading(false);
        return;
      }

      // Validate age
      if (isNaN(profile.age) || profile.age < 18) {
        setError('Age must be a valid number and at least 18');
        setLoading(false);
        return;
      }
      
      // Add profile as JSON string
      formDataToSend.append('profile', JSON.stringify(profile));

      if (profilePicture) {
        formDataToSend.append('profilePicture', profilePicture);
      }

      console.log('Sending registration data:', {
        email: formData.email,
        profile: profile,
        formDataEntries: Array.from(formDataToSend.entries()).map(([key, value]) => 
          key === 'password' ? [key, '[REDACTED]'] : [key, value]
        )
      });

      const response = await axios.post(`${config.API_URL}/api/auth/register`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Registration response:', {
        token: response.data.token ? '[PRESENT]' : '[MISSING]',
        user: response.data.user,
        message: response.data.message
      });

      if (response.data) {
        // Pass both token and user data to login
        login(response.data.token, response.data.user);
        toast.success('Welcome to ThaiMeetup! 🌟');
        navigate('/browse');
      }
    } catch (err) {
      console.error('Registration error:', {
        message: err.message,
        response: err.response?.data
      });
      setError(t('auth.register.errors.failed'));
    }

    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Cleanup preview URL on unmount
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  // Debug logs for i18n only
  console.log('Available languages:', i18n.languages);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 py-4 px-4">
      <div className="max-w-md mx-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-6">
          <div className="flex justify-end mb-4">
            <button
              onClick={toggleLanguage}
              className="text-white hover:text-blue-200 transition-colors"
            >
              {i18n.language === 'en' ? 'ไทย' : 'English'}
            </button>
          </div>

          <h2 className="text-2xl font-bold mb-6 text-white text-center">
            {t('auth.register.title')}
          </h2>
          
          {t('auth.register.ageWarning') && (
            <div className="mb-6 text-white text-sm bg-purple-800/50 p-4 rounded">
              {t('auth.register.ageWarning')}
            </div>
          )}

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Profile Picture Section */}
            <div className="mb-6">
              <label className="block text-white mb-2">{t('auth.register.profilePicture')}</label>
              <div className="flex items-center space-x-4">
                <div 
                  onClick={() => document.getElementById('profilePicInput').click()}
                  className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg overflow-hidden cursor-pointer hover:border-purple-500 hover:bg-gray-50 transition-all duration-200 flex items-center justify-center group relative"
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      document.getElementById('profilePicInput').click();
                    }
                  }}
                >
                  {previewUrl ? (
                    <img
                      src={previewUrl}
                      alt="Profile preview"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="text-center">
                      <i className="fas fa-camera text-2xl text-gray-400 group-hover:text-purple-500"></i>
                      <p className="text-sm text-white mt-2">Upload Photo</p>
                    </div>
                  )}
                </div>
                <input
                  id="profilePicInput"
                  type="file"
                  accept="image/*"
                  onChange={handleProfilePictureChange}
                  className="hidden"
                  aria-label="Upload profile picture"
                />
                <div className="flex-1">
                  <p className="text-sm text-white">
                    {t('auth.register.photoInstructions')}
                    <br />
                    {t('auth.register.photoRequirements')}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.email')}</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                required
              />
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.password')}</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                required
                autoComplete="new-password"
              />
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.name')}</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                required
              />
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.age')}</label>
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                min="18"
                max="100"
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                required
              />
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.gender')}</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                required
              >
                <option value="">{t('auth.register.selectGender')}</option>
                <option value="Male">{t('auth.register.genderOptions.male')}</option>
                <option value="Female">{t('auth.register.genderOptions.female')}</option>
                <option value="Ladyboy">{t('auth.register.genderOptions.ladyboy')}</option>
              </select>
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.lookingFor')}</label>
              <select
                name="lookingFor"
                value={formData.lookingFor}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                required
              >
                <option value="">{t('auth.register.selectPreference')}</option>
                <option value="Male">{t('auth.register.genderOptions.male')}</option>
                <option value="Female">{t('auth.register.genderOptions.female')}</option>
                <option value="Ladyboy">{t('auth.register.genderOptions.ladyboy')}</option>
              </select>
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.location')}</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                required
              />
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.register.bio')}</label>
              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-blue-100/90 text-gray-900 placeholder-gray-500 appearance-none"
                rows="3"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-purple-500 text-white p-2 rounded hover:bg-purple-600 disabled:opacity-50"
            >
              {loading ? t('auth.register.registering') : t('auth.register.submit')}
            </button>
          </form>

          <div className="mt-4 text-center">
            <p className="text-white">
              {t('auth.register.haveAccount')}{' '}
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="text-purple-600 hover:text-purple-700"
              >
                {t('auth.register.signIn')}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register; 
