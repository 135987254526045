import React from 'react';
import { FaCrown } from 'react-icons/fa';

const PremiumBadge = ({ className = '' }) => {
  return (
    <span 
      className={`inline-flex items-center ml-1 text-yellow-500 ${className}`}
      title="Premium Member"
    >
      <FaCrown className="w-8 h-8" />
    </span>
  );
};

export default PremiumBadge;