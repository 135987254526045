import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BsImages, BsShieldCheck, BsChatHeartFill } from 'react-icons/bs';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function LandingPage() {
  const navigate = useNavigate();

  // Add state for background images
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Array of background images
  const backgroundImages = [
    '/images/frontbanner1.png',
    '/images/frontbanner2.png',
    '/images/frontbanner3.png',
    '/images/frontbanner4.png'
  ];

  // Add useEffect for image rotation
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === backgroundImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleExternalLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
    }
  };

  return (
    <>
      <Helmet>
        <title>ThaiMeetup - Thai Dating & Connections | หาคู่ในประเทศไทย</title>
        <meta name="description" content="Find your perfect Thai match. Authentic Thai dating site for meaningful connections. Join now! | เว็บไซต์หาคู่ที่ดีที่สุดในประเทศไทย สมัครฟรีวันนี้!" />
        <meta name="keywords" content="thai dating, meet thai singles, dating thailand, thai matchmaking, หาคู่, หาแฟน, หาคู่ออนไลน์, เว็บหาคู่" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="ThaiMeetup - Thai Dating & Connections" />
        <meta property="og:description" content="Find your perfect Thai match. Authentic Thai dating site for meaningful connections." />
        <meta property="og:image" content="https://thaimeetup.com/og-image.jpg" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ThaiMeetup - Thai Dating" />
        <meta name="twitter:description" content="Find your perfect Thai match. Join now!" />
        
        {/* Schema.org markup for Google */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "ThaiMeetup",
              "description": "Thai Dating & Connections Platform",
              "url": "https://thaimeetup.com"
            }
          `}
        </script>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap" rel="stylesheet" />
      </Helmet>

      <div className="min-h-screen relative overflow-hidden">
        {/* Background Images */}
        {backgroundImages.map((image, index) => (
          <motion.div
            key={image}
            className="absolute inset-0 bg-cover bg-center bg-no-repeat"
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentImageIndex ? 1 : 0 }}
            transition={{ duration: 1 }}
            style={{
              backgroundImage: `url('${image}')`,
              filter: "brightness(0.8)"
            }}
          />
        ))}
        
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-br from-purple-600/70 to-blue-500/70"></div>
        
        <div className="container mx-auto px-4 pb-16 relative">
          {/* Hero Section */}
          <div className="text-center text-white mb-16">
            <motion.h1 
              className="text-6xl font-bold mb-6"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Find Your Perfect Match
              <span className="block text-3xl mt-4">
                ค้นหาคู่ที่ใช่สำหรับคุณ
              </span>
            </motion.h1>
            <motion.div
              className="text-4xl text-blue-200/90 mb-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              style={{ 
                fontFamily: "'Great Vibes', cursive",
                textShadow: "2px 2px 4px rgba(0,0,0,0.3)" 
              }}
            >
              ThaiMeetUp.com
            </motion.div>
            <motion.p 
              className="text-2xl mb-8 text-blue-200"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              Meet genuine Thai singles looking for real connections
              <span className="block text-xl mt-2">
                พบกับคนไทยที่กำลังมองหาความสัมพันธ์ที่แท้จริง
              </span>
            </motion.p>

            {/* Call to Action Buttons */}
            <motion.div 
              className="flex flex-col md:flex-row items-center justify-center gap-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
            >
              <button
                onClick={() => navigate('/register')}
                className="w-full md:w-64 bg-gradient-to-r from-pink-500 to-purple-600 text-white px-8 py-4 rounded-lg font-semibold hover:from-pink-600 hover:to-purple-700 transition-all transform hover:scale-105 shadow-lg"
              >
                Join Free Now
                <span className="block text-sm mt-1">สมัครฟรีวันนี้</span>
              </button>
              <button
                onClick={() => navigate('/login')}
                className="w-full md:w-64 border-2 border-white/50 text-white px-8 py-4 rounded-lg font-semibold hover:bg-white/10 transition-all shadow-lg"
              >
                Sign In
                <span className="block text-sm mt-1">เข้าสู่ระบบ</span>
              </button>
              <a
                href="https://blog.thaimeetup.com"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full md:w-64 bg-purple-600 text-white px-8 py-4 rounded-lg font-semibold hover:bg-purple-700 transition-all shadow-lg text-center"
              >
                Dating Blog
                <span className="block text-sm mt-1">บทความเดทติ้ง</span>
              </a>
            </motion.div>
          </div>

          {/* Features Section */}
          <div className="grid md:grid-cols-3 gap-8 text-white">
            <motion.div 
              className="bg-black/20 p-6 rounded-xl backdrop-blur-sm"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <div className="flex items-center mb-4">
                <BsImages className="text-3xl text-blue-300 mr-3" />
                <h3 className="text-xl font-semibold text-blue-200">
                  Private Galleries
                  <span className="block text-sm">แกลเลอรี่ส่วนตัว</span>
                </h3>
              </div>
              <p>
                Share your intimate moments with selected matches in our secure private galleries.
                <span className="block text-sm mt-2">
                  แชร์ช่วงเวลาพิเศษกับคู่แมทช์ที่คุณเลือกในแกลเลอรี่ส่วนตัวที่ปลอดภัย
                </span>
              </p>
            </motion.div>

            <motion.div 
              className="bg-black/20 p-6 rounded-xl backdrop-blur-sm"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <div className="flex items-center mb-4">
                <BsShieldCheck className="text-3xl text-blue-300 mr-3" />
                <h3 className="text-xl font-semibold text-blue-200">
                  Verified Members
                  <span className="block text-sm">สมาชิกที่ยืนยันแล้ว</span>
                </h3>
              </div>
              <p>
                Connect with real, verified adults who share your desires and interests.
                <span className="block text-sm mt-2">
                  พบกับสมาชิกจริงที่ผ่านการยืนยันที่มีความสนใจเดียวกับคุณ
                </span>
              </p>
            </motion.div>

            <motion.div 
              className="bg-black/20 p-6 rounded-xl backdrop-blur-sm"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <div className="flex items-center mb-4">
                <BsChatHeartFill className="text-3xl text-blue-300 mr-3" />
                <h3 className="text-xl font-semibold text-blue-200">
                  Discreet Messaging
                  <span className="block text-sm">ข้อความส่วนตัว</span>
                </h3>
              </div>
              <p>
                Enjoy private, encrypted conversations with your matches in complete confidence.
                <span className="block text-sm mt-2">
                  สนทนาอย่างเป็นส่วนตัวและปลอดภัยกับคู่แมทช์ของคุณ
                </span>
              </p>
            </motion.div>
          </div>

          {/* Stats Section */}
          <motion.div 
            className="mt-16 grid grid-cols-1 sm:grid-cols-3 gap-8 text-white text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
          >
            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-6">
              <div className="text-4xl font-bold">50,000+</div>
              <div className="text-blue-200">Active Members</div>
              <div className="text-sm">สมาชิกที่ใช้งานอยู่</div>
            </div>
            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-6">
              <div className="text-4xl font-bold">1,000+</div>
              <div className="text-blue-200">Daily Matches</div>
              <div className="text-sm">การจับคู่ต่อวัน</div>
            </div>
            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-6">
              <div className="text-4xl font-bold">5,000+</div>
              <div className="text-blue-200">Success Stories</div>
              <div className="text-sm">คู่รักที่สำเร็จ</div>
            </div>
          </motion.div>

          {/* Age Verification Notice */}
          <motion.div 
            className="mt-12 text-center text-blue-200 text-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
          >
            Must be 18+ to join. By continuing, you agree to our{' '}
            <Link 
              to="/privacy"
              className="underline hover:text-white transition-colors"
            >
              privacy policy
            </Link>
            {' '}and{' '}
            <Link 
              to="/terms"
              className="underline hover:text-white transition-colors"
            >
              terms of service
            </Link>
            .
            <span className="block mt-2">
              สำหรับผู้ที่มีอายุ 18 ปีขึ้นไป การสมัครถือว่าคุณยอมรับ{' '}
              <Link 
                to="/privacy"
                className="underline hover:text-white transition-colors"
              >
                นโยบายความเป็นส่วนตัว
              </Link>
              {' '}และ{' '}
              <Link 
                to="/terms"
                className="underline hover:text-white transition-colors"
              >
                ข้อกำหนด
              </Link>
              {' '}ของเรา
            </span>
          </motion.div>
        </div>
      </div>

      <footer className="mt-20 pb-8 text-center text-white/70">
        <div className="flex justify-center space-x-4">
          <Link to="/privacy" className="hover:text-white transition-colors">Privacy Policy</Link>
          <a href="/csae-standards.html" className="hover:text-white transition-colors">Child Safety</a>
          <Link to="/terms" className="hover:text-white transition-colors">Terms</Link>
          <Link to="/contact" className="hover:text-white transition-colors">Contact</Link>
        </div>
        <div className="text-sm">
          {new Date().getFullYear()} ThaiMeetUp.com - All rights reserved
        </div>
      </footer>
    </>
  );
}

export default LandingPage;