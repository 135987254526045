import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    category: 'general'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post('/api/contact', formData);
      toast.success(t('contact.success'));
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        category: 'general'
      });
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error(t('contact.error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-end mb-4">
          <LanguageSwitcher />
        </div>
        <div className="max-w-6xl mx-auto p-6">
          <h1 className="text-3xl font-bold text-gray-400 mb-6">{t('contact.title')}</h1>
          
          {/* Abuse Reporting Section */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-8 md:grid md:grid-cols-2 md:gap-8">
            <h2 className="text-2xl font-semibold text-purple-600 mb-4 md:col-span-2">{t('contact.abuse_reporting')}</h2>
            <div className="space-y-4 text-gray-700 md:col-span-2">
              <p className="mb-3">
                {t('contact.abuse_reporting_description')}
              </p>
              
              <div className="pl-4">
                <h3 className="font-semibold text-gray-800 mb-2">{t('contact.immediate_actions')}</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>{t('contact.report_user')}</li>
                  <li>{t('contact.block_user')}</li>
                  <li>{t('contact.save_screenshots')}</li>
                </ul>
              </div>

              <div className="pl-4 mt-4">
                <h3 className="font-semibold text-gray-800 mb-2">{t('contact.contact_support')}</h3>
                <ul className="list-disc pl-5 space-y-2">
                  <li>{t('contact.select_report_abuse')}</li>
                  <li>{t('contact.provide_details')}</li>
                  <li>{t('contact.include_username')}</li>
                  <li>{t('contact.mention_incident_time')}</li>
                </ul>
              </div>

              <div className="bg-purple-50 p-4 rounded-lg mt-4">
                <h3 className="font-semibold text-purple-800 mb-2">{t('contact.important_notes')}</h3>
                <ul className="list-disc pl-5 space-y-2 text-purple-900">
                  <li>{t('contact.confidential_reports')}</li>
                  <li>{t('contact.response_time')}</li>
                  <li>{t('contact.contact_authorities')}</li>
                  <li>{t('contact.false_reports')}</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-gray-800 rounded-lg shadow-lg p-6">
            <h2 className="text-3xl font-extrabold text-center text-purple-400 mb-8">
              {t('contact.title')}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                  {t('contact.name')}
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-700 text-white focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                  {t('contact.email')}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-700 text-white focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                />
              </div>

              <div>
                <label htmlFor="category" className="block text-sm font-medium text-gray-300">
                  {t('contact.category')}
                </label>
                <select
                  id="category"
                  name="category"
                  required
                  value={formData.category}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-700 text-white focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                >
                  <option value="general">{t('contact.categories.general')}</option>
                  <option value="membership">{t('contact.categories.membership')}</option>
                  <option value="technical">{t('contact.categories.technical')}</option>
                  <option value="report">{t('contact.categories.report')}</option>
                  <option value="feedback">{t('contact.categories.feedback')}</option>
                </select>
              </div>

              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-300">
                  {t('contact.subject')}
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  required
                  value={formData.subject}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-700 text-white focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300">
                  {t('contact.message')}
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-700 text-white focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                ></textarea>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                >
                  {isSubmitting ? t('contact.sending') : t('contact.send')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;