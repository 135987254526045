import {
    Edit,
    SimpleForm,
    NumberInput,
    BooleanInput,
    SaveButton,
    Toolbar
} from 'react-admin';

const SettingsToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
);

export const SettingsEdit = () => (
    <Edit 
        resource="settings"
        id="1"
        title="Site Settings"
    >
        <SimpleForm toolbar={<SettingsToolbar />}>
            <NumberInput 
                source="maxProfilePictures" 
                label="Maximum Profile Pictures"
                min={1}
                max={20}
            />
            <NumberInput 
                source="minAge" 
                label="Minimum Age Requirement"
                min={18}
            />
            <NumberInput 
                source="maxBioLength" 
                label="Maximum Bio Length"
                min={100}
                max={1000}
            />
            <BooleanInput 
                source="autoModeration" 
                label="Enable Auto-Moderation"
            />
            <NumberInput 
                source="reportThreshold" 
                label="Report Threshold for Auto-Action"
                min={1}
                max={10}
            />
        </SimpleForm>
    </Edit>
);

export default SettingsEdit; 
