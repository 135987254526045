import './i18n';
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import axios from 'axios'

// Simple request interceptor for logging
axios.interceptors.request.use((config) => {
  console.log('Axios Request:', {
    method: config.method,
    url: config.url
  });
  return config;
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
