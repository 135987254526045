import { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';
import { useTranslation } from 'react-i18next';

// Import or create audio elements
const swipeLeftSound = new Audio('/sounds/swipe-left.mp3');
const swipeRightSound = new Audio('/sounds/swipe-right.mp3');
const matchSound = new Audio('/sounds/match.mp3');

function Swipe() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [direction, setDirection] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [matchedUser, setMatchedUser] = useState(null);
  const [selectedGender, setSelectedGender] = useState('');

  useEffect(() => {
    fetchUsers();
  }, [i18n.language, selectedGender]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/profile' + (selectedGender ? `?gender=${selectedGender}` : ''), {
        headers: { 'x-auth-token': token }
      });

      // Filter out current user and admins
      const filteredUsers = response.data.filter(user => 
        user._id !== currentUser._id && !user.isAdmin
      );

      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to load users');
      setLoading(false);
    }
  };

  const handleSwipe = async (direction, userId) => {
    setDirection(direction);
    if (direction === 'right') {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`/api/profile/interest/${userId}`, {}, {
          headers: { 'x-auth-token': token }
        });
        
        // Check if this created a match
        const matchResponse = await axios.get(`/api/profile/${userId}/interests`, {
          headers: { 'x-auth-token': token }
        });
        
        // If the other user has also shown interest in us
        if (matchResponse.data.includes(currentUser._id)) {
          // Play match sound instead of swipe sound
          matchSound.play();
          // Get the matched user's details
          const userResponse = await axios.get(`/api/profile/${userId}`, {
            headers: { 'x-auth-token': token }
          });
          setMatchedUser(userResponse.data);
          // Trigger confetti animation
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
        } else {
          swipeRightSound.play();
        }
      } catch (error) {
        console.error('Error showing interest:', error);
      }
    } else {
      swipeLeftSound.play();
    }
    
    setTimeout(() => {
      setCurrentIndex(prev => prev + 1);
      setDirection(null);
    }, 300);
  };

  if (loading) return <div className="text-center py-4">{t('browse.loading')}</div>;
  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 flex flex-col items-center p-4">
      {/* Gender Filter */}
      <div className="w-full max-w-md mb-4">
        <select
          value={selectedGender}
          onChange={(e) => setSelectedGender(e.target.value)}
          className="w-full px-4 py-2 rounded-lg bg-gray-800/80 backdrop-blur-sm text-white border border-white/20 focus:border-white focus:ring focus:ring-white/20 focus:ring-opacity-50"
          style={{
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 0.5rem center',
            backgroundSize: '1.5em 1.5em',
            paddingRight: '2.5rem'
          }}
        >
          <option value="" className="bg-gray-800 text-white">{t('swipe.showAll')}</option>
          <option value="Male" className="bg-gray-800 text-white">{t('swipe.showMen')}</option>
          <option value="Female" className="bg-gray-800 text-white">{t('swipe.showWomen')}</option>
          <option value="Ladyboy" className="bg-gray-800 text-white">{t('swipe.showLadyboys')}</option>
        </select>
      </div>

      {/* Main Content Container */}
      <div className="w-full max-w-md flex flex-col items-center">
        {/* Instructions Container - Fixed width */}
        <motion.div 
          className="w-full text-center mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h2 
            className="text-3xl font-bold text-white mb-4"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
          >
            {t('swipe.findMatch')}
          </motion.h2>
          
          <div className="space-y-2">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="bg-white/10 backdrop-blur-sm rounded-lg p-2"
            >
              <p className="text-white text-lg">{t('swipe.swipeRight')}</p>
            </motion.div>

            <motion.div
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
              className="bg-white/10 backdrop-blur-sm rounded-lg p-2"
            >
              <p className="text-white text-lg">{t('swipe.swipeLeft')}</p>
            </motion.div>
          </div>
        </motion.div>

        {/* Cards Container - Independent width */}
        <div className="w-full relative h-[calc(100vh-16rem)] max-h-[600px]">
          {/* Show next card (stacked underneath) */}
          {users[currentIndex + 1] && (
            <div 
              className="absolute inset-0 rounded-lg shadow-xl overflow-hidden transform scale-[0.95] -translate-y-1 opacity-70"
            >
              <div className="bg-white h-full">
                <div className="relative h-full">
                  <img
                    src={`https://thaimeetup.com${users[currentIndex + 1].profile.profilePicture}`}
                    alt="Next profile"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-6">
                    <h2 className="text-2xl font-bold text-white">
                      {users[currentIndex + 1].profile.name}, {users[currentIndex + 1].profile.age}
                    </h2>
                    <p className="text-white/90">{users[currentIndex + 1].profile.location}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Current card */}
          <AnimatePresence initial={false} mode='wait'>
            <motion.div 
              key={users[currentIndex]._id}
              className="absolute inset-0 bg-white rounded-lg shadow-xl overflow-hidden"
              initial={{ scale: 0.95, opacity: 0, y: 20 }}
              animate={{ scale: 1, opacity: 1, y: 0 }}
              exit={{ 
                x: direction === 'right' ? 1000 : -1000,
                opacity: 0,
                rotate: direction === 'right' ? 20 : -20
              }}
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragStart={() => setIsDragging(true)}
              onDragEnd={(e, { offset, velocity }) => {
                setIsDragging(false);
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  handleSwipe('left', users[currentIndex]._id);
                } else if (swipe > swipeConfidenceThreshold) {
                  handleSwipe('right', users[currentIndex]._id);
                }
              }}
            >
              <div className="relative h-full">
                <img
                  src={`https://thaimeetup.com${users[currentIndex].profile.profilePicture}`}
                  alt={users[currentIndex].profile.name}
                  className="w-full h-full object-cover"
                />
                
                {/* Overlay buttons - moved higher up */}
                <div className="absolute bottom-32 left-0 right-0 flex items-center justify-center space-x-4 px-6">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleSwipe('left', users[currentIndex]._id)}
                    className="bg-red-500/90 backdrop-blur-sm text-white w-16 h-16 rounded-full flex items-center justify-center text-2xl shadow-lg hover:bg-red-600/90 transition-colors"
                  >
                    ✕
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleSwipe('right', users[currentIndex]._id)}
                    className="bg-green-500/90 backdrop-blur-sm text-white w-16 h-16 rounded-full flex items-center justify-center text-2xl shadow-lg hover:bg-green-600/90 transition-colors"
                  >
                    ♥
                  </motion.button>
                </div>

                {/* Name and location info */}
                <div className="absolute bottom-6 left-6 right-6 flex flex-col gap-2">
                  <div className="bg-blue-500/90 backdrop-blur-sm text-white px-4 py-2 rounded-lg shadow-lg">
                    <h2 className="text-2xl font-bold">
                      {users[currentIndex].profile.name}, {users[currentIndex].profile.age}
                    </h2>
                    <p className="text-white/90">{users[currentIndex].profile.location}</p>
                  </div>
                </div>
              </div>
              
              <div className="p-4">
                <p className="text-gray-700">{users[currentIndex].profile.bio}</p>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      {currentIndex >= users.length ? (
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-8 text-center max-w-md w-full"
        >
          <h2 className="text-2xl font-bold text-white mb-4">{t('browse.noMoreProfiles')}</h2>
          <p className="text-white/90 mb-6">{t('browse.checkBackLater')}</p>
          <button
            onClick={() => {
              setCurrentIndex(0);
              fetchUsers();
            }}
            className="bg-white/20 hover:bg-white/30 text-white px-6 py-2 rounded-full transition-colors"
          >
            {t('browse.refresh')}
          </button>
        </motion.div>
      ) : (
        <div className="max-w-md mx-auto pt-2">
        </div>
      )}
      {matchedUser && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4">
          <motion.div 
            className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-8 max-w-md w-full text-center"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", duration: 0.5 }}
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.3 }}
              className="text-6xl mb-4"
            >
              🎉
            </motion.div>
            <h2 className="text-3xl font-bold text-white mb-4">{t('swipe.match')}</h2>
            <p className="text-white/90 mb-6">
              {t('swipe.matchMessage', { name: matchedUser.profile.name })}
            </p>
            <div className="flex justify-center space-x-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  navigate(`/messages/${matchedUser._id}`);
                  setMatchedUser(null);
                }}
                className="px-6 py-3 bg-white text-purple-600 rounded-lg font-medium hover:bg-white/90 transition-colors"
              >
                {t('swipe.message')}
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setMatchedUser(null)}
                className="px-6 py-3 bg-white/10 text-white rounded-lg font-medium hover:bg-white/20 transition-colors"
              >
                {t('swipe.continue')}
              </motion.button>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default Swipe; 