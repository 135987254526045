import { useState, useEffect } from 'react';
import { LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';

function ServerMonitoring() {
  const [metrics, setMetrics] = useState({
    cpu: 0,
    memory: { total: 0, used: 0, free: 0 },
    heap: { total: 0, used: 0, limit: 0 },
    connections: 0,
    websocket: {
      messagesPerMinute: 0,
      activeRooms: 0,
      avgDuration: 0,
      peakConnections: 0
    }
  });
  const [historicalData, setHistoricalData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMetrics();
    const interval = setInterval(fetchMetrics, 5000);
    return () => clearInterval(interval);
  }, []);

  const fetchMetrics = async () => {
    try {
      const token = localStorage.getItem('token');
      const [current, history] = await Promise.all([
        axios.get('/api/admin/server/metrics', {
          headers: { 'x-auth-token': token }
        }),
        axios.get('/api/admin/server/metrics/history', {
          headers: { 'x-auth-token': token }
        })
      ]);

      setMetrics(current.data);
      setHistoricalData(history.data.metrics || []);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching metrics:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) return <div className="p-4">Loading metrics...</div>;
  if (error) return <div className="p-4 text-red-500">Error: {error}</div>;

  return (
    <div className="space-y-8 p-4">
      {/* System Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* CPU Usage */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-gray-500 text-sm font-medium">CPU Usage</h3>
          <p className="text-3xl font-bold text-gray-900 mt-2">{metrics.cpu}%</p>
          <div className="mt-2 w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-blue-600 h-2 rounded-full"
              style={{ width: `${metrics.cpu}%` }}
            />
          </div>
        </div>

        {/* Memory Usage */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-gray-500 text-sm font-medium">Memory Usage</h3>
          <p className="text-3xl font-bold text-gray-900 mt-2">
            {((metrics.memory.used / metrics.memory.total) * 100).toFixed(1)}%
          </p>
          <div className="mt-2 w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-green-500 h-2 rounded-full"
              style={{
                width: `${(metrics.memory.used / metrics.memory.total) * 100}%`
              }}
            />
          </div>
          <p className="text-sm text-gray-500 mt-2">
            {formatBytes(metrics.memory.used)} / {formatBytes(metrics.memory.total)}
          </p>
        </div>

        {/* Heap Usage */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-gray-500 text-sm font-medium">Heap Usage</h3>
          <p className="text-3xl font-bold text-gray-900 mt-2">
            {((metrics.heap.used / metrics.heap.total) * 100).toFixed(1)}%
          </p>
          <div className="mt-2 w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-purple-500 h-2 rounded-full"
              style={{
                width: `${(metrics.heap.used / metrics.heap.total) * 100}%`
              }}
            />
          </div>
          <p className="text-sm text-gray-500 mt-2">
            {formatBytes(metrics.heap.used)} / {formatBytes(metrics.heap.total)}
          </p>
        </div>

        {/* Active Connections */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-gray-500 text-sm font-medium">Active Connections</h3>
          <p className="text-3xl font-bold text-gray-900 mt-2">{metrics.connections || 'N/A'}</p>
          <p className="text-sm text-gray-500 mt-2">
            Peak: {metrics.websocket?.peakConnections || 'N/A'}
          </p>
        </div>
      </div>

      {/* WebSocket Metrics */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-lg font-semibold mb-4">WebSocket Metrics</h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          <div>
            <p className="text-sm text-gray-500">Active Connections</p>
            <p className="text-xl font-semibold">{metrics.connections || 'N/A'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Messages/min</p>
            <p className="text-xl font-semibold">{metrics.websocket?.messagesPerMinute || 'N/A'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Active Rooms</p>
            <p className="text-xl font-semibold">{metrics.websocket?.activeRooms || 'N/A'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Peak Connections</p>
            <p className="text-xl font-semibold">{metrics.websocket?.peakConnections || 'N/A'}</p>
          </div>
        </div>
      </div>

      {/* Historical Metrics */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h3 className="text-lg font-semibold mb-4">Historical Metrics</h3>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={historicalData}>
              <defs>
                <linearGradient id="colorCpu" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <XAxis 
                dataKey="timestamp" 
                tickFormatter={(time) => new Date(time).toLocaleTimeString()}
              />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip 
                labelFormatter={(time) => new Date(time).toLocaleString()}
                formatter={(value) => `${value}%`}
              />
              <Area
                type="monotone"
                dataKey="cpu"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorCpu)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

function formatBytes(bytes) {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export default ServerMonitoring;