import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import axios from 'axios';
import EmailDashboard from './email/EmailDashboard.jsx';
import ServerMonitoring from './ServerMonitoring';

function AdminDashboard() {
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    suspended: 0,
    gender: { male: 0, female: 0, ladyboy: 0 },
    ageGroups: { '18-25': 0, '26-35': 0, '36-45': 0, '46+': 0 },
    growth: { daily: 0, weekly: 0, monthly: 0 },
    locationData: [],
    geoData: {}
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('dashboard');
  const [searchTerm, setSearchTerm] = useState('');

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658'];
  
  const defaultLocationData = [
    { name: 'Thailand', value: 0 },
    { name: 'Japan', value: 0 },
    { name: 'China', value: 0 },
    { name: 'USA', value: 0 },
    { name: 'UK', value: 0 }
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      setError('');
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/admin/users', {
        headers: { 'x-auth-token': token }
      });
      
      const defaultStats = {
        total: 0,
        active: 0,
        suspended: 0,
        gender: { male: 0, female: 0, ladyboy: 0 },
        ageGroups: { '18-25': 0, '26-35': 0, '36-45': 0, '46+': 0 },
        growth: { daily: 0, weekly: 0, monthly: 0 },
        locationData: [],
        geoData: {}
      };

      if (!response.data?.users || !response.data?.stats) {
        throw new Error('Invalid response format');
      }

      setUsers(response.data.users);
      setStats({
        ...defaultStats,
        ...response.data.stats,
        gender: { ...defaultStats.gender, ...response.data.stats.gender },
        ageGroups: { ...defaultStats.ageGroups, ...response.data.stats.ageGroups },
        growth: { ...defaultStats.growth, ...response.data.stats.growth }
      });
      setLoading(false);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(err.message);
      setLoading(false);
      
      if (err.response?.status === 401 || err.response?.status === 403) {
        navigate('/login');
      }
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/api/admin/users/${userId}`, {
        headers: { 'x-auth-token': token }
      });
      
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('Failed to delete user. Please try again.');
    }
  };

  const handleSuspendUser = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`/api/admin/users/${userId}/suspend`, null, {
        headers: { 'x-auth-token': token }
      });
      
      fetchUsers();
    } catch (error) {
      console.error('Error suspending user:', error);
      alert('Failed to suspend user. Please try again.');
    }
  };

  const genderData = Object.entries(stats?.gender || { male: 0, female: 0, ladyboy: 0 }).map(([name, value]) => ({
    name: name.charAt(0).toUpperCase() + name.slice(1),
    value: typeof value === 'number' ? value : 0
  })).filter(item => item.value > 0);

  const ageData = Object.entries(stats?.ageGroups || { '18-25': 0, '26-35': 0, '36-45': 0, '46+': 0 }).map(([range, count]) => ({
    range,
    users: typeof count === 'number' ? count : 0
  }));

  const locationData = (Array.isArray(stats?.locationData) ? stats.locationData : [])
    .map(item => ({
      location: item?.location || 'Unknown',
      count: parseInt(item?.count || 0, 10),
      region: item?.region || 'Unknown'
    }))
    .filter(item => item.count > 0 && item.location !== 'Unknown')
    .sort((a, b) => b.count - a.count)
    .slice(0, 10)
    .map(item => ({
      name: item.location.charAt(0).toUpperCase() + item.location.slice(1),
      value: item.count
    }));

  const validateChartData = (data, defaultValue = []) => {
    if (!data || data.length === 0) return defaultValue;
    return data.map(item => ({
      ...item,
      value: typeof item.value === 'number' ? item.value : 0
    })).filter(item => item.value > 0);
  };

  const validatedGenderData = validateChartData(genderData, [
    { name: 'Male', value: 0 },
    { name: 'Female', value: 0 },
    { name: 'Ladyboy', value: 0 }
  ]);

  const validatedAgeData = ageData.length > 0 ? ageData : [
    { range: '18-25', users: 0 },
    { range: '26-35', users: 0 },
    { range: '36-45', users: 0 },
    { range: '46+', users: 0 }
  ];

  const renderChart = (data, component) => {
    if (!data || data.length === 0) {
      return (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500">No data available</p>
        </div>
      );
    }
    return component;
  };

  const filteredUsers = users.filter(user => {
    const searchLower = searchTerm.toLowerCase();
    return (
      user.email.toLowerCase().includes(searchLower) ||
      (user.profile?.name?.toLowerCase().includes(searchLower)) ||
      (user.profile?.location?.toLowerCase().includes(searchLower))
    );
  });

  if (loading) return <div className="p-6">Loading...</div>;
  if (error) return <div className="p-6 text-red-500">Error: {error}</div>;

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Admin Dashboard</h2>
        <div className="space-x-4">
          <button
            onClick={() => setActiveSection('dashboard')}
            className={`px-4 py-2 rounded ${activeSection === 'dashboard' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Dashboard
          </button>
          <button
            onClick={() => setActiveSection('email')}
            className={`px-4 py-2 rounded ${activeSection === 'email' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Email Stats
          </button>
          <button
            onClick={() => setActiveSection('server')}
            className={`px-4 py-2 rounded ${activeSection === 'server' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Server Stats
          </button>
        </div>
      </div>

      {activeSection === 'email' && <EmailDashboard />}
      {activeSection === 'server' && <ServerMonitoring />}
      
      {activeSection === 'dashboard' && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-gray-500 text-sm font-medium">Total Users</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">{stats.total}</p>
              <p className="text-sm text-green-500 mt-1">+{stats.growth.daily} today</p>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-gray-500 text-sm font-medium">Active Users</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">{stats.active || 0}</p>
              <p className="text-sm text-gray-500 mt-1">
                {stats.total > 0 ? ((stats.active / stats.total) * 100).toFixed(1) : '0'}%
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-gray-500 text-sm font-medium">Suspended Users</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">{stats.suspended || 0}</p>
              <p className="text-sm text-gray-500 mt-1">
                {stats.total > 0 ? ((stats.suspended / stats.total) * 100).toFixed(1) : '0'}%
              </p>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-gray-500 text-sm font-medium">Growth</h3>
              <p className="text-3xl font-bold text-gray-900 mt-2">+{stats.growth.monthly}</p>
              <p className="text-sm text-gray-500 mt-1">Last 30 days</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold mb-4">Top 10 Locations</h3>
              <div className="h-[400px]">
                {renderChart(locationData.length > 0 ? locationData : defaultLocationData,
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={locationData.length > 0 ? locationData : defaultLocationData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={160}
                        label={({ name, value, percent }) => `${name} (${value}, ${(percent * 100).toFixed(0)}%)`}
                      >
                        {(locationData.length > 0 ? locationData : defaultLocationData).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => `${value} users`} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold mb-4">Gender Distribution</h3>
              <div className="h-64">
                {renderChart(validatedGenderData,
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={validatedGenderData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        label={({ name, value }) => `${name} (${value})`}
                      >
                        {validatedGenderData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => value} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-semibold mb-4">Age Distribution</h3>
              <div className="h-64">
                {renderChart(validatedAgeData,
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={validatedAgeData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="range" />
                      <YAxis allowDecimals={false} />
                      <Tooltip formatter={(value) => value} />
                      <Bar dataKey="users" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
              <h3 className="text-lg font-semibold">User List</h3>
              <input
                type="text"
                placeholder="Search users..."
                className="px-4 py-2 border rounded-lg w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredUsers.map(user => (
                    <tr key={user._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div>
                            <div className="text-sm font-medium text-gray-900">
                              {user.profile?.name || 'N/A'}
                            </div>
                            <div className="text-sm text-gray-500">
                              Joined: {new Date(user.createdAt).toLocaleDateString()}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{user.email}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          user.suspended ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                        }`}>
                          {user.suspended ? 'Suspended' : 'Active'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.profile?.location || 'N/A'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button
                          onClick={() => handleSuspendUser(user._id)}
                          className="text-indigo-600 hover:text-indigo-900 mr-4"
                        >
                          {user.suspended ? 'Unsuspend' : 'Suspend'}
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user._id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                  {filteredUsers.length === 0 && (
                    <tr>
                      <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                        No users found matching your search
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AdminDashboard;