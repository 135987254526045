import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const API_URL = config.API_URL;
const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      setupAxiosDefaults();
      await checkAuthStatus();
    };

    initAuth();
  }, []);

  const setupAxiosDefaults = () => {
    const token = localStorage.getItem('token');
    console.log('Setting up axios defaults with token:', token ? 'Present' : 'Missing');
    
    // Clear any existing headers
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['x-auth-token'];

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      axios.defaults.headers.common['x-auth-token'] = token;
    }

    // Set base URL and other defaults
    axios.defaults.baseURL = API_URL;
    axios.defaults.withCredentials = true;

    // Add request interceptor to add token to all requests
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
          config.headers['x-auth-token'] = token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add response interceptor to handle 401s
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          console.log('Received 401, clearing token and redirecting to login');
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );
  };

  const checkAuthStatus = async () => {
    try {
      let token = localStorage.getItem('token');
      
      // If token is not in localStorage, check for cookie
      if (!token) {
        const cookies = document.cookie.split('; ');
        const authCookie = cookies.find(cookie => cookie.startsWith('auth_token='));
        if (authCookie) {
          token = authCookie.split('=')[1];
          // Restore token to localStorage
          localStorage.setItem('token', token);
        }
      }
      
      console.log('Token from storage:', token ? 'Present' : 'Missing');

      if (!token) {
        console.log('No token found, setting loading false');
        setLoading(false);
        return;
      }

      // Use full URL here
      const response = await axios.get(`${API_URL}/api/auth/verify`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-auth-token': token
        }
      });

      console.log('Verify response:', response.data);

      if (response.data) {
        setCurrentUser(response.data);
      } else {
        // Clear everything if verification fails
        localStorage.removeItem('token');
        document.cookie = 'auth_token=; path=/; max-age=0'; // Clear cookie
        delete axios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['x-auth-token'];
        setCurrentUser(null);
      }
    } catch (error) {
      console.error('Auth check error:', error);
      // Only clear on auth errors
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        document.cookie = 'auth_token=; path=/; max-age=0'; // Clear cookie
        delete axios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['x-auth-token'];
        setCurrentUser(null);
      }
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    try {
      // First login to get the token
      const response = await axios.post('/api/auth/login', { 
        email: email,
        password: password 
      });
      
      const { token } = response.data;
      
      // Set token in localStorage and axios defaults
      localStorage.setItem('token', token);
      // Set a cookie with a long expiration to keep users logged in
      document.cookie = `auth_token=${token}; path=/; max-age=31536000; SameSite=Strict`; // 1 year expiration
      setupAxiosDefaults();

      // Fetch fresh user data with profile and membership
      const { data: userData } = await axios.get('/api/auth/me');
      setCurrentUser(userData);
      
      return { token, user: userData };
    } catch (error) {
      console.error('Login error:', error.response?.data || error.message);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    document.cookie = 'auth_token=; path=/; max-age=0'; // Clear cookie
    setCurrentUser(null);
    setupAxiosDefaults(); // Reset axios without token
    window.location.href = '/login';
  };

  const updateUser = (userData) => {
    setCurrentUser(userData);
  };

  const value = {
    currentUser,
    login,
    logout,
    updateUser,
    hasFullAccess: currentUser?.hasFullAccess || false,
    isPremium: currentUser?.membership?.type === 'premium',
    isFemale: currentUser?.profile?.gender === 'Female',
    isLadyboy: currentUser?.profile?.gender === 'Ladyboy',
    membershipType: currentUser?.membership?.type || 'free',
    membershipExpiresAt: currentUser?.membership?.expiresAt,
    isAdmin: currentUser?.isAdmin || false
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
