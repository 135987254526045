import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create an axios instance with the base URL
const api = axios.create({
  baseURL: 'https://thaimeetup.com/api'
});

const TranslationPanel = ({ message, onClose, onSendMessage }) => {
  const { t } = useTranslation();
  const [translation, setTranslation] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sourceLang, setSourceLang] = useState(null);
  const [targetLang, setTargetLang] = useState(null);
  const [customText, setCustomText] = useState('');
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    if (message) {
      setCurrentText(message);
      detectAndTranslate(message);
    }
  }, [message]);

  const detectAndTranslate = async (text) => {
    if (!text?.trim()) return;
    
    try {
      setLoading(true);
      setError(null);

      const detectResponse = await api.post('/translation/detect', {
        text: text
      });

      if (!detectResponse.data?.language) {
        throw new Error(t('translation.panel.error'));
      }

      const detectedLang = detectResponse.data.language;
      setSourceLang(detectedLang);
      
      const newTargetLang = detectedLang === 'th' ? 'en' : 'th';
      setTargetLang(newTargetLang);

      const translateResponse = await api.post('/translation', {
        text: text,
        targetLang: newTargetLang,
        sourceLang: detectedLang
      });

      if (!translateResponse.data?.translatedText) {
        throw new Error(t('translation.panel.error'));
      }

      setTranslation(translateResponse.data.translatedText);
    } catch (err) {
      console.error('Translation error:', err);
      const errorMsg = err.response?.data?.message || t('translation.panel.error');
      setError(errorMsg);
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleCustomTextSubmit = async (e) => {
    e.preventDefault();
    if (!customText.trim()) return;
    
    setCurrentText(customText);
    await detectAndTranslate(customText);
  };

  const handleSendToChat = () => {
    if (typeof onSendMessage === 'function' && translation) {
      onSendMessage(translation);
      onClose();
    }
  };

  return (
    <div className="bg-gray-900/95 backdrop-blur-lg rounded-xl shadow-xl p-4 relative max-w-lg w-full">
      <div className="mb-4">
        <h3 className="text-lg font-semibold text-white mb-2">{t('translation.panel.title')}</h3>
        
        {/* Message translation section */}
        {message && (
          <>
            <div className="bg-white/5 rounded-lg p-3 mb-3">
              <div className="text-sm text-white/60 mb-1">
                {sourceLang === 'th' ? t('translation.panel.thai') : t('translation.panel.english')}
              </div>
              <div className="text-white whitespace-pre-wrap break-words">{message}</div>
            </div>
            <div className="bg-white/5 rounded-lg p-3 mb-4">
              <div className="text-sm text-white/60 mb-1">
                {targetLang === 'th' ? t('translation.panel.thai') : t('translation.panel.english')}
              </div>
              <div className="text-white whitespace-pre-wrap break-words">
                {loading ? (
                  <div className="animate-pulse">{t('translation.panel.translating')}</div>
                ) : error ? (
                  <div className="text-red-400">{error}</div>
                ) : (
                  translation
                )}
              </div>
            </div>
          </>
        )}

        {/* Custom text input section */}
        <form onSubmit={handleCustomTextSubmit} className="mt-4">
          <div className="flex gap-2">
            <input
              type="text"
              value={customText}
              onChange={(e) => setCustomText(e.target.value)}
              placeholder={t('translation.panel.enterText')}
              className="flex-1 bg-white/5 text-white rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              disabled={!customText.trim() || loading}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors disabled:opacity-50"
            >
              {t('translation.panel.translate')}
            </button>
          </div>
        </form>

        {/* Custom text translation result */}
        {customText && translation && (
          <div className="bg-white/5 rounded-lg p-3 mt-3">
            <div className="text-sm text-white/60 mb-1">
              {targetLang === 'th' ? t('translation.panel.thai') : t('translation.panel.english')}
            </div>
            <div className="text-white whitespace-pre-wrap break-words">
              {loading ? (
                <div className="animate-pulse">{t('translation.panel.translating')}</div>
              ) : error ? (
                <div className="text-red-400">{error}</div>
              ) : (
                translation
              )}
            </div>
          </div>
        )}
      </div>

      {/* Action buttons */}
      <div className="mt-4 flex justify-center gap-3">
        {customText && translation && (
          <button
            onClick={handleSendToChat}
            disabled={!translation || loading}
            className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-full transition-colors disabled:opacity-50 flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
            {t('translation.panel.sendToChat')}
          </button>
        )}
        <button
          onClick={onClose}
          className="bg-white/10 hover:bg-white/20 text-white px-6 py-2 rounded-full transition-colors flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
          {t('translation.panel.close')}
        </button>
      </div>
    </div>
  );
};

TranslationPanel.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired
};

TranslationPanel.defaultProps = {
  message: ''
};

export default TranslationPanel;
