import { List, Datagrid, TextField, DateField } from 'react-admin';

export const ReportList = () => (
    <List>
        <Datagrid>
            <TextField source="reporterName" label="Reporter" />
            <TextField source="reportedUserName" label="Reported User" />
            <TextField source="reason" />
            <DateField source="createdAt" label="Date" />
            <TextField source="status" />
        </Datagrid>
    </List>
);

export default ReportList; 
