import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const styles = {
  'image-error': {
    padding: '10px',
    margin: '10px',
    backgroundColor: '#ffebee',
    border: '1px solid #ef5350',
    borderRadius: '4px',
    color: '#c62828',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all'
  },
  'error-message': {
    padding: '15px',
    margin: '15px 0',
    backgroundColor: '#fff3cd',
    border: '1px solid #ffeeba',
    borderRadius: '4px',
    color: '#856404'
  }
};

function PrivatePhotos() {
  const { currentUser } = useAuth();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSharedUsersModalOpen, setIsSharedUsersModalOpen] = useState(false);
  const [interestedUsers, setInterestedUsers] = useState([]);
  const [sharedUserIds, setSharedUserIds] = useState([]);
  const [sharing, setSharing] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();
  const API_URL = config.API_URL;
  const { t } = useTranslation();

  // Check authentication
  useEffect(() => {
    if (!currentUser) {
      console.log('No current user, redirecting to login');
      navigate('/login');
      return;
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    fetchPhotos();
  }, []);

  const getAuthenticatedImageUrl = (photo) => {
    if (!photo?.url) return '';
    const token = localStorage.getItem('token');
    if (!token) return '';

    // Create URL object to properly handle query parameters
    const url = new URL(`${API_URL}/api/private-photos/image/${photo._id}`);
    url.searchParams.append('token', token);
    return url.toString();
  };

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      'x-auth-token': token,
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    };
  };

  const fetchPhotos = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await axios.get(`${API_URL}/api/private-photos`, {
        headers: getAuthHeaders()
      });

      console.log('Private photos response:', {
        status: response.status,
        dataLength: response.data?.length || 0,
        data: response.data
      });

      if (!response.data) {
        throw new Error('No data received from server');
      }

      setPhotos(response.data || []);
    } catch (error) {
      console.error('Error fetching photos:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setError(error.message);
      toast.error(t('privatePhotos.errors.loadFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (!file) return;

      // Create form data
      const formData = new FormData();
      formData.append('photo', file);

      // Upload with progress tracking
      const response = await axios.post(
        `${API_URL}/api/private-photos/upload`,
        formData,
        {
          headers: {
            ...getAuthHeaders(),
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          }
        }
      );

      console.log('Upload response:', response.data);
      setUploadProgress(0);
      
      // Refresh photos after upload
      fetchPhotos();
      toast.success(t('privatePhotos.success.uploaded'));
    } catch (error) {
      console.error('Error uploading photo:', error);
      setError(error.response?.data?.message || 'Failed to upload photo');
      setUploadProgress(0);
      toast.error(t('privatePhotos.errors.uploadFailed'));
    }
  };

  const handleDeletePhoto = async (photoId) => {
    try {
      if (!window.confirm(t('privatePhotos.warnings.deleteConfirm'))) {
        return;
      }
      setError(null);
      
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/private-photos/${photoId}`, {
        headers: getAuthHeaders()
      });
      
      // Refresh the photos after successful deletion
      fetchPhotos();
      toast.success(t('privatePhotos.success.deleted'));
    } catch (error) {
      console.error('Error deleting photo:', error);
      const errorMessage = error.response?.data?.message || 'Failed to delete photo';
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  const fetchInterestedUsers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      console.log('Token:', token);
      
      const response = await axios.get(`${API_URL}/api/private-photos/users/interested`, {
        headers: getAuthHeaders()
      });
      console.log('Interested users:', response.data);
      setInterestedUsers(response.data);
    } catch (error) {
      console.error('Error fetching interested users:', error);
      toast.error(t('privatePhotos.errors.loadUsersFailed'));
    } finally {
      setLoading(false);
    }
  };

  const fetchSharedUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/api/private-photos/shared-users`, {
        headers: getAuthHeaders()
      });
      console.log('Shared users data:', response.data);  
      setSharedUserIds(response.data.map(user => user._id));
    } catch (error) {
      console.error('Error fetching shared users:', error);
      setError(error.response?.data?.message || t('privatePhotos.errors.fetchSharedUsersFailed'));
      toast.error(t('privatePhotos.errors.fetchSharedUsersFailed'));
    }
  };

  const handleShareGallery = async () => {
    const confirmed = window.confirm(t('privatePhotos.warnings.shareWarning'));
    if (!confirmed) return;

    try {
      setSharing(true);
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/api/private-photos/share`,
        { userIds: selectedUsers },
        { 
          headers: getAuthHeaders()
        }
      );
      
      toast.success("Gallery shared successfully ✨", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon: "🎉"
      });
      setShowShareModal(false);
      setSelectedUsers([]);
    } catch (error) {
      console.error('Error sharing gallery:', error);
      toast.error(t('privatePhotos.errors.shareFailed'));
    } finally {
      setSharing(false);
    }
  };

  const toggleUserSelection = (userId) => {
    setSelectedUsers(prev => 
      prev.includes(userId) 
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };

  const SharedUsersModal = ({ isOpen, onClose }) => {
    const [sharedUsers, setSharedUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [revoking, setRevoking] = useState(false);

    useEffect(() => {
      if (isOpen) {
        fetchSharedUsers();
      }
    }, [isOpen]);

    const fetchSharedUsers = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/private-photos/shared-users`, {
          headers: getAuthHeaders()
        });
        setSharedUsers(response.data);
      } catch (error) {
        console.error('Error fetching shared users:', error);
        setError(error.response?.data?.message || t('privatePhotos.errors.fetchSharedUsersFailed'));
        toast.error(t('privatePhotos.errors.fetchSharedUsersFailed'));
      } finally {
        setLoading(false);
      }
    };

    const handleRevokeAccess = async (userId) => {
      if (!window.confirm(t('privatePhotos.warnings.revokeAccessConfirm'))) return;
      
      try {
        setRevoking(true);
        const token = localStorage.getItem('token');
        await axios.delete(
          `${API_URL}/api/private-photos/share/${userId}`,
          { 
            headers: getAuthHeaders()
          }
        );
        
        toast.success(t('privatePhotos.success.accessRevoked'));
        // Refresh the list
        fetchSharedUsers();
      } catch (error) {
        console.error('Error revoking access:', error);
        toast.error(error.response?.data?.message || t('privatePhotos.errors.revokeAccessFailed'));
      } finally {
        setRevoking(false);
      }
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full mx-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">{t('privatePhotos.modals.sharedUsers.title')}</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center py-4">{error}</div>
          ) : sharedUsers.length === 0 ? (
            <div className="text-gray-400 text-center py-4">
              {t('privatePhotos.modals.sharedUsers.noUsers')}
            </div>
          ) : (
            <div className="space-y-3">
              {sharedUsers.map(user => (
                <div key={user._id} className="flex items-center justify-between bg-gray-700 p-3 rounded-lg">
                  <div className="flex items-center space-x-3">
                    <img
                      src={user.profile?.profilePicture ? `${API_URL}${user.profile.profilePicture}` : 'https://via.placeholder.com/40?text=User'}
                      alt={user.nid || 'User'}
                      className="w-10 h-10 rounded-full object-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = 'https://via.placeholder.com/40?text=User';
                      }}
                    />
                    <div>
                      <div className="text-white font-medium">
                        {user.nid}
                      </div>
                      <div className="text-gray-400 text-sm">
                        {user.profile?.name || t('privatePhotos.modals.sharedUsers.nameNotSpecified')}
                      </div>
                      <div className="text-gray-400 text-sm">
                        {user.profile?.location || t('privatePhotos.modals.sharedUsers.locationNotSpecified')}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleRevokeAccess(user._id)}
                    disabled={revoking}
                    className={`${
                      revoking 
                        ? 'bg-gray-600 cursor-not-allowed' 
                        : 'bg-red-600 hover:bg-red-700'
                    } text-white px-3 py-1 rounded-lg text-sm transition-colors flex items-center space-x-1`}
                  >
                    {revoking ? (
                      <>
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                        <span>{t('privatePhotos.buttons.revoking')}</span>
                      </>
                    ) : (
                      <span>{t('privatePhotos.buttons.revokeAccess')}</span>
                    )}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Image Viewer Modal Component
  const ImageViewerModal = ({ image, onClose }) => {
    if (!image) return null;

    const currentIndex = photos.findIndex(p => p._id === image._id);
    
    const handlePrevious = (e) => {
      e.stopPropagation();
      if (currentIndex > 0) {
        setSelectedImage(photos[currentIndex - 1]);
      }
    };

    const handleNext = (e) => {
      e.stopPropagation();
      if (currentIndex < photos.length - 1) {
        setSelectedImage(photos[currentIndex + 1]);
      }
    };

    // Add keyboard navigation
    React.useEffect(() => {
      const handleKeyDown = (e) => {
        if (e.key === 'ArrowLeft') {
          handlePrevious(e);
        } else if (e.key === 'ArrowRight') {
          handleNext(e);
        } else if (e.key === 'Escape') {
          onClose();
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, [currentIndex]);

    const handleClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };

    return (
      <div 
        onClick={handleClick}
        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
      >
        <div className="relative max-w-4xl w-full">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute -top-12 right-0 text-white hover:text-gray-300"
          >
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {/* Image */}
          <div onClick={e => e.stopPropagation()} className="relative">
            <img
              src={getAuthenticatedImageUrl(image)}
              alt="Full size"
              className="max-h-[90vh] max-w-[90vw] object-contain"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'https://via.placeholder.com/800x600?text=Image+Not+Found';
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleShareClick = () => {
    console.log('Share button clicked');
    setShowShareModal(true);
    console.log('Modal state set to:', true);
    fetchInterestedUsers();
    fetchSharedUsers();
  };

  const PrivatePhotoCard = ({ photo, onDelete }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    return (
      <div 
        className="relative group"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="aspect-square overflow-hidden rounded-lg">
          <img
            src={getAuthenticatedImageUrl(photo)}
            alt={photo.caption || t('privatePhotos.noCaption')}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
            onError={(e) => {
              console.error('Image load error:', {
                src: e.target.src,
                photo: photo,
                error: e.error
              });
              e.target.parentNode.innerHTML = `
                <div class="w-full h-full bg-red-500/20 rounded-lg flex items-center justify-center text-red-300 p-4 text-center">
                  <div>
                    <img src="/images/placeholder.png" alt="Error" class="w-16 h-16 mx-auto mb-2" />
                    <div>Error loading image</div>
                  </div>
                </div>
              `;
            }}
          />
        </div>
        
        {/* Delete Button */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (!window.confirm(t('privatePhotos.warnings.deleteConfirm'))) return;

            setIsDeleting(true);
            axios.delete(`${API_URL}/api/private-photos/${photo._id}`, {
              headers: getAuthHeaders()
            })
              .then(() => {
                toast.success(t('privatePhotos.success.photoDeleted'));
                onDelete(photo._id);
              })
              .catch((error) => {
                console.error('Error deleting photo:', error);
                toast.error(t('privatePhotos.errors.deleteFailed'));
              })
              .finally(() => {
                setIsDeleting(false);
              });
          }}
          disabled={isDeleting}
          className={`
            absolute top-2 right-2 
            ${isHovered ? 'opacity-100' : 'opacity-0'} 
            transition-opacity duration-200
            bg-red-600 hover:bg-red-700 
            rounded-full p-1.5
            text-white
            shadow-lg
            ${isDeleting ? 'cursor-not-allowed bg-gray-600' : ''}
          `}
        >
          {isDeleting ? (
            <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
          ) : (
            <svg 
              className="w-5 h-5" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </button>

        {/* Caption (if exists) */}
        {photo.caption && (
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">
            {photo.caption}
          </div>
        )}
      </div>
    );
  };

  const WarningMessage = () => (
    <div className="bg-yellow-500/20 backdrop-blur-sm border border-yellow-500/50 rounded-lg p-4 mb-6">
      <h3 className="text-yellow-300 font-semibold mb-2">{t('privatePhotos.warning.title')}</h3>
      <p className="text-white/90">
        {t('privatePhotos.warning.description')}
      </p>
      <ul className="list-disc list-inside text-white/80 mt-2 space-y-1">
        <li>{t('privatePhotos.warning.risks.save')}</li>
        <li>{t('privatePhotos.warning.risks.sensitive')}</li>
        <li>{t('privatePhotos.warning.risks.trust')}</li>
        <li>{t('privatePhotos.warning.risks.report')}</li>
      </ul>
    </div>
  );

  const ShareGalleryModal = ({ isOpen, onClose }) => {
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [interestedUsers, setInterestedUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sharing, setSharing] = useState(false);

    useEffect(() => {
      if (isOpen) {
        fetchInterestedUsers();
      }
    }, [isOpen]);

    const fetchInterestedUsers = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/private-photos/users/interested`, {
          headers: getAuthHeaders()
        });
        setInterestedUsers(response.data);
      } catch (error) {
        console.error('Error fetching interested users:', error);
        setError(t('privatePhotos.errors.fetchInterestedUsersFailed'));
        toast.error(t('privatePhotos.errors.fetchInterestedUsersFailed'));
      } finally {
        setLoading(false);
      }
    };

    const handleShareGallery = async () => {
      if (selectedUsers.length === 0) {
        toast.warning(t('privatePhotos.warnings.selectUsers'), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      try {
        setSharing(true);
        const token = localStorage.getItem('token');
        await axios.post(
          `${API_URL}/api/private-photos/share`,
          { userIds: selectedUsers },
          { 
            headers: getAuthHeaders()
          }
        );
        
        toast.success("Gallery shared successfully ✨", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          icon: "🎉"
        });
        
        onClose();
        setSelectedUsers([]);
      } catch (error) {
        console.error('Error sharing gallery:', error);
        toast.error(t('privatePhotos.errors.shareFailed'), {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setSharing(false);
      }
    };

    const toggleUser = (userId) => {
      setSelectedUsers(prev => 
        prev.includes(userId)
          ? prev.filter(id => id !== userId)
          : [...prev, userId]
      );
    };

    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full mx-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">{t('privatePhotos.modals.shareGallery.title')}</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <WarningMessage />

          {loading ? (
            <div className="flex justify-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center py-4">{error}</div>
          ) : interestedUsers.length === 0 ? (
            <div className="text-gray-400 text-center py-4">
              {t('privatePhotos.modals.shareGallery.noUsers')}
            </div>
          ) : (
            <>
              <div className="space-y-3 max-h-96 overflow-y-auto">
                {interestedUsers.map(user => (
                  <div
                    key={user._id}
                    onClick={() => toggleUser(user._id)}
                    className={`
                      flex items-center space-x-3 p-3 rounded-lg cursor-pointer
                      ${selectedUsers.includes(user._id) ? 'bg-blue-600' : 'bg-gray-700 hover:bg-gray-600'}
                      transition-colors duration-200
                    `}
                  >
                    <div className="flex-shrink-0">
                      <img
                        src={user.profile?.profilePicture ? `${API_URL}${user.profile.profilePicture}` : 'https://via.placeholder.com/40?text=User'}
                        alt={user.nid}
                        className="w-10 h-10 rounded-full object-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'https://via.placeholder.com/40?text=User';
                        }}
                      />
                    </div>
                    <div className="flex-grow">
                      <div className="text-white font-medium">
                        {user.nid}
                      </div>
                      <div className="text-gray-400 text-sm">
                        {user.profile?.name || t('privatePhotos.modals.shareGallery.nameNotSpecified')}
                      </div>
                    </div>
                    {selectedUsers.includes(user._id) && (
                      <div className="text-white">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  onClick={handleShareGallery}
                  disabled={sharing || selectedUsers.length === 0}
                  className={`
                    ${sharing || selectedUsers.length === 0 ? 'bg-gray-600 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}
                    text-white px-4 py-2 rounded-lg
                    flex items-center space-x-2
                    transition-colors duration-200
                  `}
                >
                  {sharing ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
                      <span>{t('privatePhotos.buttons.sharing')}</span>
                    </>
                  ) : (
                    <>
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                      </svg>
                      <span>{t('privatePhotos.buttons.shareGallery')}</span>
                    </>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderGallery = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-red-500 text-center py-4">
          {error}
        </div>
      );
    }

    if (photos.length === 0) {
      return (
        <div className="text-white/80 text-center py-12">
          {t('privatePhotos.noPhotos')}
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.map(photo => (
          <PrivatePhotoCard 
            key={photo._id} 
            photo={photo} 
            onDelete={(photoId) => {
              setPhotos(photos => photos.filter(p => p._id !== photoId));
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white/10 backdrop-blur-lg rounded-lg shadow-xl p-6">
          <div className="mb-6">
            <h1 className="text-3xl font-bold text-white mb-2">{t('privatePhotos.title')}</h1>
            <p className="text-white/80">{t('privatePhotos.description')}</p>
          </div>

          <WarningMessage />

          {renderGallery()}
        </div>

        {uploadProgress > 0 && (
          <div className="mb-4">
            <div className="w-full bg-white/20 rounded-full h-2.5">
              <div 
                className="bg-blue-500 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-white/80 text-sm mt-1">Uploading: {uploadProgress}%</p>
          </div>
        )}

        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate('/profile')}
            className="flex items-center space-x-2 text-white hover:text-white/80 transition-colors bg-white/10 px-4 py-2 rounded-lg"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            <span>{t('privatePhotos.buttons.back')}</span>
          </button>
        </div>

        <div className="flex items-center space-x-4">
          <button
            onClick={handleShareClick}
            className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors"
          >
            {t('privatePhotos.buttons.shareGallery')}
          </button>

          <button
            onClick={() => setIsSharedUsersModalOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            {t('privatePhotos.buttons.manageAccess')}
          </button>

          <label className="cursor-pointer bg-white text-purple-600 px-4 py-2 rounded-lg hover:bg-white/90 transition-colors">
            {t('privatePhotos.buttons.addPhoto')}
            <input
              type="file"
              accept="image/jpeg,image/png,image/gif"
              onChange={handlePhotoUpload}
              className="hidden"
            />
          </label>
        </div>

        {showShareModal && (
          <ShareGalleryModal
            isOpen={showShareModal}
            onClose={() => setShowShareModal(false)}
          />
        )}

        <SharedUsersModal
          isOpen={isSharedUsersModalOpen}
          onClose={() => setIsSharedUsersModalOpen(false)}
        />

        <ImageViewerModal
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      </div>
    </div>
  );
}

export default PrivatePhotos;