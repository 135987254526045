import { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axiosInstance from '../../axios';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useWebSocket } from '../../contexts/WebSocketContext';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import PremiumBadge from '../common/PremiumBadge';
import { Squares2X2Icon as ViewGrid4, ViewColumnsIcon as ViewGrid6 } from '@heroicons/react/24/solid';

function Browse() {
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [myInterests, setMyInterests] = useState([]);
  const [gridLayout, setGridLayout] = useState(window.innerWidth >= 768 ? '4' : '1'); // Start with 1 column on mobile, 4 on desktop
  const [filters, setFilters] = useState({
    gender: '',
    minAge: '',
    maxAge: '',
    location: ''
  });
  const { onlineUsers, requestOnlineUsers } = useWebSocket();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      
      // Update grid layout based on screen size
      setGridLayout(prev => {
        if (mobile) {
          // On mobile: keep 1 or 2
          return prev === '1' || prev === '2' ? prev : '1';
        } else {
          // On desktop: keep 4 or 6
          return prev === '4' || prev === '6' ? prev : '4';
        }
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  useEffect(() => {
    requestOnlineUsers();
  }, [requestOnlineUsers]);

  // Effect for fetching users when filters change
  useEffect(() => {
    fetchUsers().catch(err => {
      console.error('Error in fetchUsers effect:', err);
      setError(err.response?.data?.message || 'Failed to load users');
    });
  }, [filters]);

  // Effect for fetching interests
  useEffect(() => {
    fetchMyInterests().catch(err => {
      console.error('Error in fetchMyInterests effect:', err);
      setError(err.response?.data?.message || 'Failed to load interests');
    });
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No authentication token found');
        setError('Please log in again');
        navigate('/login');
        return;
      }

      console.log('Fetching users with filters:', filters);
      const response = await axiosInstance.get('/api/profile', {
        params: filters
      });

      if (!response.data) {
        console.error('No data received from server');
        setError('No users found');
        return;
      }

      console.log('Response data:', response.data);
      setUsers(response.data);
      setError(''); // Clear any previous errors
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.response) {
        console.error('Response error:', error.response.data);
        if (error.response.status === 401) {
          setError('Session expired. Please log in again.');
          navigate('/login');
        } else if (error.response.status === 403) {
          setError('Access restricted. Please upgrade your membership to view profiles.');
          navigate('/membership');
        } else {
          setError(error.response.data.message || 'Failed to load users');
        }
      } else if (error.request) {
        console.error('Request error:', error.request);
        setError('Network error. Please check your connection.');
      } else {
        console.error('Error:', error.message);
        setError('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchMyInterests = async () => {
    try {
      if (!currentUser?._id) {
        console.log('No current user ID available, skipping interests fetch');
        return;
      }
      
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/api/profile/${currentUser._id}/interests`);
      setMyInterests(response.data);
    } catch (error) {
      console.error('Error fetching interests:', error);
    }
  };

  const handleShowInterest = async (userId) => {
    try {
      // Prevent showing interest in your own profile
      if (userId === currentUser._id) {
        console.error('Cannot show interest in your own profile');
        setError('You cannot show interest in your own profile');
        return;
      }

      const token = localStorage.getItem('token');
      if (myInterests.includes(userId)) {
        // Remove interest
        await axiosInstance.delete(`/api/profile/interest/${userId}`);
        // Update local state
        setMyInterests(prev => prev.filter(id => id !== userId));
      } else {
        // Show interest
        await axiosInstance.post(`/api/profile/interest/${userId}`, {});
        // Update local state
        setMyInterests(prev => [...prev, userId]);
      }
    } catch (error) {
      console.error('Error toggling interest:', error);
      setError('Failed to update interest');
    }
  };

  // Add this function to handle message click
  const handleMessageClick = (e, userId) => {
    e.stopPropagation(); // Prevent navigation to profile
    console.log('Navigating to messages with userId:', userId); // Debug log
    navigate(`/messages/${userId}`); // Remove 'new' from the path
  };

  // Default placeholder image as data URL - a simple gray avatar
  const DEFAULT_AVATAR = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgZmlsbD0iI2U1ZTdlYiIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjcwIiByPSI1MCIgZmlsbD0iI2E3YWFhZCIvPjxjaXJjbGUgY3g9IjEwMCIgY3k9IjIzMCIgcj0iMTAwIiBmaWxsPSIjYTdhYWFkIi8+PC9zdmc+';

  // Try to get a JPEG/PNG version of the image URL
  const getImageUrl = (url) => {
    if (!url) return DEFAULT_AVATAR;
    
    // For HEIC files, try to load JPEG/PNG versions
    if (url.toLowerCase().endsWith('.heic')) {
      return url.replace(/\.heic$/i, '.jpg');
    }
    
    const baseUrl = process.env.NODE_ENV === 'development' ? '' : 'https://thaimeetup.com';
    return url.startsWith('http') ? url : `${baseUrl}${url}`;
  };

  // User card component
  const UserCard = ({ user }) => {
    return (
      <motion.div variants={itemVariants}>
        <div 
          className={`bg-white rounded-xl shadow-lg overflow-hidden cursor-pointer transform transition-all duration-300 hover:shadow-xl hover:-translate-y-1 
            ${gridLayout === '6' ? 'text-sm' : ''}`}
        >
          <div className="relative aspect-square group cursor-pointer">
            <img
              src={getImageUrl(user.profile?.profilePicture)}
              alt={user.profile.name}
              className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              onClick={() => navigate(`/profile/${user._id}`)}
              onError={(e) => {
                console.error('Image load error:', e.target.src);
                e.target.onerror = null;
                e.target.src = DEFAULT_AVATAR;
              }}
            />
            
            {/* Online Status Indicator */}
            {onlineUsers.has(user._id) && (
              <div className={`absolute top-3 right-3 flex items-center space-x-1 bg-green-500/90 backdrop-blur-sm text-white 
                text-sm md:text-xs px-2 py-1 rounded-full shadow-lg z-10 ${gridLayout === '6' ? 'md:text-[10px] md:px-1.5 md:py-0.5' : ''}`}>
                <span className="w-2 h-2 bg-white rounded-full animate-pulse"></span>
                <span>{t('browse.online')}</span>
              </div>
            )}

            {/* Premium Badge */}
            {user.membership?.type === 'premium' && (
              <div className={`absolute top-3 left-3 ${gridLayout === '6' ? 'md:scale-75 md:origin-left' : ''}`}>
                <PremiumBadge />
              </div>
            )}

            {/* User Info Overlay */}
            <div className={`absolute bottom-0 left-0 right-0 p-4 
              bg-gradient-to-t from-black/80 via-black/50 to-transparent text-white
              ${gridLayout === '6' ? 'md:p-2' : ''}`}>
              <h3 className={`font-semibold text-lg md:text-base truncate ${gridLayout === '6' ? 'md:text-sm' : ''}`}>
                {user.profile.name}
              </h3>
              <p className={`text-base md:text-sm opacity-90 ${gridLayout === '6' ? 'md:text-xs' : ''}`}>
                {user.profile.age} • {user.profile.location}
              </p>
            </div>
          </div>
          <div className="p-4">
            <div className="flex space-x-2">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleShowInterest(user._id);
                }}
                className={`flex-1 px-2 py-1 md:px-4 md:py-2 rounded-lg transition-all duration-300 ${
                  myInterests.includes(user._id)
                    ? 'bg-green-500 hover:bg-red-500 text-white shadow-green-200'
                    : 'bg-blue-500 hover:bg-blue-600 text-white shadow-blue-200'
                } shadow-lg transform hover:-translate-y-0.5`}
              >
                {myInterests.includes(user._id) ? (
                  <div className="flex flex-col items-center justify-center text-xs md:text-sm">
                    <span className="flex items-center">
                      {t('browse.interests.shown')}
                      <motion.span 
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="ml-2"
                      >
                        ✓
                      </motion.span>
                    </span>
                    <span className="text-xs md:text-sm opacity-80">{t('browse.interests.undo')}</span>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center text-xs md:text-sm">
                    <span>{t('browse.interests.show')}</span>
                    <span className="text-xs md:text-sm opacity-80">{t('browse.interests.match')}</span>
                  </div>
                )}
              </motion.button>
              
              {/* Message Button */}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={(e) => handleMessageClick(e, user._id)}
                className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-5 w-5" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path 
                    fillRule="evenodd" 
                    d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" 
                    clipRule="evenodd" 
                  />
                </svg>
              </motion.button>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  if (loading) return <div className="text-center py-8">{t('browse.loading')}</div>;
  if (error) return <div className="text-red-500 text-center py-8">{error}</div>;

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500">
      <motion.div 
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="max-w-7xl mx-auto px-4 py-8"
      >
        <motion.div 
          variants={itemVariants}
          className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-6 mb-8"
        >
          {/* Layout Toggle Buttons - At the top */}
          <div className="flex justify-between items-center mb-6">
            <motion.div 
              className="text-white"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <motion.h1 
                className="text-3xl font-bold mb-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                {t('browse.title')}
              </motion.h1>
              <motion.p 
                className="text-lg opacity-90"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {t('browse.subtitle')}
              </motion.p>
            </motion.div>

            <div className="flex space-x-2">
              <button
                onClick={() => {
                  const isMobile = window.innerWidth < 768;
                  setGridLayout(isMobile ? '1' : '4');
                }}
                className={`p-2 rounded-lg transition-all ${
                  gridLayout === '1' || gridLayout === '4'
                    ? 'bg-white text-purple-600'
                    : 'bg-white/10 text-white hover:bg-white/20'
                }`}
                aria-label="Switch to compact layout"
              >
                <ViewGrid4 className="w-5 h-5" />
              </button>
              <button
                onClick={() => {
                  const isMobile = window.innerWidth < 768;
                  setGridLayout(isMobile ? '2' : '6');
                }}
                className={`p-2 rounded-lg transition-all ${
                  gridLayout === '2' || gridLayout === '6'
                    ? 'bg-white text-purple-600'
                    : 'bg-white/10 text-white hover:bg-white/20'
                }`}
                aria-label="Switch to expanded layout"
              >
                <ViewGrid6 className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Filters with updated styling */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <motion.div 
              className="space-y-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
            >
              <label className="block text-sm font-medium text-white">{t('auth.register.gender')}</label>
              <select
                name="gender"
                value={filters.gender}
                onChange={handleFilterChange}
                className="w-full p-2 border border-white/20 rounded-lg bg-white/10 text-white placeholder-white/60
                         focus:ring-2 focus:ring-white/50 focus:border-white/50 transition-all backdrop-blur-sm"
              >
                <option value="" className="text-gray-900">{t('browse.filters.all')}</option>
                <option value="Male" className="text-gray-900">{t('auth.register.genderOptions.male')}</option>
                <option value="Female" className="text-gray-900">{t('auth.register.genderOptions.female')}</option>
                <option value="Ladyboy" className="text-gray-900">{t('auth.register.genderOptions.ladyboy')}</option>
              </select>
            </motion.div>

            {/* Rest of the filters with similar animations */}
            <motion.div 
              className="space-y-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5 }}
            >
              <label className="block text-sm font-medium text-white">{t('browse.filters.minAge')}</label>
              <input
                type="number"
                name="minAge"
                value={filters.minAge}
                onChange={handleFilterChange}
                className="w-full p-2 border border-white/20 rounded-lg bg-white/10 text-white placeholder-white/60
                         focus:ring-2 focus:ring-white/50 focus:border-white/50 transition-all"
                min="18"
                placeholder="18+"
              />
            </motion.div>

            <motion.div 
              className="space-y-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6 }}
            >
              <label className="block text-sm font-medium text-white">{t('browse.filters.maxAge')}</label>
              <input
                type="number"
                name="maxAge"
                value={filters.maxAge}
                onChange={handleFilterChange}
                className="w-full p-2 border border-white/20 rounded-lg bg-white/10 text-white placeholder-white/60
                         focus:ring-2 focus:ring-white/50 focus:border-white/50 transition-all"
                min="18"
                placeholder="Any"
              />
            </motion.div>

            <motion.div 
              className="space-y-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.7 }}
            >
              <label className="block text-sm font-medium text-white">{t('browse.filters.location')}</label>
              <input
                type="text"
                name="location"
                value={filters.location}
                onChange={handleFilterChange}
                className="w-full p-2 border border-white/20 rounded-lg bg-white/10 text-white placeholder-white/60
                         focus:ring-2 focus:ring-white/50 focus:border-white/50 transition-all"
                placeholder="Enter location..."
              />
            </motion.div>
          </div>
        </motion.div>

        {/* User Grid - Different columns for mobile and desktop */}
        <div className={`grid gap-4 sm:gap-6 ${
          window.innerWidth < 768
            ? gridLayout === '1' 
              ? 'grid-cols-1' 
              : 'grid-cols-2'
            : gridLayout === '4'
              ? 'grid-cols-4'
              : 'grid-cols-6'
        }`}>
          {users.map((user) => (
            <UserCard key={user._id} user={user} />
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default Browse; 