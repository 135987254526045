import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import EmojiPicker from 'emoji-picker-react';
import { useWebSocket } from '../../contexts/WebSocketContext';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PremiumBadge from '../common/PremiumBadge';
import TranslationPanel from './TranslationPanel';
import { useTranslation } from 'react-i18next';

const sendSound = new Audio('/sounds/send-message.mp3');

const PremiumOverlay = ({ onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="bg-white/95 rounded-xl shadow-2xl p-6 max-w-sm backdrop-blur-sm border border-pink-400/20 animate-fade-in">
      <div className="text-center">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">Want to Continue Chatting?</h3>
        <p className="text-gray-600 mb-4">Upgrade to Premium to see all messages!</p>
        <div className="space-y-3">
          <Link
            to="/upgrade"
            className="block bg-pink-100 text-black font-semibold px-6 py-3 rounded-lg text-center hover:bg-pink-200 transition-all transform hover:scale-105 w-full"
          >
            ✨ Upgrade to Premium
          </Link>
          {onClose && (
            <button
              onClick={onClose}
              className="block w-full text-gray-500 hover:text-gray-700 px-6 py-2 rounded-lg font-medium transition-all"
            >
              Maybe Later
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
);

function Conversation() {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [partner, setPartner] = useState(null);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showTranslation, setShowTranslation] = useState(false);
  const [showTranslationGuide, setShowTranslationGuide] = useState(true);
  const [showTranslator, setShowTranslator] = useState(false);
  const [translateAll, setTranslateAll] = useState(false);
  const [translatedMessages, setTranslatedMessages] = useState({});
  const [translating, setTranslating] = useState(false);
  const [translatedInput, setTranslatedInput] = useState('');
  const { userId } = useParams();
  const { currentUser, isPremium, isFemale, isLadyboy } = useAuth();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showPremiumOverlay, setShowPremiumOverlay] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const webSocket = useWebSocket();
  const socket = webSocket?.socket;
  const navigate = useNavigate();
  const [onlineUsers] = useState(new Set());
  const [isTyping, setIsTyping] = useState(false);

  // Check if user has full access (Female, Ladyboy, or Premium)
  const hasFullAccess = isPremium || isFemale || isLadyboy;

  // Function to determine if a message should be blurred
  const shouldBlurMessage = (messageIndex) => {
    return !hasFullAccess && messageIndex >= 6;
  };

  // Debug logging
  useEffect(() => {
    console.log('Current User Data:', {
      id: currentUser?._id,
      email: currentUser?.email,
      gender: currentUser?.profile?.gender,
      membershipType: currentUser?.membership?.type,
      isPremium,
      isFemale,
      isLadyboy,
      hasFullAccess
    });
  }, [currentUser, isPremium, isFemale, isLadyboy, hasFullAccess]);

  useEffect(() => {
    // Show overlay and announcement when messages > 6 and user doesn't have full access
    if (!hasFullAccess && messages.length === 6) {
      setShowPremiumOverlay(true);
      setShowAnnouncement(true);
    }
  }, [messages.length, hasFullAccess]);

  // Contact information patterns
  const CONTACT_PATTERNS = {
    consecutiveNumbers: /\d{3,}/g,  // Match 3 or more consecutive digits anywhere
    phone: /(?:^|\s|[^\d])(\+?\d{1,3}[-.\s]?\d{3,4}[-.\s]?\d{3,4}[-.\s]?\d{3,4})(?:$|\s|[^\d])/g,
    // WhatsApp with all possible variations, typos, and better word boundaries
    whatsapp: /(?:\b|^)(?:w(?:h|a|u)?(?:h|a|u)?(?:a|u)?(?:h|t|d)?[t|d]?s?[\s'-]*(?:a|ua|u|o|uo|ou|ao|oa|e|ea|ae|oe|eo|a+)?[\s'-]*(?:ap+s?|ap+(?:ps?)?|aap+s?|ep+s?|up+s?))(?:\b|$)/gi,
    line: /(?:^|\s|[^\w])(line\s*id|line\s*messenger|line\s*chat|line\s*app|(?:add|contact|message|msg|pm|dm)\s+(?:me\s+)?(?:on|in|at|via|through|by)?\s+line)(?:$|\s|[^\w])/gi,
    telegram: /(?:^|\s)(t\.me\/|telegram\.me\/|telegram\/\/|telegram)/gi,
    email: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
  };

  // Function to filter contact information with normalization
  const filterContactInfo = (content) => {
    if (!content) return content;
    let filteredContent = content;
    
    // First filter consecutive numbers
    filteredContent = filteredContent.replace(CONTACT_PATTERNS.consecutiveNumbers, match => '*'.repeat(match.length));
    
    // Then filter other patterns
    Object.entries(CONTACT_PATTERNS).forEach(([key, pattern]) => {
      if (key !== 'consecutiveNumbers') {
        filteredContent = filteredContent.replace(pattern, (match, group1) => {
          // If there's a captured group, only replace that part
          if (group1) {
            return match.replace(group1, '*'.repeat(group1.length));
          }
          return '*'.repeat(match.length);
        });
      }
    });
    
    return filteredContent;
  };

  useEffect(() => {
    console.log('Current User Data:', {
      id: currentUser?._id,
      email: currentUser?.email,
      gender: currentUser?.profile?.gender,
      membershipType: currentUser?.membership?.type,
      hasFullAccess
    });
  }, [currentUser, hasFullAccess]);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      setError('');
      
      const response = await axios.get(`/api/messages/conversation/${userId}`);
      console.log('Raw messages:', response.data);
      
      const formattedMessages = Array.isArray(response.data) 
        ? response.data
            .map(formatMessage)
            .filter(Boolean)
        : [];
        
      console.log('Formatted messages:', formattedMessages);
      setMessages(formattedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError(error.response?.data?.message || 'Failed to load messages');
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchPartnerInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/profile/${userId}`, {
        headers: { 'x-auth-token': token }
      });
      console.log('Partner info response:', response.data);
      console.log('Partner profile picture:', response.data.profile.profilePicture);
      setPartner(response.data);
    } catch (error) {
      console.error('Error fetching partner info:', error);
      setError('Failed to load partner information');
    }
  };

  const markMessagesAsRead = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put('/api/messages/read-all', {}, {
        headers: { 'x-auth-token': token }
      });
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const formatMessage = (message) => {
    // Handle case where message is undefined or null
    if (!message) return null;

    // Basic message structure
    const formatted = {
      _id: message._id || '',
      content: message.content || '',
      createdAt: message.createdAt ? new Date(message.createdAt) : new Date(),
      requiresPremium: message.requiresPremium || false
    };

    // Handle sender info
    formatted.sender = {
      _id: typeof message.sender === 'object' ? message.sender._id : message.sender,
      profile: {
        name: message.sender?.profile?.name || 'Unknown User',
        profilePicture: message.sender?.profile?.profilePicture
      }
    };

    // Handle recipient info
    formatted.recipient = {
      _id: typeof message.recipient === 'object' ? message.recipient._id : message.recipient,
      profile: {
        name: message.recipient?.profile?.name || 'Unknown User',
        profilePicture: message.recipient?.profile?.profilePicture
      }
    };

    // Star out phone numbers for free male users regardless of sender
    if (!hasFullAccess) {
      // Phone number regex patterns
      const phonePatterns = [
        /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/g,  // 123-456-7890
        /\b\d{10}\b/g,                      // 1234567890
        /\b\+\d{1,3}[-.]?\d{3}[-.]?\d{3}[-.]?\d{4}\b/g,  // +1-123-456-7890
        /\b\+\d{11,12}\b/g,                 // +11234567890
        /\b\(\d{3}\)[-.]?\d{3}[-.]?\d{4}\b/g  // (123)456-7890
      ];

      let formattedContent = formatted.content;
      phonePatterns.forEach(pattern => {
        formattedContent = formattedContent.replace(pattern, match => '*'.repeat(match.length));
      });
      formatted.content = formattedContent;
    }

    return formatted;
  };

  // Initial fetch only happens once
  useEffect(() => {
    if (!currentUser || !userId) return;
    fetchMessages();
  }, [userId, currentUser]);

  // WebSocket message handling
  useEffect(() => {
    if (!webSocket?.socket || !currentUser || !userId) {
      console.log('Missing required data for WebSocket setup');
      return;
    }

    const socket = webSocket.socket;

    // If socket exists but not connected, try to reconnect
    if (!socket.connected && webSocket.connect) {
      console.log('Socket not connected, attempting to reconnect');
      webSocket.connect();
      return;
    }

    // Create a deterministic room name by sorting IDs
    const ids = [currentUser._id, userId].sort();
    const room = `chat_${ids[0]}_${ids[1]}`;
    socket.emit('join', { room });

    // Keep track of message IDs to prevent duplicates
    const messageIds = new Set();

    // Listen for new messages
    socket.on('newMessage', (newMessage) => {
      setMessages(prev => {
        // If this is a message we sent (optimistic update), replace the temporary one
        const optimisticIndex = prev.findIndex(m => 
          m.isOptimistic && 
          m.content === newMessage.content && 
          Math.abs(new Date(m.createdAt) - new Date(newMessage.createdAt)) < 5000
        );

        if (optimisticIndex !== -1) {
          const newMessages = [...prev];
          newMessages[optimisticIndex] = formatMessage(newMessage);
          return newMessages;
        }

        // If we've already seen this message ID, don't add it again
        if (messageIds.has(newMessage._id)) {
          return prev;
        }

        // Add the message ID to our set
        messageIds.add(newMessage._id);
        return [...prev, formatMessage(newMessage)].filter(Boolean);
      });
    });

    // Listen for typing status
    socket.on('typing', (data) => {
      if (data.userId === userId) {
        setIsTyping(data.isTyping);
      }
    });

    // Listen for message errors
    socket.on('messageError', (error) => {
      console.error('Message error:', error);
      toast.error(t('messages.messageError'));
    });

    // Cleanup listeners and leave room
    return () => {
      socket.emit('leave', { room });
      socket.off('newMessage');
      socket.off('typing');
      socket.off('messageError');
    };
  }, [webSocket?.socket, currentUser, userId, webSocket?.connect]);

  // Handle sending messages
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!newMessage.trim()) {
      return;
    }

    if (!webSocket?.socket) {
      console.log('No socket available, attempting to reconnect');
      webSocket?.connect?.();
      toast.error(t('messages.connectionLost'));
      return;
    }

    if (!webSocket.socket.connected) {
      console.log('Socket not connected, attempting to reconnect');
      webSocket.connect();
      toast.error(t('messages.connectionLost'));
      return;
    }

    if (!currentUser || !userId) {
      console.log('Missing user data');
      return;
    }

    const messageContent = newMessage.trim();
    setNewMessage('');

    try {
      // Create message object
      const newMessageObj = {
        content: messageContent,
        createdAt: new Date().toISOString()
      };

      // Get socket reference
      const socket = webSocket.socket;
      if (!socket || !socket.connected) {
        throw new Error('WebSocket connection not available');
      }

      // Optimistically add message to UI
      const optimisticMessage = {
        _id: `temp_${Date.now()}`, // Temporary ID
        content: messageContent,
        sender: {
          _id: currentUser._id,
          profile: currentUser.profile
        },
        recipient: {
          _id: userId,
          profile: partner?.profile
        },
        createdAt: new Date().toISOString(),
        isOptimistic: true // Mark as optimistic update
      };

      setMessages(prev => [...prev, optimisticMessage]);

      // Create deterministic room name
      const ids = [currentUser._id, userId].sort();
      const room = `chat_${ids[0]}_${ids[1]}`;
      socket.emit('sendMessage', {
        room,
        message: newMessageObj
      });

      // Play send sound
      sendSound.play().catch(() => {});
    } catch (error) {
      console.error('Failed to send message:', error);
      toast.error(t('messages.messageError'));
      
      // Retry connection if socket is null or disconnected
      if (!webSocket?.socket?.connected) {
        webSocket?.connect?.();
      }
    }
  };

  // Handle typing status
  useEffect(() => {
    if (!webSocket?.socket || !userId) return;

    const socket = webSocket.socket;
    let typingTimeout;

    if (newMessage) {
      socket.emit('typing', { userId, isTyping: true });
      
      // Clear previous timeout
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set new timeout
      typingTimeout = setTimeout(() => {
        socket.emit('typing', { userId, isTyping: false });
      }, 2000);
    } else {
      socket.emit('typing', { userId, isTyping: false });
    }

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [newMessage, webSocket?.socket, userId]);

  // Initialize conversation
  useEffect(() => {
    const initConversation = async () => {
      await fetchPartnerInfo();
      await markMessagesAsRead();
    };

    initConversation();
  }, [userId]);

  const onEmojiClick = (emojiObject) => {
    setNewMessage(prev => prev + emojiObject.emoji);
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleOverlayClose = () => {
    setShowPremiumOverlay(false);
  };

  // Scroll to bottom function
  const scrollToBottom = (behavior = 'smooth') => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior });
    }
  };

  // Handle scroll events to track if we're at bottom
  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const isBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;
      setIsScrolledToBottom(isBottom);
    }
  };

  // Scroll to bottom on new messages if we were already at bottom
  useEffect(() => {
    if (isScrolledToBottom) {
      scrollToBottom();
    }
  }, [messages]);

  // Initial scroll to bottom and scroll event listener
  useEffect(() => {
    scrollToBottom('instant');
    const container = messagesContainerRef.current;
    
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // Add scroll button if not at bottom
  const ScrollToBottomButton = () => (
    <motion.button
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      onClick={() => scrollToBottom()}
      className={`
        fixed bottom-24 right-8 z-10 p-3 rounded-full 
        bg-gradient-to-r from-pink-500 to-purple-600 text-white
        shadow-lg hover:shadow-xl transition-all transform hover:scale-105
        ${isScrolledToBottom ? 'hidden' : 'flex items-center justify-center'}
      `}
    >
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
      </svg>
    </motion.button>
  );

  const handleMessageClick = (message) => {
    if (!message.content) return;
    
    console.log('Opening translation panel for message:', message);
    setSelectedMessage(message.content);
    setShowTranslator(true);
  };

  const handleSendTranslation = (translatedText) => {
    console.log('Sending message:', translatedText);
    if (translatedText?.trim()) {
      setNewMessage(translatedText);
      handleSubmit({ preventDefault: () => {} });
    }
    setShowTranslator(false);
    setSelectedMessage(null);
  };

  const handleToggleTranslateAll = useCallback((enabled) => {
    console.log('handleToggleTranslateAll called', { enabled });
    setTranslateAll(enabled);
    if (enabled && messages?.length > 0) {
      console.log('Starting translation of all messages', { messagesCount: messages.length });
      handleTranslateAll(messages);
    } else {
      console.log('Clearing translations');
      setTranslatedMessages({});
    }
  }, [messages]);

  const handleTranslateAll = async (messages) => {
    try {
      console.log('handleTranslateAll started', { messagesCount: messages.length });
      const newTranslations = { ...translatedMessages };
      
      for (const msg of messages) {
        if (!newTranslations[msg._id] && msg.content) {
          try {
            console.log('Translating message', { messageId: msg._id });
            // Detect language
            const detectResponse = await axios.post('https://thaimeetup.com/api/translation/detect', {
              text: msg.content
            });

            if (detectResponse.data?.language) {
              const targetLang = detectResponse.data.language === 'th' ? 'en' : 'th';
              console.log('Language detected', { 
                messageId: msg._id, 
                sourceLang: detectResponse.data.language,
                targetLang 
              });
              
              // Translate
              const translateResponse = await axios.post('https://thaimeetup.com/api/translation', {
                text: msg.content,
                targetLang,
                sourceLang: detectResponse.data.language
              });

              if (translateResponse.data?.translatedText) {
                console.log('Translation successful', { 
                  messageId: msg._id,
                  translation: translateResponse.data.translatedText 
                });
                newTranslations[msg._id] = translateResponse.data.translatedText;
                // Update state after each successful translation to show progress
                setTranslatedMessages({ ...newTranslations });
              }
            }
          } catch (err) {
            console.error('Translation error for message:', msg._id, err);
            toast.error(t('messages.translationError'));
          }
          // Add a small delay between translations to avoid rate limiting
          await new Promise(resolve => setTimeout(resolve, 100));
        }
      }
    } catch (err) {
      console.error('Translation error:', err);
      toast.error(t('messages.translationError'));
    }
  };

  const renderMessageContent = (message) => {
    if (!message.content) return '';
    return message.content;
  };

  const handleTranslateInput = async () => {
    if (!newMessage.trim()) return;
    
    try {
      setTranslating(true);
      
      const detectResponse = await axios.post('/api/translation/detect', {
        text: newMessage
      });

      if (!detectResponse.data?.language) {
        throw new Error(t('translation.error'));
      }

      const detectedLang = detectResponse.data.language;
      const targetLang = detectedLang === 'th' ? 'en' : 'th';

      const translateResponse = await axios.post('/api/translation', {
        text: newMessage,
        targetLang,
        sourceLang: detectedLang
      });

      if (!translateResponse.data?.translatedText) {
        throw new Error(t('translation.error'));
      }

      setNewMessage(translateResponse.data.translatedText);
      setTranslatedInput(newMessage); // Store original text
      toast.success(t('translation.success'));
    } catch (err) {
      console.error('Translation error:', err);
      toast.error(t('translation.error'));
    } finally {
      setTranslating(false);
    }
  };

  const handleUndoTranslation = () => {
    if (translatedInput) {
      setNewMessage(translatedInput);
      setTranslatedInput('');
    }
  };

  if (loading) return <div className="text-center py-8">{t('messages.loading')}</div>;
  if (error) return <div className="text-red-500 text-center py-8">{error}</div>;

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 pt-2 px-4 pb-4">
      <div className="container max-w-4xl mx-auto">
        <div className="text-center mb-4">
          <div className="inline-flex items-center justify-center p-3 bg-white/10 backdrop-blur-sm rounded-full mb-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 1c-6.627 0-12 4.208-12 9.4 0 3.131 2.024 5.927 5.216 7.585.522.31.72 1.146.282 1.725-.648.854-1.674 1.642-2.498 2.29 2.644-1.02 3.562-2.385 3.562-2.385.522.31 1.13.562 1.773.752.484.143.993.261 1.515.339.586.089 1.193.135 1.81.135 6.627 0 12-4.208 12-9.4s-5.373-9.4-12-9.4zm0 2c5.514 0 10 3.297 10 7.4s-4.486 7.4-10 7.4c-.585 0-1.162-.044-1.721-.127-.428-.063-.848-.145-1.256-.255-.525-.144-1.032-.329-1.512-.548l-2.011.675c.691-.712 1.3-1.438 1.647-2.096.441-.658.394-1.495-.128-2.05-2.713-1.438-4.419-3.907-4.419-6.699 0-4.103 4.486-7.4 10-7.4zm-3.5 7.4c-.828 0-1.5-.448-1.5-1s.672-1 1.5-1 1.5.448 1.5 1-.672 1-1.5 1zm3.5 0c-.828 0-1.5-.448-1.5-1s.672-1 1.5-1 1.5.448 1.5 1-.672 1-1.5 1zm3.5 0c-.828 0-1.5-.448-1.5-1s.672-1 1.5-1 1.5.448 1.5 1-.672 1-1.5 1z"/>
            </svg>
          </div>
          <h1 className="text-4xl font-bold text-white mb-3">{t('chat.liveChat')}</h1>
          <p className="text-white/80 text-lg max-w-lg mx-auto leading-relaxed">
            {t('messages.conversationFlowing')}
          </p>
        </div>

        <div className="bg-white/10 backdrop-blur-sm rounded-2xl shadow-xl overflow-hidden">
          {partner && (
            <div className="flex items-center justify-between p-4 border-b border-white/10">
              <div className="flex items-center">
                <div className="relative">
                  <Link 
                    to={`/profile/${partner._id}`}
                    className="block transition-transform hover:scale-105 hover:shadow-lg rounded-full"
                  >
                    <img
                      src={partner.profile.profilePicture || '/default-avatar.png'}
                      alt={partner.profile.name}
                      className="w-14 h-14 rounded-full border-2 border-white/20 object-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/default-avatar.png';
                      }}
                    />
                  </Link>
                  {onlineUsers.has(partner._id) && (
                    <span className="absolute bottom-0 right-0 w-4 h-4 bg-green-400 border-2 border-white rounded-full animate-pulse" />
                  )}
                </div>
                <div className="ml-3 flex-1">
                  <h2 className="text-xl font-semibold text-white flex items-center">
                    {partner.profile.name}
                    {partner.membership?.type === 'premium' && <PremiumBadge className="text-yellow-300" />}
                  </h2>
                  {isTyping && (
                    <div className="flex items-center gap-1 text-white/70 text-sm mt-1">
                      <span>{t('messages.typing')}</span>
                      <motion.span
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ repeat: Infinity, duration: 0.5 }}
                      >
                        ...
                      </motion.span>
                    </div>
                  )}
                </div>
              </div>
              <button
                onClick={() => navigate('/messages')}
                className="text-white/70 hover:text-white/90 transition-colors p-2 hover:bg-white/10 rounded-full"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          )}

          <div className="flex-1 relative">
            {/* Translation Guide */}
            <AnimatePresence>
              {showTranslationGuide && (
                <div className="fixed inset-x-0 bottom-24 flex justify-center z-50">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-6 py-3 rounded-full shadow-lg flex items-center gap-3 mx-auto"
                  >
                    <span className="text-xl">✨</span>
                    <span className="text-base font-medium">{t('messages.clickToTranslate')}</span>
                    <span className="text-xl">✨</span>
                    <button
                      onClick={() => setShowTranslationGuide(false)}
                      className="ml-2 bg-white/10 hover:bg-white/20 p-1.5 rounded-full transition-colors"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </motion.div>
                </div>
              )}
            </AnimatePresence>

            {/* Translation Panel */}
            <AnimatePresence>
              {showTranslation && selectedMessage && (
                <div className="fixed inset-x-0 bottom-24 flex justify-center z-50">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    className="w-[90%] max-w-2xl"
                  >
                    <TranslationPanel
                      message={selectedMessage}
                      onClose={() => {
                        setShowTranslation(false);
                        setSelectedMessage(null);
                      }}
                    />
                  </motion.div>
                </div>
              )}
            </AnimatePresence>

            {/* Translator */}
            <AnimatePresence>
              {showTranslator && selectedMessage && (
                <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
                  <TranslationPanel
                    message={selectedMessage}
                    onClose={() => setShowTranslator(false)}
                    onSendMessage={handleSendTranslation}
                    onToggleTranslateAll={handleToggleTranslateAll}
                    translateAll={translateAll}
                  />
                </div>
              )}
            </AnimatePresence>

            <div className="h-[calc(100vh-16rem)] overflow-y-auto overflow-x-hidden p-4 space-y-4" ref={messagesContainerRef}>
              {messages.map((message, index) => {
                if (!message || typeof message !== 'object') return null;
                
                const senderId = typeof message.sender === 'object' ? message.sender._id : message.sender;
                const isCurrentUser = currentUser?._id === senderId;
                const messageContent = typeof message.content === 'string' ? message.content : '';
                
                return (
                  <motion.div
                    key={message._id}
                    initial={{ opacity: 0, x: isCurrentUser ? 20 : -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.2 }}
                    className={`flex items-end gap-2 ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'}`}
                  >
                    <img
                      src={isCurrentUser 
                        ? (currentUser.profile?.profilePicture || '/default-avatar.png')
                        : (message.sender?.profile?.profilePicture || '/default-avatar.png')
                      }
                      alt={isCurrentUser ? currentUser.profile?.name : message.sender?.profile?.name}
                      className="w-8 h-8 rounded-full border-2 border-white/20 object-cover flex-shrink-0"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/default-avatar.png';
                      }}
                    />
                    <div className="relative max-w-[85%] min-w-[260px]">
                      <div 
                        onClick={() => handleMessageClick(message)}
                        className={`relative rounded-2xl p-3 shadow-md backdrop-blur-sm cursor-pointer transition-all hover:shadow-lg hover:scale-[1.02] ${
                          isCurrentUser
                            ? 'bg-gradient-to-br from-purple-500 to-purple-600 border-2 border-purple-400/30 ml-auto'
                            : 'bg-gradient-to-br from-blue-500 to-blue-600 border-2 border-blue-400/30 mr-auto'
                        }`}
                      >
                        <div className={`${shouldBlurMessage(index) ? 'blur-sm select-none' : ''}`}>
                          <div className="whitespace-pre-wrap break-words">
                            {hasFullAccess || message.sender._id === currentUser._id ? 
                              renderMessageContent(message) : 
                              filterContactInfo(messageContent)
                            }
                          </div>
                          <div className="text-white/60 text-xs mt-1">
                            {format(new Date(message.createdAt), 'h:mm a')}
                          </div>
                          {translateAll && translatedMessages[message._id] && (
                            <div className="text-white/60 text-sm mt-1">
                              {translatedMessages[message._id]}
                            </div>
                          )}
                        </div>
                        {shouldBlurMessage(index) && (
                          <div className="absolute inset-0 flex items-center justify-center text-center">
                            <div className="w-[90%] max-w-[280px] px-2">
                              <Link 
                                to="/upgrade" 
                                className="text-xs text-black font-semibold flex items-center justify-center gap-1 bg-pink-100 hover:bg-pink-200 px-3 py-2 rounded-full transition-all mx-auto"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <span>✨</span>
                                <span>{t('messages.upgradeToPremium')}</span>
                                <span>→</span>
                              </Link>
                            </div>
                          </div>
                        )}
                        <div 
                          className={`absolute -bottom-2 ${isCurrentUser ? '-right-6' : '-left-6'} w-3 h-3 transform rotate-45 ${
                            isCurrentUser
                              ? 'bg-gradient-to-br from-purple-500 to-purple-600 border-r-2 border-b-2 border-purple-400/30'
                              : 'bg-gradient-to-br from-blue-500 to-blue-600 border-l-2 border-b-2 border-blue-400/30'
                          }`}
                        />
                      </div>
                      {shouldBlurMessage(index) && (
                        <span className={`absolute ${isCurrentUser ? '-left-2' : '-right-2'} -top-2 text-base animate-bounce-gentle`}>
                          💎
                        </span>
                      )}
                    </div>
                  </motion.div>
                );
              })}
              <div ref={messagesEndRef} />
            </div>

            {/* Scroll to bottom button */}
            <AnimatePresence>
              {!isScrolledToBottom && <ScrollToBottomButton />}
            </AnimatePresence>

            <form onSubmit={handleSubmit} className="p-4 border-t border-white/10 bg-white/5">
              <div className="relative flex items-center">
                <button
                  type="button"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="absolute left-2 text-white/50 hover:text-white/80 transition-colors"
                >
                  😊
                </button>
                {showEmojiPicker && (
                  <div className="absolute bottom-full left-0 mb-2">
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                  </div>
                )}
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                    setTranslatedInput(''); // Clear translated state when input changes
                  }}
                  placeholder={t('messages.typeMessage')}
                  className="w-full py-2 px-12 bg-white/10 rounded-full text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
                />
                <button
                  type="button"
                  onClick={translatedInput ? handleUndoTranslation : handleTranslateInput}
                  disabled={!newMessage.trim() || translating}
                  className={`absolute right-12 top-1/2 -translate-y-1/2 ${
                    newMessage.trim() && !translating
                      ? 'text-white/90 hover:text-white'
                      : 'text-white/30 cursor-not-allowed'
                  } transition-colors`}
                  title={translatedInput ? t('messages.undoTranslation') : t('messages.translateMessage')}
                >
                  {translating ? (
                    <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : translatedInput ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M7 2a1 1 0 011 1v1h3a1 1 0 110 2H9.578a18.87 18.87 0 01-1.724 4.78c.29.354.596.696.914 1.026a1 1 0 11-1.44 1.389c-.188-.196-.373-.396-.554-.6a19.098 19.098 0 01-3.107 3.567 1 1 0 01-1.334-1.49 17.087 17.087 0 003.13-3.733 18.992 18.992 0 01-1.487-2.494 1 1 0 111.79-.89c.234.47.489.928.764 1.372.417-.934.752-1.913.997-2.927H3a1 1 0 110-2h3V3a1 1 0 011-1z" />
                    </svg>
                  )}
                </button>
                <button
                  type="submit"
                  disabled={!newMessage.trim()}
                  className="absolute right-2 top-1/2 -translate-y-1/2 text-white/90 hover:text-white disabled:text-white/30 disabled:cursor-not-allowed transition-colors"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                  </svg>
                </button>
              </div>
            </form>

            {showAnnouncement && (
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="relative bg-gradient-to-r from-pink-500 via-purple-500 to-pink-500 bg-size-200 animate-gradient-x p-3 text-center border-t border-white/10"
              >
                <div className="container mx-auto max-w-4xl flex items-center justify-center gap-4 text-white">
                  <span className="hidden md:inline">💫</span>
                  <p className="font-medium text-sm md:text-base animate-pulse">
                    {t('messages.letsKeepConversationFlowing')}
                  </p>
                  <span className="hidden md:inline">✨</span>
                  <Link 
                    to="/upgrade"
                    className="bg-white/20 hover:bg-white/30 px-4 py-1.5 rounded-full text-sm font-bold transition-all whitespace-nowrap"
                  >
                    {t('messages.upgradeNow')}
                  </Link>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showPremiumOverlay && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <PremiumOverlay onClose={handleOverlayClose} />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Translation Panel */}
      <AnimatePresence>
        {showTranslator && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          >
            <TranslationPanel
              message={selectedMessage}
              onClose={() => setShowTranslator(false)}
              onSendMessage={handleSendTranslation}
              onToggleTranslateAll={handleToggleTranslateAll}
              translateAll={translateAll}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export { Conversation as default };