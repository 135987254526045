import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config'; // Assuming config is in a separate file

function UserProfile() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [hasGalleryAccess, setHasGalleryAccess] = useState(false);
  const [hasShownInterest, setHasShownInterest] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
    checkGalleryAccess();
    checkInterestStatus();
  }, [id]);

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await axios.get(`${config.API_URL}/api/profile/${id}`, {
        headers: { 'x-auth-token': token }
      });
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to load user profile');
    } finally {
      setLoading(false);
    }
  };

  const checkGalleryAccess = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.API_URL}/api/private-photos/${id}/access`, {
        headers: { 'x-auth-token': token }
      });
      setHasGalleryAccess(response.data.hasAccess);
    } catch (error) {
      console.error('Error checking gallery access:', error);
    }
  };

  const checkInterestStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const [sentResponse, receivedResponse] = await Promise.all([
        axios.get(`${config.API_URL}/api/profile/interests/sent`, {
          headers: { 'x-auth-token': token }
        }),
        axios.get(`${config.API_URL}/api/profile/interests/received`, {
          headers: { 'x-auth-token': token }
        })
      ]);
      
      console.log('Interest check responses:', {
        sent: sentResponse.data,
        received: receivedResponse.data
      });
      
      // Convert IDs to strings for consistent comparison
      const hasSentInterest = sentResponse.data.some(user => user._id.toString() === id);
      const hasReceivedInterest = receivedResponse.data.some(user => user._id.toString() === id);
      
      console.log('Interest status:', {
        userId: id,
        hasSentInterest,
        hasReceivedInterest,
        isMatch: hasSentInterest && hasReceivedInterest
      });
      
      setHasShownInterest(hasSentInterest);
      setIsMatch(hasSentInterest && hasReceivedInterest);
    } catch (error) {
      console.error('Error checking interest status:', error);
    }
  };

  const toggleInterest = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (hasShownInterest) {
        await axios.delete(`${config.API_URL}/api/profile/interest/${id}`, {
          headers: { 'x-auth-token': token }
        });
      } else {
        const response = await axios.post(`${config.API_URL}/api/profile/interest/${id}`, {}, {
          headers: { 'x-auth-token': token }
        });
        console.log('Toggle interest response:', response.data);
      }
      
      // Refresh interest status
      await checkInterestStatus();
    } catch (error) {
      console.error('Error toggling interest:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden">
          {/* Profile Header */}
          <div className="p-6 space-y-6">
            {/* Profile Picture and Basic Info */}
            <div className="flex items-start space-x-6">
              {/* Profile Picture */}
              <div className="w-32 h-32 rounded-xl overflow-hidden flex-shrink-0 shadow-lg">
                <img
                  src={`${config.API_URL}${user.profile?.profilePicture}`}
                  alt={user.profile?.name}
                  className="w-full h-full object-cover"
                  onClick={() => setShowImageModal(true)}
                  style={{ cursor: 'pointer' }}
                />
              </div>

              {/* Basic Info */}
              <div className="flex-1">
                {/* Name and Age */}
                <h1 className="text-3xl font-bold text-white flex items-center">
                  {user.profile?.name}, {user.profile?.age}
                </h1>
                
                {/* Location */}
                <p className="text-white/80 mt-2">
                  <span className="font-semibold">📍</span> Location: {user.profile?.location}
                </p>
                
                {/* Gender and Looking For */}
                <div className="flex gap-4 mt-2">
                  <p className="text-white/80">
                    <span className="font-semibold">Gender:</span> {' '}
                    {user.profile?.gender}
                  </p>
                  <p className="text-white/80">
                    <span className="font-semibold">Looking For:</span> {' '}
                    {user.profile?.lookingFor}
                  </p>
                </div>
              </div>
            </div>

            {/* Bio Section - Full Width */}
            <div className="bg-white/5 rounded-lg p-4">
              <h3 className="text-white/90 font-semibold mb-2">About Me</h3>
              <p className="text-white/80 whitespace-pre-wrap">
                {user.profile?.bio || 'No bio provided'}
              </p>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="p-6 border-t border-white/10">
            <div className="flex gap-4">
              <button
                onClick={() => navigate(`/messages/${id}`)}
                className="w-1/2 py-2 px-4 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-all duration-200"
              >
                Message
              </button>
              <button
                onClick={toggleInterest}
                className={`w-1/2 py-2 px-4 rounded-lg transition-all duration-200 ${
                  hasShownInterest
                    ? 'bg-green-500 hover:bg-green-600'
                    : 'bg-blue-500 hover:bg-blue-600'
                } text-white`}
              >
                {hasShownInterest ? 'Interested ✓' : 'Show Interest'}
              </button>
            </div>
          </div>

          {/* Additional Pictures Grid */}
          {user.profile?.additionalPictures?.length > 0 && (
            <div className="p-6 border-t border-white/10">
              <h2 className="text-2xl font-semibold mb-4 text-white">Photos</h2>
              <div className="grid grid-cols-3 gap-4">
                {user.profile.additionalPictures.map((picture, index) => (
                  <div
                    key={index}
                    className="aspect-square cursor-pointer hover:opacity-90 transition-all duration-200"
                    onClick={() => setShowImageModal(true)}
                  >
                    <img
                      src={`${config.API_URL}${picture}`}
                      alt={`${user.profile?.name}'s photo ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Image Modal */}
          {showImageModal && (
            <div
              className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
              onClick={() => setShowImageModal(false)}
            >
              <div className="relative max-w-6xl max-h-[90vh] w-full">
                <button
                  className="absolute top-4 right-4 text-white text-xl bg-blue-400 hover:bg-blue-500 w-10 h-10 rounded-full flex items-center justify-center"
                  onClick={() => setShowImageModal(false)}
                >
                  ×
                </button>
                <img
                  src={`${config.API_URL}${user.profile?.profilePicture}`}
                  alt={user.profile?.name}
                  className="max-w-full max-h-[85vh] object-contain mx-auto"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

