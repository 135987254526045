import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import API_URL from '../../utils/apiConfig';
import { useTranslation } from 'react-i18next';

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Use the login function from AuthContext directly
      await login(formData.email, formData.password);
      navigate('/profile/edit');
    } catch (error) {
      console.error('Login error:', error.response?.data || error);
      setError(error.response?.data?.message || t('auth.login.errors.failed'));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 py-4 px-4">
      <div className="max-w-md mx-auto">
        <div className="bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-6">
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-2 text-white">{t('auth.login.title')} / เข้าสู่ระบบ 🌟</h2>
            <p className="text-white/80 mb-1">{t('auth.login.welcome')}</p>
            <p className="text-white/80 mb-6">ยินดีต้อนรับกลับมา! เริ่มการเดินทางของคุณ</p>
          </div>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-white mb-2">{t('auth.login.email')} / อีเมล</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-white/20 border-white/30 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/50"
                required
              />
            </div>

            <div>
              <label className="block text-white mb-2">{t('auth.login.password')} / รหัสผ่าน</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-2 border rounded bg-white/20 border-white/30 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/50"
                required
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-white/20 hover:bg-white/30 text-white p-2 rounded transition duration-200 border border-white/30 focus:outline-none focus:ring-2 focus:ring-white/50 disabled:opacity-50"
            >
              {loading ? t('auth.login.loading') + ' / กำลังเข้าสู่ระบบ...' : t('auth.login.submit') + ' / เข้าสู่ระบบ'}
            </button>
          </form>

          <div className="mt-4 text-center space-y-4">
            <p className="text-white/80">
              {t('auth.login.noAccount')} / ยังไม่มีบัญชี?{' '}
              <button
                type="button"
                onClick={() => navigate('/register')}
                className="text-white hover:text-blue-200 transition-colors underline"
              >
                {t('auth.login.signUp')} / ลงทะเบียนที่นี่
              </button>
            </p>
            <button
              type="button"
              onClick={() => navigate('/forgot-password')}
              className="text-white/80 hover:text-white transition-colors border border-white/30 rounded-lg px-4 py-2 text-sm w-full bg-white/10 hover:bg-white/20"
            >
              Forgot Password? / ลืมรหัสผ่าน?
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;