import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const FeatureGuide = ({ 
  children, 
  title, 
  description, 
  icon,
  position = 'bottom', // can be 'top', 'bottom', 'left', 'right'
  className = '' // Add className prop with default empty string
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  
  // Handle mobile tap
  const handleTap = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Calculate tooltip position
  const getTooltipPosition = () => {
    switch (position) {
      case 'top':
        return { bottom: '100%', marginBottom: '8px' };
      case 'bottom':
        return { top: '100%', marginTop: '8px' };
      case 'left':
        return { right: '100%', marginRight: '8px' };
      case 'right':
        return { left: '100%', marginLeft: '8px' };
      default:
        return { top: '100%', marginTop: '8px' };
    }
  };

  return (
    <div className={`relative inline-block ${className}`}>
      {/* Wrap children with info indicator */}
      <div 
        className="group relative w-full"
        onMouseEnter={() => window.innerWidth > 768 && setIsOpen(true)}
        onMouseLeave={() => window.innerWidth > 768 && setIsOpen(false)}
        onClick={handleTap}
      >
        <div className="relative w-full">
          {children}
          <div className="absolute -top-1 -right-1 w-4 h-4 bg-purple-500 rounded-full flex items-center justify-center text-[10px] text-white animate-pulse">
            {icon || 'i'}
          </div>
        </div>
      </div>

      {/* Tooltip */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            style={getTooltipPosition()}
            className="absolute z-50 min-w-[200px] max-w-[280px] bg-white rounded-lg shadow-xl p-4 border border-purple-100"
          >
            {/* Tooltip content */}
            <div className="flex flex-col gap-2">
              <h4 className="font-semibold text-purple-800">
                {t(title)}
              </h4>
              <p className="text-sm text-gray-600">
                {t(description)}
              </p>
              
              {/* Mobile-only close button */}
              <button
                className="md:hidden mt-2 text-xs text-purple-600 hover:text-purple-800"
                onClick={() => setIsOpen(false)}
              >
                {t('profile.features.tapToClose')} ×
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FeatureGuide;

