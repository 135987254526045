import { useState, useEffect, useCallback } from "react";
import { createBrowserRouter, RouterProvider, Navigate, Route, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { WebSocketProvider, useWebSocket } from "./contexts/WebSocketContext";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./components/Navbar";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import VerifyEmail from "./components/auth/VerifyEmail";
import Profile from "./components/profile/Profile";
import Browse from "./components/browse/Browse";
import Messages from "./components/messages/Messages";
import { default as Conversation } from "./components/messages/Conversation";
import UserProfile from "./components/browse/UserProfile";
import EditProfile from "./components/profile/EditProfile";
import NotificationToast from "./components/notifications/NotificationToast";
import Notifications from "./components/notifications/Notifications";
import LandingPage from "./components/LandingPage";
import MobileLandingPage from "./components/MobileLandingPage"; // Import MobileLandingPage component
import Index from "./pages/index";
import Swipe from "./components/swipe/Swipe";
import PrivatePhotos from "./components/profile/PrivatePhotos";
import UserPrivateGallery from "./components/profile/UserPrivateGallery";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import Contact from "./components/pages/Contact"; // Import Contact component
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminUsers from "./components/admin/AdminUsers";
import AdminReports from "./components/admin/AdminReports";
import AdminSettings from "./components/admin/AdminSettings";
import Upgrade from "./pages/Upgrade";
import UpgradeSuccess from "./pages/UpgradeSuccess";
import axios from 'axios';
import config from './config';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AdminApp from "./components/admin/AdminApp"; // Import AdminApp component

// Route protection components
const PrivateRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !currentUser) {
      // Only redirect to login if not authenticated
      navigate('/login', { state: { from: location } });
    }
  }, [currentUser, loading, navigate, location]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return currentUser ? children : null;
};

const AdminRoute = ({ children }) => {
  const { currentUser } = useAuth();
  
  if (!currentUser || !currentUser.isAdmin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const UpgradeSuccessRoute = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hasPayPalParams = params.has('subscription_id') && params.has('token');

  if (!hasPayPalParams) {
    return <Navigate to="/upgrade" replace />;
  }

  return <UpgradeSuccess />;
};

// Layout component with navbar
function Layout() {
  const { currentUser, loading } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { webSocket } = useWebSocket();
  const { logoutEvent } = useWebSocket();

  // Check if device is mobile and redirect to mobile landing page if needed
  useEffect(() => {
    const checkMobile = () => {
      const ua = navigator.userAgent;
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
      setIsMobile(isMobileDevice);
      console.log('UserAgent:', ua);
      console.log('Is Mobile:', isMobileDevice);
      
      // If on mobile and at root path or landing page, redirect to mobile landing page
      if (isMobileDevice && (location.pathname === '/' || location.pathname === '/landing')) {
        navigate('/mobile');
      }
    };
    
    checkMobile();
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (logoutEvent) {
      const message = logoutEvent.reason === 'subscription_cancelled' 
        ? t('auth.subscriptionCancelled')
        : t('auth.sessionExpired');
      
      toast.info(message);
      navigate('/login');
    }
  }, [logoutEvent, navigate, t]);

  // Check unread messages periodically
  useEffect(() => {
    if (!currentUser) return;

    const checkUnreadMessages = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await axios.get(`${config.API_URL}/api/messages/unread`, {
          headers: { 'x-auth-token': token },
        });

        if (response.data.success && response.data.count > 0) {
          setNotifications((prev) => {
            // Find existing unread messages notification
            const existingIndex = prev.findIndex((n) => n.type === 'unread_messages');

            // Create new notification
            const newNotif = {
              id: Date.now(),
              type: 'unread_messages',
              count: response.data.count,
              message: `You have ${response.data.count} unread message${
                response.data.count > 1 ? 's' : ''
              }`,
            };

            // If exists, update it, otherwise add new
            if (existingIndex >= 0) {
              const updated = [...prev];
              updated[existingIndex] = newNotif;
              return updated;
            }
            return [...prev, newNotif];
          });
        }
      } catch (error) {
        console.error('Error checking unread messages:', error);
        // Don't show error to user, just log it
      }
    };

    // Check immediately on mount
    checkUnreadMessages();

    // Then check every minute (increased from 30s to reduce server load)
    const interval = setInterval(checkUnreadMessages, 60000);

    return () => clearInterval(interval);
  }, [currentUser]);

  // Listen for new messages via WebSocket
  useEffect(() => {
    if (!webSocket?.socket || !currentUser) return;

    const handleNewMessage = (message) => {
      if (!message) return;

      // Check if message is for current user and unread
      const isRecipient =
        message.recipient === currentUser._id ||
        message.recipient?._id === currentUser._id;

      if (isRecipient && !message.read) {
        setNotifications((prev) => {
          // Find existing unread messages notification
          const existingIndex = prev.findIndex((n) => n.type === 'unread_messages');

          // Create new notification
          const newNotif = {
            id: Date.now(),
            type: 'unread_messages',
            count: (existingIndex >= 0 ? prev[existingIndex].count : 0) + 1,
            message: 'You have a new message',
          };

          // If exists, update it, otherwise add new
          if (existingIndex >= 0) {
            const updated = [...prev];
            updated[existingIndex] = newNotif;
            return updated;
          }
          return [...prev, newNotif];
        });
      }
    };

    webSocket.socket.on('newMessage', handleNewMessage);

    return () => {
      webSocket.socket.off('newMessage', handleNewMessage);
    };
  }, [webSocket?.socket, currentUser]);

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-600 to-blue-700">
      <Navbar />
      <div className={`min-h-screen ${location.pathname === '/' || location.pathname === '/landing' ? '' : 'pt-20'}`}>
        <div className="w-full">
          <Outlet />
        </div>
        <AnimatePresence>
          {notifications.map((notification, index) => (
            <NotificationToast
              key={index}
              notification={notification}
              onClose={() => {
                setNotifications((prev) =>
                  prev.filter((_, i) => i !== index)
                );
              }}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

function App() {
  const { t } = useTranslation();
  
  // Define routes with mobile detection
  const router = createBrowserRouter([
    {
      path: '/mobile',
      element: <MobileLandingPage />,
    },
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Index />,
        },
        {
          path: 'landing',
          element: <LandingPage />,
        },
        {
          path: 'privacy',
          element: <Privacy />,
        },
        {
          path: 'terms',
          element: <Terms />,
        },
        {
          path: 'contact',
          element: <Contact />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'register',
          element: <Register />,
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'reset-password/:token',
          element: <ResetPassword />,
        },
        {
          path: 'verify-email/:token',
          element: <VerifyEmail />,
        },
        {
          path: 'upgrade',
          element: <PrivateRoute><Upgrade /></PrivateRoute>,
        },
        {
          path: 'upgrade/success',
          element: <UpgradeSuccessRoute />,
        },
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'profile/edit',
          element: <EditProfile />,
        },
        {
          path: 'browse',
          element: <Browse />,
        },
        {
          path: 'swipe',
          element: <Swipe />,
        },
        {
          path: 'browse/:id',
          element: <UserProfile />,
        },
        {
          path: 'user/:userId/private-gallery',
          element: <UserPrivateGallery />,
        },
        {
          path: 'private-photos',
          element: <PrivatePhotos />,
        },
        {
          path: 'messages',
          element: <PrivateRoute><Messages /></PrivateRoute>,
        },
        {
          path: 'messages/:userId',
          element: <PrivateRoute><Conversation /></PrivateRoute>,
        },
        {
          path: 'messages/new/:userId',
          element: <PrivateRoute><Conversation /></PrivateRoute>,
        },
        {
          path: 'notifications',
          element: <Notifications />,
        },
        {
          path: 'profile/:userId',
          element: <Profile />,
        },
        {
          path: 'admin',
          element: (
            <AdminRoute>
              <div className="min-h-screen bg-gray-100">
                <div className="w-full">
                  <Outlet />
                </div>
              </div>
            </AdminRoute>
          ),
          children: [
            {
              index: true,
              element: <AdminDashboard />,
            },
            {
              path: 'users',
              element: <AdminUsers />,
            },
            {
              path: 'reports',
              element: <AdminReports />,
            },
            {
              path: 'settings',
              element: <AdminSettings />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <WebSocketProvider>
          <ToastContainer />
          <RouterProvider router={router} />
        </WebSocketProvider>
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;