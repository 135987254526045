import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import thTranslation from './locales/th/translation.json';

console.log('Loading translations:', {
  en: enTranslation,
  th: thTranslation
});

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      th: { translation: thTranslation }
    },
    lng: localStorage.getItem('language') || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    debug: true
  });

export default i18n; 