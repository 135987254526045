import { createContext, useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useAuth } from './AuthContext';
import { io } from 'socket.io-client';
import config from '../config';

const WebSocketContext = createContext(null);

export function useWebSocket() {
  return useContext(WebSocketContext);
}

export function WebSocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const { currentUser, logout } = useAuth();
  const socketRef = useRef(null);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;
  const [isConnecting, setIsConnecting] = useState(false);
  const currentUserRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const mountedRef = useRef(true);
  const connectionActiveRef = useRef(false);
  const MIN_RECONNECT_DELAY = 1000;
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [logoutEvent, setLogoutEvent] = useState(null);

  // Handle component unmount
  useEffect(() => {
    return () => {
      mountedRef.current = false;
      cleanup(true);
    };
  }, []);

  // Handle user changes
  useEffect(() => {
    const prevUserId = currentUserRef.current;
    const newUserId = currentUser?._id;

    if (prevUserId !== newUserId) {
      currentUserRef.current = newUserId;

      if (newUserId) {
        if (!connectionActiveRef.current) {
          connectSocket();
        }
      } else {
        cleanup(true);
      }
    }
  }, [currentUser]);

  const cleanup = useCallback((force = false) => {
    if (!mountedRef.current && !force) return;

    connectionActiveRef.current = false;
    clearTimeout(reconnectTimeoutRef.current);
    reconnectTimeoutRef.current = null;

    if (socketRef.current) {
      socketRef.current.removeAllListeners();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    setSocket(null);
    setIsConnecting(false);
    reconnectAttempts.current = 0;
    setOnlineUsers(new Set());
  }, []);

  const handleReconnection = useCallback(() => {
    if (!mountedRef.current || !connectionActiveRef.current) return;

    if (reconnectAttempts.current < maxReconnectAttempts) {
      reconnectAttempts.current++;
      console.log(`Attempting reconnect (${reconnectAttempts.current}/${maxReconnectAttempts})`);
      
      reconnectTimeoutRef.current = setTimeout(() => {
        connectSocket();
      }, MIN_RECONNECT_DELAY * reconnectAttempts.current);
    } else {
      console.error('Maximum reconnect attempts reached');
      cleanup(true);
    }
  }, [cleanup]);

  const connectSocket = useCallback(() => {
    if (!mountedRef.current || !currentUserRef.current || isConnecting) return;

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('WebSocket connection failed: No authentication token');
      return;
    }

    cleanup();
    setIsConnecting(true);
    connectionActiveRef.current = true;

    try {
      const newSocket = io(config.WS_URL, {
        auth: { token },
        transports: ['websocket'],
        reconnection: false,
        autoConnect: true,
        forceNew: true,
        timeout: 10000
      });

      socketRef.current = newSocket;

      // Connection established
      newSocket.on('connect', () => {
        if (!mountedRef.current) return;
        console.log('WebSocket: Connected, requesting online users');
        setSocket(newSocket);
        setIsConnecting(false);
        reconnectAttempts.current = 0;
        newSocket.emit('getOnlineUsers');
      });

      // Handle online users updates
      newSocket.on('onlineUsers', (users) => {
        console.log('WebSocket: Online users updated:', users);
        if (mountedRef.current) {
          setOnlineUsers(new Set(users));
        }
      });

      // Handle force logout
      newSocket.on('force_logout', async (data) => {
        if (!mountedRef.current) return;
        console.log('WebSocket: Force logout event:', data);
        setLogoutEvent(data);
        await logout();
      });

      // Connection error
      newSocket.on('connect_error', (error) => {
        if (!mountedRef.current) return;
        console.error('WebSocket: Connection error:', error);
        setIsConnecting(false);

        if (error.message.includes('auth')) {
          console.error('WebSocket authentication failed');
          cleanup(true);
          return;
        }

        handleReconnection();
      });

      // Connection closed
      newSocket.on('disconnect', (reason) => {
        if (!mountedRef.current) return;
        console.log('WebSocket disconnected:', reason);
        
        if (reason === 'io server disconnect') {
          cleanup(true);
        } else {
          handleReconnection();
        }
      });

    } catch (error) {
      console.error('WebSocket connection error:', error);
      cleanup(true);
    }
  }, [cleanup, isConnecting, handleReconnection, logout]);

  const contextValue = useMemo(() => ({
    socket: socketRef.current,
    isConnected: !!socket,
    isConnecting,
    onlineUsers,
    logoutEvent,
    connect: connectSocket,
    disconnect: cleanup,
    requestOnlineUsers: () => {
      if (socketRef.current?.connected) {
        console.log('WebSocket: Manually requesting online users');
        socketRef.current.emit('getOnlineUsers');
      }
    }
  }), [socket, isConnecting, connectSocket, cleanup, onlineUsers, logoutEvent]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
}
