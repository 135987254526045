import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const ProfileCompletionPrompt = ({ photoCount, totalPhotos = 6 }) => {
  const { t } = useTranslation();
  const completionPercentage = Math.min(100, Math.round((photoCount / totalPhotos) * 100));

  const tips = [
    {
      icon: '📸',
      title: t('profile.completion.tips.morePhotos.title'),
      description: t('profile.completion.tips.morePhotos.description')
    },
    {
      icon: '🎯',
      title: t('profile.completion.tips.personality.title'),
      description: t('profile.completion.tips.personality.description')
    },
    {
      icon: '✨',
      title: t('profile.completion.tips.quality.title'),
      description: t('profile.completion.tips.quality.description')
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-r from-purple-50 to-pink-50 rounded-xl p-6 shadow-lg mb-6"
    >
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-semibold text-purple-800">
          {t('profile.completion.title')}
        </h3>
        <div className="text-sm text-purple-600 font-medium">
          {completionPercentage}% {t('profile.completion.percentComplete')}
        </div>
      </div>

      {/* Progress bar */}
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${completionPercentage}%` }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="bg-gradient-to-r from-purple-500 to-pink-500 h-2.5 rounded-full"
        />
      </div>

      {/* Tips grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {tips.map((tip, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white rounded-lg p-4 shadow-sm"
          >
            <div className="text-2xl mb-2">{tip.icon}</div>
            <h4 className="font-medium text-purple-800 mb-1">{tip.title}</h4>
            <p className="text-sm text-gray-600">{tip.description}</p>
          </motion.div>
        ))}
      </div>

      {/* Action button */}
      <Link
        to="/profile/edit"
        className="block w-full bg-gradient-to-r from-purple-600 to-pink-600 text-white text-center py-3 rounded-lg font-medium hover:from-purple-700 hover:to-pink-700 transition-all transform hover:scale-[1.02] active:scale-[0.98]"
      >
        {t('profile.completion.addPhotos')} →
      </Link>

      {/* Bonus tip */}
      <div className="mt-4 text-sm text-center text-purple-600">
        <span className="inline-block animate-bounce mr-1">💡</span>
        {t('profile.completion.bonusTip')}
      </div>
    </motion.div>
  );
};

export default ProfileCompletionPrompt;