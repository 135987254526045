import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

export default function MobileLandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Set viewport meta tag to ensure proper mobile display
  useEffect(() => {
    // Force the page to be at the top when loaded
    window.scrollTo(0, 0);
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleExternalLink = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <title>ThaiMeetup - Thai Dating & Connections | หาคู่ในประเทศไทย</title>
      </Helmet>
      <div className="min-h-screen flex flex-col bg-gradient-to-br from-purple-600 to-blue-500 text-white">
        {/* Logo and Header - no top padding */}
        <div className="px-6 flex flex-col items-center">
          <img 
            src="/images/thaimeetup-logo1.png" 
            alt="ThaiMeetup Logo" 
            className="w-48 h-48 object-contain mb-4 mt-4"
          />
          <motion.h1 
            className="text-3xl font-bold text-center mb-2"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Thai Meetup
            <span className="block text-xl mt-1">
              ค้นหาคู่ที่ใช่สำหรับคุณ
            </span>
          </motion.h1>
          <motion.p 
            className="text-lg text-center mb-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Meet genuine Thai singles looking for real connections
            <span className="block text-sm mt-1">
              พบกับคนไทยที่กำลังมองหาความสัมพันธ์ที่แท้จริง
            </span>
          </motion.p>
        </div>

        {/* Main Content with Buttons */}
        <div className="flex-1 px-6 pb-12 flex flex-col items-center">
          <div className="w-full space-y-4 max-w-sm">
            {/* Join Free Button */}
            <motion.button
              onClick={() => navigate('/register')}
              className="w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white px-6 py-4 rounded-xl font-semibold shadow-lg flex flex-col items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="text-lg">Join Free Now</span>
              <span className="text-sm mt-1">สมัครฟรีวันนี้</span>
            </motion.button>

            {/* Sign In Button */}
            <motion.button
              onClick={() => navigate('/login')}
              className="w-full border-2 border-white/50 text-white px-6 py-4 rounded-xl font-semibold flex flex-col items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="text-lg">Sign In</span>
              <span className="text-sm mt-1">เข้าสู่ระบบ</span>
            </motion.button>

            {/* Dating Blog Button */}
            <motion.button
              onClick={(e) => handleExternalLink(e, 'https://blog.thaimeetup.com')}
              className="w-full bg-purple-600 text-white px-6 py-4 rounded-xl font-semibold flex flex-col items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="text-lg">Dating Blog</span>
              <span className="text-sm mt-1">บทความเดทติ้ง</span>
            </motion.button>
          </div>
        </div>

        {/* Footer */}
        <div className="py-4 px-6 flex justify-between items-center bg-purple-700/50 backdrop-blur-sm">
          <button
            onClick={() => navigate('/privacy')}
            className="text-xs text-white/80"
          >
            Privacy
          </button>
          <a
            href="/csae-standards.html"
            className="text-xs text-white/80"
          >
            Child Safety
          </a>
          <button
            onClick={() => navigate('/contact')}
            className="text-xs text-white/80"
          >
            Contact
          </button>
          <button
            onClick={() => navigate('/terms')}
            className="text-xs text-white/80"
          >
            Terms
          </button>
        </div>
      </div>
    </>
  );
}
