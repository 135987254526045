import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function Privacy() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      <Helmet>
        <title>Privacy Policy - ThaiMeetUp.com</title>
        <meta name="description" content="Privacy Policy for ThaiMeetUp.com - Learn how we protect your data" />
      </Helmet>

      <div className="max-w-4xl mx-auto bg-white/10 backdrop-blur-sm rounded-xl shadow-lg p-8 text-white">
        {/* Back Button */}
        <motion.button
          onClick={() => navigate('/')}
          className="mb-6 text-blue-200 hover:text-white transition-colors flex items-center gap-2"
          whileHover={{ x: -2 }}
        >
          ← Back to Home
        </motion.button>

        <motion.h1 
          className="text-4xl font-bold mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          Privacy Policy
          <span className="block text-2xl mt-2">นโยบายความเป็นส่วนตัว</span>
        </motion.h1>

        <motion.div
          className="space-y-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
            <p className="mb-4 text-white/90">We collect information that you provide directly to us, including:</p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>Profile information (name, age, location)</li>
              <li>Contact information (email address)</li>
              <li>Photos you choose to upload</li>
              <li>Messages and communication with other users</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
            <p className="mb-4 text-white/90">We use the information we collect to:</p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>Provide and maintain our services</li>
              <li>Match you with potential partners</li>
              <li>Send you updates and notifications</li>
              <li>Ensure platform safety and security</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Information Security</h2>
            <p className="mb-4 text-white/90">
              We implement appropriate security measures to protect your personal information, including:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>Encryption of sensitive data</li>
              <li>Regular security audits</li>
              <li>Secure data storage practices</li>
              <li>Staff training on data protection</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Your Rights</h2>
            <p className="mb-4 text-white/90">You have the right to:</p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>Access your personal data</li>
              <li>Request data correction or deletion</li>
              <li>Opt-out of marketing communications</li>
              <li>Request data portability</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Contact Us</h2>
            <p className="text-white/90">
              If you have any questions about this Privacy Policy, please contact us at:
              <br />
              <a href="mailto:privacy@thaimeetup.com" className="text-blue-300 hover:text-blue-200 transition-colors">
                privacy@thaimeetup.com
              </a>
            </p>
          </section>

          {/* Thai Version Summary */}
          <section className="mt-12 pt-8 border-t border-white/20">
            <h2 className="text-2xl font-semibold mb-4">สรุปนโยบายความเป็นส่วนตัว</h2>
            <p className="text-white/90 mb-4">
              เราให้ความสำคัญกับความเป็นส่วนตัวของคุณ โดยจะ:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-white/80">
              <li>เก็บข้อมูลเท่าที่จำเป็นในการให้บริการ</li>
              <li>ปกป้องข้อมูลของคุณด้วยระบบความปลอดภัยระดับสูง</li>
              <li>ไม่เปิดเผยข้อมูลส่วนตัวของคุณแก่บุคคลที่สาม</li>
              <li>ให้คุณสามารถจัดการข้อมูลส่วนตัวของคุณได้</li>
            </ul>
          </section>
        </motion.div>
      </div>
    </div>
  );
}

export default Privacy;
