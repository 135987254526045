import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CheckCircleIcon, HeartIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../contexts/AuthContext';
import { motion } from 'framer-motion';

const UpgradeSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkAuthStatus, logout } = useAuth();
  const [autoLogoutTimer, setAutoLogoutTimer] = useState(10);

  useEffect(() => {
    let timer;
    if (autoLogoutTimer > 0) {
      timer = setInterval(() => {
        setAutoLogoutTimer(prev => prev - 1);
      }, 1000);
    } else if (autoLogoutTimer === 0) {
      handleLogout();
    }
    return () => clearInterval(timer);
  }, [autoLogoutTimer]);

  const handleLogout = async () => {
    await logout();
    navigate('/login', { 
      state: { 
        message: t('upgrade.success.loginAgain'),
        autoLogin: true 
      } 
    });
  };

  useEffect(() => {
    const verifySubscription = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const subscriptionId = urlParams.get('subscription_id');
        const token = urlParams.get('token');

        if (!subscriptionId || !token) {
          console.error('Missing subscription parameters');
          toast.error(t('upgrade.errors.invalidParameters'));
          navigate('/upgrade');
          return;
        }

        const response = await axios.post('/api/payments/verify-subscription', {
          subscriptionId,
          orderID: token
        });

        if (response.data.success) {
          toast.success(t('upgrade.success.subscriptionActive'));
        } else {
          throw new Error('Verification failed');
        }
      } catch (error) {
        console.error('Subscription verification error:', error);
        toast.error(t('upgrade.errors.verificationFailed'));
        navigate('/upgrade');
      }
    };

    verifySubscription();
  }, [navigate, t]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 via-pink-500 to-red-500">
      <div className="container mx-auto px-4 h-screen flex flex-col items-center justify-center">
        <motion.div 
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-2xl shadow-2xl p-8 max-w-md w-full text-center"
        >
          {/* Logo */}
          <Link to="/" className="inline-block mb-6">
            <div className="flex items-center justify-center space-x-2">
              <HeartIcon className="h-12 w-12 text-pink-500" />
              <span className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
                ThaiMeetup
              </span>
            </div>
          </Link>

          {/* Success Icon */}
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
          >
            <CheckCircleIcon className="mx-auto h-16 w-16 text-green-500" />
          </motion.div>

          {/* Success Message */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {t('upgrade.success.title')}
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              {t('upgrade.success.subscriptionActive')}
            </p>
            <p className="mt-4 text-md text-blue-600">
              {t('upgrade.success.autoLogout', { seconds: autoLogoutTimer })}
            </p>
            <button
              onClick={handleLogout}
              className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              {t('upgrade.success.logoutNow')}
            </button>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default UpgradeSuccess;