import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import PhotoNotification from './PhotoNotification';

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/profile/notifications', {
        headers: { 'x-auth-token': token }
      });
      setNotifications(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setError('Failed to load notifications');
      setLoading(false);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`/api/profile/notifications/${notificationId}/read`, {}, {
        headers: { 'x-auth-token': token }
      });
      
      setNotifications(prev => prev.map(notif => 
        notif._id === notificationId ? { ...notif, read: true } : notif
      ));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const renderNotification = (notification) => {
    switch (notification.type) {
      case 'private_photo':
      case 'gallery_share':
        return (
          <PhotoNotification
            key={notification._id}
            notification={notification}
            onMarkAsRead={markAsRead}
          />
        );
      default:
        return null;
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-500"></div>
    </div>
  );

  if (error) return (
    <div className="text-center py-8 text-red-500">{error}</div>
  );

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-6">Notifications</h2>
      <AnimatePresence>
        {notifications.length > 0 ? (
          <div className="space-y-4">
            {notifications.map(notification => renderNotification(notification))}
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-8 text-gray-500"
          >
            No notifications to show
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Notifications; 